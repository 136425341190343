import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React from "react";
import { PDF_DOC_PROPS } from "../../../common/constants";
import Button from "../../../components/ui/button";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const generatePDF = async ({
  name,
  school,
  coachName
}) => {
  let docDefinition = {
    pageSize: "A4",
    pageMargins: [0, 0, 10, 0],
    background: {
      image: PDF_DOC_PROPS.tf_izin_yazisi.background_image,
      fit: [595.28, 842],
    },
    content: [
      {
        text: school,
        fontSize: 11,
        margin: [72, 340, 0, 0],
      },
      {
        text: "Öğrenciler: " + name.toLocaleUpperCase('TR'),
        fontSize: 10,
        margin: [72, 13, 0, 0],
      },
      {
        text: "Sorumlu Öğretmen: " + (coachName ?? " -").toLocaleUpperCase('TR') ,
        fontSize: 10,
        margin: [72, 16, 0, 0],
      },
    ],
  };
  pdfMake.createPdf(docDefinition).download(PDF_DOC_PROPS.tf_izin_yazisi.file_name);
};

const PDFTfIzinYazisi = ({
  name,
  school,
  coachName
}) => {
  if (name === "") {
    return null;
  } else {
    return (
      <Button
        style={{
          background: "cornsilk",
          fontSize: 20,
          marginLeft: 0,
          height:  40,
          color:"black",
        }}
        onClick={() => {
          generatePDF({
            name,
            school,
            coachName,
          });
        }}
      >
       {PDF_DOC_PROPS.tf_izin_yazisi.button_text}
      </Button>
    );
  }
};
export default PDFTfIzinYazisi;

