import React from "react";
import classNames from "./button.module.css";

const Button = ({
  children,
  color = "white",
  background = "orange",
  size,
  className,
  ...props
}) => (
  <button
    className={[
      classNames.button,
      color,
      "bg_" + background,
      size && size === "large" ? classNames.large : "",
      className,
    ].join(" ")}
    {...props}
  >
    {children}
  </button>
);

export default Button;
