import React, { Component } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import MaterialBackDrop from "../../components/ui/loading/materialBackDrop";
import {
  pullAndListenTeamAnswers,
  updateAnswerOfTeam,
  pullExamFromCloudFunctions,
} from "../../firebaseMethods";
import Radio from "@material-ui/core/Radio";
import Typography from "../../components/ui/typography";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Snackbar from "@material-ui/core/Snackbar";
import { PanoramaSharp } from "@material-ui/icons";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import MaterialButton from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";

const qu_box_style = {
  textAlign: "left",
  padding: 30,
  margin: 10,
  borderRadius: 15,
  maxWidth: 700,
  backgroundColor: "rgb(223, 222, 222)",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  color: "black",
};

const qu_part_style = {
  textAlign: "left",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  color: "black",
};

class Exam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //name: "",
      formId: null,
      exam: null,
      examId: null,
      form: null,
      isFetchingExam: false,
      questions: [],
      isUpdatingAnswer: false,
      feedbackMessage: "",
      messageForNoExam: "", // ekrana sınav gelmeyecekse ekran boş kalmasın, bi mesaj olsun. Bu mesaj o.
      responseTexts: {}, // ekranda yazılan
      announcement_date_has_come: false,
      examResult: null,
    };
  }

  componentDidMount() {
    this.pullQuestionsAndResponses();
  }

  setForm = (f) => {
    const previousForm = this.state.form;
    const newForm = f;

    let message = this.state.feedbackMessage;

    if (previousForm) {
      Object.keys(newForm).forEach((q_uid) => {
        const newQ = newForm[q_uid];
        const previousQ = previousForm[q_uid];

        // formdaki bu soru ekrandaki soru listesinin kaçıncı sorusu:
        // const newQIndex =
        //   this.state.questions.findIndex(
        //     (tem_q_obj) => tem_q_obj.uid === q_uid
        //   ) + 1;

        const questionObject = this.state.questions.find(
          (q) => q.uid === q_uid
        );

        let header =
          questionObject && questionObject.header
            ? questionObject.header
            : "bir sorunun";

        const messageTextForChangedQuestion =
          newQ.responderName +
          " " +
          header +
          " cevabını " +
          newQ.response +
          " olarak değiştirdi.\n";

        const user = this.props.user;

        if (user && newQ.responder !== user.id.toString()) {
          // login olan kullanıcı bu değişikliği yaptıysa bunu ekrana yazdırmaya gerek yok. Güncellendi desin yeter.
          if (previousQ) {
            // normalde sorunun cevabı değişmeden responderin değişmesi engellendi ama yine de ne olur ne olmaz diye onu da kontrole koydum:
            if (
              previousQ.response !== newQ.response ||
              previousQ.responder !== newQ.responder
            )
              message += messageTextForChangedQuestion;
          } else {
            // eski formda bu soru hiç yok. Yani buna ilk defa cevap submit etmişler.
            message += messageTextForChangedQuestion;
          }
        }
      });
    }

    this.setState({
      form: newForm,
      feedbackMessage: message,
    });
  };

  pullQuestionsAndResponses = async () => {
    const user = this.props.user;
    if (
      user &&
      user.applied_products &&
      user.applied_products.length > 0 &&
      user.role === 0
    ) {
      //let examId = "bolge_finali_" + (user.applied_products[0].id - 7); // 2020 bölge finalleri için 3.sınıflar bolge_finali_3, 4. sınıflar bolge_finali_4...şeklinde sınavlara girecekler.

      let examId = this.props.match.params.examId;
      // if (user.id === 2756 || user.id === 19246) examId = "temp_sinav";

      this.setState({ isFetchingExam: true }, async () => {
        const result = await pullExamFromCloudFunctions(user.id, examId);
        // console.log("Sonuç: ", result);

        const message = result.message; // hata ya da uyarı durumunda bu dolu olacak. Aksi halde null.
        const exam = result.exam;
        const questions = result.questions ? result.questions : [];
        const formId = result.formId;
        const announcement_date_has_come = result.announcement_date_has_come
          ? result.announcement_date_has_come
          : false;

        this.setState(
          {
            feedbackMessage: message,
            messageForNoExam: message,
            isFetchingExam: false,
            exam,
            formId,
            questions,
            examId,
            announcement_date_has_come,
            examResult: result.exam_result,
          },
          () => {
            if (formId) pullAndListenTeamAnswers(formId, this.setForm);
          }
        );
      });
    }
  };

  renderFeedBack = () => {
    if (this.state.feedbackMessage === "") return null;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={this.state.feedbackMessage}
        autoHideDuration={3000}
        onClose={() => {
          this.setState({ feedbackMessage: "" });
        }}
        // message={this.state.feedbackMessage}
        style={{ bottom: 20, zIndex: 99 }}

        // action={
        //   <React.Fragment>

        //     <IconButton
        //       size="small"
        //       aria-label="close"
        //       color="inherit"
        //       onClick={handleClose}
        //     >
        //       <CloseIcon fontSize="small" />
        //     </IconButton>
        //   </React.Fragment>
        // }
      >
        <div
          style={{
            backgroundColor: "rgb(255, 69, 69)",
            fontSize: "2rem",
            color: "black",
            padding: 20,
            borderRadius: "10px",
            zIndex: 999,
          }}
        >
          {this.state.feedbackMessage}
        </div>
      </Snackbar>
    );
  };

  answerTheQuestion = async (
    examId,
    userId,
    userName,
    formId,
    questionId,
    usersAnswer
  ) => {
    this.setState({ isUpdatingAnswer: true }, async () => {
      const updateResult = await updateAnswerOfTeam(
        examId,
        userId,
        userName,
        formId,
        questionId,
        usersAnswer
      );
      const { message, hideExam } = updateResult;

      if (hideExam) {
        this.setState({
          formId: null,
          exam: null,
          examId: null,
          form: {},
          questions: [],
          isUpdatingAnswer: false,
          feedbackMessage: message,
          messageForNoExam: message,
        });
      } else
        this.setState({
          isUpdatingAnswer: false,
          feedbackMessage: message,
        });
    });
  };

  renderInstructions = () => {
    return (
      <div style={qu_box_style}>
        Bilgilendirme:
        <ul>
          <li>
            Sorulara takım üyelerinden herhangi birinin cevap vermesi
            yeterlidir.
          </li>
          <li>
            Takımınızdan bir yarışmacı bir cevabı güncellediği anda bu
            değişiklik sizin ekranınıza da yansıyacaktır.
          </li>
          <li>
            Değerlendirme ilk 10 sorudan aldığınız puanlara göre yapılacaktır..
            Ek sorulardan aldığınız puanlar sadece eşitlik bozmada
            kullanılacaktır.
          </li>
          <li>
            Cevaplar, işaretlediğiniz anda sisteme kaydedilir. Sınav sonunda
            "Yanıt Gönder" ya da "Sınavı Bitir" benzeri bir işlem yapılmasına
            gerek yoktur.
          </li>
          <li>
            Sistem, süre dolduktan sonra cevap güncellemeye izin vermeyecektir.
            (Sınav başlamadan önce bilgisayarınızın saatinin Türkiye saati ile
            uyumlu olduğuna emin olunuz. Sınav Türkiye saatine göre açılıp
            kapanacaktır.)
          </li>
          <li>
            Sınav bitiminde kullanıcılara mail ile yanıt gönderimi
            yapılmayacaktır.
          </li>
          <li>
            Sınav esnasında herhangi bir teknik sorunla karşılaşırsanız sayfayı
            yenileyiniz ya da farklı bir cihazdan giriş yaparak sınava devam
            ediniz. Merak etmeyin, kaydedilmiş yanıtlar kaybolmayacaktır.
          </li>
        </ul>
      </div>
    );
  };

  renderExamResult = () => {
    const examResult = this.state.examResult;
    return (
      examResult && (
        <div style={qu_box_style}>
          <div style={{ fontWeight: "bold" }}>SINAV SONUCUNUZ</div>

          <TableContainer component={Paper} style={{ width: 400 }}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell align="left" size="small">
                    {"Toplam Puan"}
                  </TableCell>
                  <TableCell align="left">{examResult.total_score}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">{"Ek Puan"}</TableCell>
                  <TableCell align="left">
                    {examResult.additional_score}
                  </TableCell>
                </TableRow>

                {examResult.order_in_region && (
                  <TableRow>
                    <TableCell align="left">
                      {"Bölge Geneli Sıralamanız"}
                    </TableCell>
                    <TableCell align="left">
                      {examResult.order_in_region}
                    </TableCell>
                  </TableRow>
                )}

                {examResult.order_in_country && (
                  <TableRow>
                    <TableCell align="left">
                      {"Türkiye Geneli Sıralamanız"}
                    </TableCell>
                    <TableCell align="left">
                      {examResult.order_in_country}
                    </TableCell>
                  </TableRow>
                )}

                {/* <TableRow>
                  <TableCell align="left">
                    {"Türkiye Finalleri Sınavı Katılım Durumunuz"}
                  </TableCell>
                  <TableCell align="left">
                    {examResult.result_text? "Katılacak" : "-"}
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>

          <div style={{ marginTop: 20, marginLeft: 15 }}>
            <li>
              Her sorunun altında doğru cevap ve soru puanları yer almaktadır.
            </li>
            <li>
              Doğru cevaplandırdığınız soru kutuları yeşil renk ile
              işaretlenmiştir.
            </li>
            <li>
              Ek sorulardan aldığınız puanlar sadece sıralama işleminde
              kullanılmıştır.
            </li>
            <li>
              İtiraz sürecinde cevaplarda ve puanlarda değişiklik olabilir.
              Nihai sonuçlar takvmide belirtilen tarihte açıklanacaktır.
            </li>
            <li>
              Takımınızın sıralaması kesin sonuçlarla birlikte ilan edilecektir.
            </li>
          </div>
        </div>
      )
    );
  };

  renderExam = () => {
    const {
      questions,
      form,
      examId,
      formId,
      exam,
      announcement_date_has_come,
      examResult,
    } = this.state;

    const { user } = this.props;

    const examHeader = (
      <Typography color="orange" size="bigger" style={{ maxWidth: 750 }}>
        {exam.header
          .split("(")
          .map((row, i) => (i > 0 ? <div>{"(" + row}</div> : <div>{row}</div>))}
      </Typography>
    );

    const generateAMultipleChoiceAnswer = (key, qu, q_index) => {
      const isThisAnswerSelected =
        form && form[qu.uid] && form[qu.uid].response === key;
      const responderName = form && form[qu.uid] && form[qu.uid].responderName;

      return (
        <div
          key={q_index + "_" + key}
          style={{
            display: "flex",
            alignItems: "center",
            border: isThisAnswerSelected ? "solid red 1px" : "",
            borderRadius: 10,
            padding: 5,
          }}
        >
          <input
            disabled={announcement_date_has_come}
            type="radio"
            checked={isThisAnswerSelected}
            style={{
              marginRight: 10,
              width: 20,
              height: 20,
            }}
            onChange={() =>
              this.answerTheQuestion(
                examId,
                user.id,
                user.name,
                formId,
                qu.uid,
                key
              )
            }
          ></input>

          {key + ") " + qu.choices[key].choice_text}
          {isThisAnswerSelected && responderName && (
            <span style={{ marginLeft: 20 }}>
              {"(Cevabı seçen yarışmacı: " + responderName + ")"}
            </span>
          )}
        </div>
      );
    };

    const generateATextInputAnswer = (qu, q_index) => {
      const savedResponse = form && form[qu.uid] && form[qu.uid].response;

      const responderName = form && form[qu.uid] && form[qu.uid].responderName;

      return (
        <div key={q_index + "_question_with_text_field"}>
          <div
            style={{
              border: savedResponse ? "solid red 1px" : "",
              borderRadius: 10,
              padding: 5,
            }}
          >
            {savedResponse
              ? "Kayıtlı cevap: " + savedResponse
              : announcement_date_has_come
              ? "Bu soru için cevap kaydedilmemiştir."
              : "Bu soru için henüz cevap kaydedilmemiştir."}
          </div>
          {responderName && <p>(Cevabı kaydeden yarışmacı: {responderName})</p>}
          {!announcement_date_has_come && (
            <TextField
              variant="outlined"
              color="secondary"
              value={this.state.responseTexts[qu.uid] || ""}
              onChange={(event) => {
                const newVal = event.target.value
                  .trim()
                  .toLocaleUpperCase("tr-TR");

                // qu_type 4 ise number girilmesi gerekiyordur.
                if (qu.type === 4 && isNaN(newVal)) {
                  this.setState({
                    feedbackMessage:
                      "Bu soru için sadece sayısal bir cevap yazabilirsiniz.",
                  });
                  return;
                }

                let tempObject = { ...this.state.responseTexts };
                tempObject[qu.uid] = newVal;

                this.setState({
                  responseTexts: {
                    ...tempObject,
                  },
                });
              }}
              style={{ marginRight: 20, marginBottom: 10 }}
              size={"small"}
              placeholder="Cevap kutusu..."
            />
          )}

          {!announcement_date_has_come && (
            <MaterialButton
              variant="contained"
              color="primary"
              //className={classes.button}
              startIcon={<SaveIcon />}
              onClick={() => {
                const resp = this.state.responseTexts[qu.uid];

                if (resp) {
                  this.answerTheQuestion(
                    examId,
                    user.id,
                    user.name,
                    formId,
                    qu.uid,
                    resp
                  );

                  let tempObject = { ...this.state.responseTexts };
                  tempObject[qu.uid] = "";

                  this.setState({
                    responseTexts: {
                      ...tempObject,
                    },
                  });
                } else {
                  this.setState({
                    feedbackMessage: "Lütfen bir cevap yazınız!",
                  });
                }
              }}
            >
              {savedResponse ? "CEVABI DEĞİŞTİR" : "CEVABI KAYDET"}
            </MaterialButton>
          )}
        </div>
      );
    };

    const generateQuestionBox = (qu, q_index) => {
      const has_multiple_answer = qu.has_multiple_answer;

      return (
        <div
          key={q_index + "_question"}
          style={
            announcement_date_has_come &&
            examResult &&
            examResult.questions[qu.uid]
              ? { ...qu_box_style, backgroundColor: "rgb(193, 238, 139)" }
              : qu_box_style
          }
        >
          {(qu.pre_question_explanation || qu.pre_question_image_path) && (
            <div style={qu_part_style}>
              <h5>
                {qu.pre_question_header ? qu.pre_question_header : "AÇIKLAMA"}
              </h5>
              <span style={{ whiteSpace: "pre-wrap", marginBottom: 15 }}>
                {qu.pre_question_explanation}
              </span>

              {qu.pre_question_image_path && (
                <img
                  style={{
                    alignSelf: "center",
                    maxWidth: 640,
                    width: "90vw",
                    // maxWidth:,
                    marginBottom: 20,
                    borderRadius: 14,
                  }}
                  src={qu.pre_question_image_path}
                  alt="resim yüklenemedi..."
                />
              )}
              <div
                style={{
                  height: 2,
                  width: "100%",
                  backgroundColor: "black",
                  marginBottom: 15,
                }}
              ></div>
            </div>
          )}

          <div style={qu_part_style}>
            <span
              style={{
                fontWeight: "bold",
                backgroundColor: "darkorange",
                padding: "3px 8px 3px 8px",
                borderRadius: 7,
                marginBottom: 5,
              }}
            >
              {qu.header}
            </span>

            {qu.question_image_path && (
              <img
                style={{
                  alignSelf: "center",
                  maxWidth: 640,
                  width: "90vw",
                  // maxWidth:,
                  marginBottom: 20,
                  borderRadius: 14,
                }}
                src={qu.question_image_path}
                alt="ill-pair"
              />
            )}

            <span style={{ whiteSpace: "pre-wrap", marginBottom: 15 }}>
              {qu.question_text}
            </span>
            <div>
              {qu.choices &&
                Object.keys(qu.choices)
                  .sort((c1, c2) => c1.localeCompare(c2))
                  .map((key) =>
                    generateAMultipleChoiceAnswer(key, qu, q_index)
                  )}

              {qu.type &&
                (qu.type === 3 || qu.type === 4) &&
                generateATextInputAnswer(qu, q_index)}

              {announcement_date_has_come && (
                <TableContainer
                  component={Paper}
                  style={{
                    width: 300,
                    padding: 5,
                    borderRadius: 11,
                    marginTop: 20,
                  }}
                >
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" size="small">
                          {"Doğru Cevap"}
                        </TableCell>
                        <TableCell align="left">{qu.answer}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell align="left">{"Soru Puanı"}</TableCell>
                        <TableCell align="left">
                          {exam.questions[qu.uid] &&
                            exam.questions[qu.uid].score}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell align="left">{"Sonuç"}</TableCell>
                        <TableCell align="left">
                          {examResult &&
                          examResult.questions[qu.uid] &&
                          examResult.questions[qu.uid] === 1
                            ? "Cevabınız doğru."
                            : "Cevabınız yanlış."}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div
        style={{
          textAlign: "center",
          position: "relative",
          //backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {examHeader}
        {!announcement_date_has_come && this.renderInstructions()}
        {announcement_date_has_come && this.renderExamResult()}
        {questions
          .sort((q1, q2) => q1.order - q2.order)
          .map((qu, q_index) => generateQuestionBox(qu, q_index))}
      </div>
    );
  };

  renderExplanationScreen = () => {
    const { isFetchingExam, exam, feedbackMessage, messageForNoExam } =
      this.state;

    const { user } = this.props;
    let text = "";

    if (!user || user.role !== 0)
      text =
        "Sınav ekranına ulaşabilmek için lütfen öğrenci olarak oturum açınız.";
    else if (exam) {
      text = "";
    } else {
      text = messageForNoExam;
      if (text.indexOf("başlamadı") > -1)
        text +=
          "\nLütfen sınav takviminde belirtilen tarih ve saatte sayfayı yenileyiniz.";
    }

    if (text)
      return (
        <div
          style={{
            position: "relative",
            marginTop: 100,
            color: "white",
            fontSize: "1.5rem",
            whiteSpace: "pre-wrap",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {text}
          {text.indexOf("başlamadı") > -1 && this.renderInstructions()}
        </div>
      );
    else return null;
  };

  render() {
    const { isFetchingExam, isUpdatingAnswer, exam } = this.state;

    const { user } = this.props;

    return (
      <div style={{ marginTop: "150px", textAlign: "center" }}>
        {(isFetchingExam || isUpdatingAnswer) && <MaterialBackDrop />}
        {this.renderExplanationScreen()}
        {user && user.role === 0 && exam && this.renderExam()}
        {this.state.feedbackMessage !== "" && this.renderFeedBack()}
        <ScrollToTop />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getRecruiterAdmins: () => dispatch(getRecruiterAdmins()),
    // deleteRecruiterAdmin: (id) => dispatch(deleteRecruiterAdmin(id)),
    // updateRecruiterAdmin: (data) => dispatch(updateRecruiterAdmin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Exam);
