import React, { useState, useRef, useEffect } from "react";
import classNames from "./collapsable.module.css";
import Typography from "../typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Collapsable = ({
  title,
  body,
  className,
  showing = false,
  onToggle,
  ...props
}) => {
  const [show, setShow] = useState(showing);
  const [height, setHeight] = useState(0);
  const toggleShow = () => {
    if (onToggle) onToggle(!show);
    setShow(!show);
  };
  const bodyText = useRef(null);
  const wrapperHeight = bodyText.current ? bodyText.current.clientHeight : 0;
  useEffect(() => {
    setHeight(wrapperHeight);
  }, [setHeight, wrapperHeight]);
  useEffect(() => setShow(showing), [showing]);
  return (
    <div className={[classNames.collapsable, className].join(" ")} {...props}>
      <div className={classNames.title} onClick={toggleShow}>
        <Typography
          size="smaller"
          font="merel"
          weight="bold"
          color="orange"
          style={{ lineHeight: 1.2 }}
        >
          {title}
        </Typography>
        <Typography
          size="smaller"
          font="merel"
          weight="bold"
          color="orange"
          className={classNames.collapse_icon}
        >
          <FontAwesomeIcon icon={`chevron-${!show ? "down" : "up"}`} />
        </Typography>
      </div>
      <div
        className={[classNames.body, !show && classNames.hiden].join(" ")}
        style={height && show ? { height } : {}}
      >
        <div className={classNames.bodyText} ref={bodyText}>
          {body}
        </div>
      </div>
    </div>
  );
};

export default Collapsable;
