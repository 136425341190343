import axios from "../../config/axios-instance";
import { actionCreate } from "./actionCreator";
import {
  actionTypeRequest,
  PARTICIPANTS,
  MAIL_STATS,
  actionTypeSuccess,
  actionTypeFail,
} from "../actionTypes";
import api from "../../config/api";

export const updateParticipants = (participant) => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(PARTICIPANTS)));

  return axios
    .post(api.pariticipants, { participant: participant })
    .then((response) => {
      dispatch(actionCreate(actionTypeSuccess(PARTICIPANTS)));
      console.log("participant response", participant);
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(PARTICIPANTS)));
      console.log("participant error", error);
    });
};

export const mailStats = () => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(MAIL_STATS)));
  return axios
    .get(api.mailStats, {})
    .then((response) => {
      dispatch(actionCreate(actionTypeSuccess(MAIL_STATS)));
      console.log("mail stats response", response);
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(MAIL_STATS)));
      console.log("mail stats error", error);
    });
};
