import axios, {
  updateAuthHeaders,
  clearHeaders,
} from "../../config/axios-instance";
import React from "react";
import { Redirect } from "react-router-dom";
import {
  setAuthHeadersToLocalStorage,
  clearAuthDataFromLocalStorage,
} from "../../helpers/localStorageHelper";
import api from "../../config/api";
import { actionCreate } from "./actionCreator";
import {
  actionTypeRequest,
  SIGN_IN,
  actionTypeSuccess,
  actionTypeFail,
  VALIDATE_TOKEN,
  CLEAR_AUTH,
  SIGN_OUT,
  PURCHASE,
  UPDATE_USER,
  CHECK_PURCHASE_WITH_USER_ID,
} from "../actionTypes";

export const signIn =
  ({ email, password }) =>
  (dispatch) => {
    dispatch(actionCreate(actionTypeRequest(SIGN_IN)));

    return axios
      .post(api.signIn, {
        email,
        password,
      })
      .then((response) => {
        const user = response.data.data;
        const headers = {
          "access-token": response.headers["access-token"],
          client: response.headers["client"],
          uid: response.headers["uid"],
        };
        dispatch(actionCreate(actionTypeSuccess(SIGN_IN), { user }));
        updateAuthHeaders(headers);
        setAuthHeadersToLocalStorage({
          ...headers,
          user: JSON.stringify(user),
        });
        return true;
      })
      .catch((error) => {
        dispatch(actionCreate(actionTypeFail(SIGN_IN)));
        console.log("error: ", error);
        if (error.response && error.response.status == 401)
          return alert("Lütfen giriş bilgilerinizi kontrol ediniz.");
        else
          return alert(
            "Beklenmeyen bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz."
          );
      });
  };

export const checkPurchaseWithUserId = (id) => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(CHECK_PURCHASE_WITH_USER_ID)));
  return axios
    .post(api.checkPurchaseWithUserId, {
      id,
    })
    .then((response) => {
      const user = response.data;
      // const headers = {
      //   "access-token": response.headers["access-token"],
      //   client: response.headers["client"],
      //   uid: response.headers["uid"],
      // };
      dispatch(
        actionCreate(actionTypeSuccess(CHECK_PURCHASE_WITH_USER_ID), { user })
      );
      // updateAuthHeaders(headers);
      // setAuthHeadersToLocalStorage({ ...headers, user: JSON.stringify(user) });
      return true;
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(CHECK_PURCHASE_WITH_USER_ID)));
      if (error.response && error.response.status == 401)
        return alert("Lütfen giriş bilgilerinizi kontrol ediniz.");
      else
        return alert(
          "Beklenmeyen bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz."
        );
    });
};

export const getUser = () => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(SIGN_IN)));
  return axios
    .post(api.getTest, {})
    .then((response) => {
      const user = response.data.data;
      dispatch(actionCreate(actionTypeSuccess(SIGN_IN), { user }));
      return true;
    })
    .catch((error) => {
      console.log(error);
      dispatch(actionCreate(actionTypeFail(SIGN_IN)));
      return alert("Lütfen giriş bilgilerinizi kontrol ediniz.");
    });
};

export const signUp = (params) => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(SIGN_IN)));

  return axios
    .post(api.auth, params)
    .then((response) => {
      const user = response.data.data;

      // eğer role=1 ise params içindeki coachClasses'ı db'ye yazdır.
      // api'deki addCoachesToSchool fonksiyonuna user_id, school_id ve sınıf listesini ilet.

      const headers = {
        "access-token": response.headers["access-token"],
        client: response.headers["client"],
        uid: response.headers["uid"],
      };
      dispatch(actionCreate(actionTypeSuccess(SIGN_IN), { user }));
      updateAuthHeaders(headers);
      setAuthHeadersToLocalStorage({ ...headers, user: JSON.stringify(user) });
      return { status: "ok" };
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(SIGN_IN)));

      console.log("error: ", error);
      // if (error.response && error.response.status == 900) {// bir okula 2. kez öğretmen kayıt olmaya çalışırsa bu hata kodu geliyor.
      //   console.log("error.response : ", error.response);
      //   return alert(error.response.data.error);
      // }
      // else

      if (error.response && error.response.status == 901)
        // aynı mail 2. kez kaydolmaya çalışıyor.
        return { status: "already_exists" };
      else return { status: "unknown_error" };
    });
};

// export const individualSignUp = params => dispatch => {
//   dispatch(actionCreate(actionTypeRequest(SIGN_IN)));

//   return axios
//     .post(api.auth, params)
//     .then(response => {
//       const user = response.data.data;
//       const headers = {
//         "access-token": response.headers["access-token"],
//         client: response.headers["client"],
//         uid: response.headers["uid"]
//       };
//       dispatch(actionCreate(actionTypeSuccess(SIGN_IN), { user }));
//       updateAuthHeaders(headers);
//       setAuthHeadersToLocalStorage({ ...headers, user: JSON.stringify(user) });
//       return true;
//     })
//     .catch(error => {
//       dispatch(actionCreate(actionTypeFail(SIGN_IN)));
//       return alert("E-posta adresi kullanımda.");
//     });
// };

export const validateToken = () => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(VALIDATE_TOKEN)));
  return axios
    .get(api.validate)
    .then((response) => {
      dispatch(actionCreate(actionTypeSuccess(VALIDATE_TOKEN)));
      dispatch(
        actionCreate(actionTypeSuccess(SIGN_IN), { user: response.data.data })
      );
    })
    .catch((error) => {
      clearAuthDataFromLocalStorage();
      clearHeaders();
      dispatch(clearAuth());
      dispatch(actionCreate(actionTypeFail(VALIDATE_TOKEN)));
    });
};

export const signOut = () => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(SIGN_OUT)));
  clearAuthDataFromLocalStorage();
  clearHeaders();
  dispatch(clearAuth());
  dispatch(actionCreate(actionTypeSuccess(SIGN_OUT)));
};

export const rescuePassword =
  (params, password, confirmPassword) => (dispatch) => {
    dispatch(actionCreate(actionTypeRequest(SIGN_IN)));
    updateAuthHeaders(params);

    return axios
      .put(api.password, {
        password,
        password_confirmation: confirmPassword,
      })
      .then((response) => {
        console.log(response.data);
        const user = response.data.data;
        setAuthHeadersToLocalStorage({ ...params, user: JSON.stringify(user) });
        dispatch(actionCreate(actionTypeSuccess(SIGN_IN), { user }));
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        dispatch(actionCreate(actionTypeFail(SIGN_IN)));
      });
  };
const clearAuth = () => ({
  type: CLEAR_AUTH,
});

export const purchase =
  (products = [], students = []) =>
  (dispatch) => {
    console.log({ products });
    dispatch(actionCreate(actionTypeRequest(PURCHASE)));
    return axios
      .post(api.purchase, {
        products,
        students,
      })
      .then((response) => {
        console.log({ response });
        dispatch(actionCreate(actionTypeSuccess(PURCHASE)));
        return response.data;
      })
      .catch((err) => {
        console.log({ err });
        dispatch(actionCreate(actionTypeFail(PURCHASE)));
        return { data: "error" };
      });
  };

export const updateUser = (params) => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(UPDATE_USER)));
  axios
    .put(api.auth, params)
    .then((response) => {
      dispatch(actionCreate(actionTypeSuccess(UPDATE_USER)));
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(UPDATE_USER)));
    });
};
export const forgetPassword =
  ({ email }) =>
  (dispatch) => {
    dispatch(actionCreate(actionTypeRequest(SIGN_IN)));
    return axios
      .post(api.password, {
        email,
        redirect_url: "https://sampiyona.tzv.org.tr/giris",
      })
      .then((response) => {
        dispatch(actionCreate(actionTypeSuccess(SIGN_IN)));
        return response.data;
      })
      .catch((error) => {
        dispatch(actionCreate(actionTypeFail(SIGN_IN)));
      });
  };
