import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducer from "./reducers/auth";
import loadingReducer from "./reducers/loading";
import generic from "./reducers/generic";

export default (history) => {
  const rootReducerFn = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    router: connectRouter(history),
    generic: generic,
  });

  return (state, action) =>
    rootReducerFn(
      action.type === "SIGN_OUT_REQUEST" ? undefined : state,
      action
    );
};
