import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React from "react";
import { PDF_DOC_PROPS } from "../../../common/constants";
import Button from "../../../components/ui/button";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const generatePDF = async ({
  class_id,
  school,
  name,
  table_number,
}) => {

  let session = "10.30";
  if ([5, 6, 7, 8].includes(class_id)) session = "13.30";
  else if ([9, 10, 11, 12].includes(class_id)) session = "16.30";

  let docDefinition = {
    pageSize: "A4",
    pageMargins: [0, 0, 10, 0],
    background: {
      image: PDF_DOC_PROPS.tf_table_number_information.background_image,
      fit: [595.28, 842],
    },
    content: [
      {
        text: "SINIF:  " + class_id,
        fontSize: 11,
        bold: true,
        margin: [30, 550, 0, 0],
      },
      {
        text: "OKUL:  " + school,
        fontSize: 11,
        bold: true,
        margin: [30, 10, 0, 0],
      },

      {
        text: "KATILIMCILAR:  " + name.toLocaleUpperCase('TR'),
        fontSize: 11,
        bold: true,
        margin: [30, 10, 0, 0],
      },
      {
        text: "SINAV BAŞLAMA SAATİ:  " + session,
        fontSize: 11,
        bold: true,
        margin: [30, 35, 0, 0],
      },
      {
        text: "SINAV SÜRESİ:  60 Dakika",
        fontSize: 11,
        bold: true,
        margin: [30, 10, 0, 0],
      },
      {
        text: "MASA NUMARASI:  " + table_number,
        fontSize: 24,
        bold: true,
        margin: [30, 35, 0, 0],
      },
    ],
  };
  pdfMake.createPdf(docDefinition).download(PDF_DOC_PROPS.tf_table_number_information.file_name);
};

const PdfExporterTableNumberInformation = ({
  name,
  school,
  class_id,
  table_number,
}) => {
  if (name === "") {
    return null;
  } else {
    return (
      <Button
        style={{
          background: "cornsilk",
          fontSize: 20,
          marginLeft: 0,
          marginTop:  12,
          marginBottom: 0,
          height:  40,
          color:"black",
        }}
        onClick={() => {
          generatePDF({
            name,
            school,
            class_id,
            table_number,
          });
        }}
      >
        {PDF_DOC_PROPS.tf_table_number_information.button_text}
      </Button>
    );
  }
};
export default PdfExporterTableNumberInformation;

