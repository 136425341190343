import React from "react";
import classNames from "../manage.module.css";
import LabelAndText from "../../../components/ui/labelAndText/labelAndText";
import MaterialButton from "@material-ui/core/Button";
import SettingsIcon from "@material-ui/icons/Settings";
import {  ListAltOutlined } from "@material-ui/icons";

export const UserProfileSummaryBox = ({ user, onUserProfileEditClick, coachNameOfStudent }) => {
  const schoolName =
    user && user.school_name
      ? user.school_name + " (" + user.city + "/" + user.district + ")"
      : "";

  const coachClasses = user.all_coaches_of_this_school
    .sort((a, b) => a.class_id - b.class_id)
    .map((u) => {
      if (u.id === user.id) return " " + u.class_id + ".Sınıf";
    })
    .filter((u) => u)
    .toString();

  return (
    <div className={"card " + classNames.left_step}>
      <LabelAndText labelText={""} text={schoolName} />
      <LabelAndText labelText={"Adı-Soyadı"} text={user.name} />
      <LabelAndText labelText={"E-Posta"} text={user.email} />
      <LabelAndText
        isHidden={user.role !== 1}
        labelText={"Sınıflarınız"}
        text={
          coachClasses ??
          "Öğrenci listesine ulaşabilmek için lütfen 'Düzenle' butonuna basarak sınıf seçimi yapınız."
        }
      />
      <LabelAndText
        isHidden={user.role !== 0}
        labelText={"Kategori"}
        text={
          user.class_id
            ? user.class_id + ". Sınıf"
            : "Lütfen 'Düzenle' butonuna basarak okul ve sınıf bilgilerinizi güncelleyiniz."
        }
      />
      <LabelAndText
        isHidden={user.role !== 0}
        labelText={"Yarışmacı ID"}
        text={user.id}
        title={
          "Bu numara yarışma başvuru numaranızdır, otomatik olarak verilmektedir."
        }
      />
      <LabelAndText
        isHidden={user.role !== 0}
        labelText={"Sorumlu Öğretmen"}
        text={
          coachNameOfStudent ??
          "Okulunuzdan sorumlu öğretmen kaydı yapılmamıştır."
        }
      />

      <div>
        <MaterialButton
          variant="contained"
          color="secondary"
          style={{ backgroundColor: "#6b6a5f", marginTop: 20,marginRight:10 }}
          startIcon={<SettingsIcon />}
          onClick={onUserProfileEditClick}
        >
          Düzenle
        </MaterialButton>
        {/* <a href="/belgelerim">
        <MaterialButton
          variant="contained"
          color="secondary"
          style={{ backgroundColor: "#6b6a5f", marginTop: 20 }}
          startIcon={<ListAltOutlined />}
          
        >
          Belgelerim
        </MaterialButton>
        </a> */}
      </div>
      
    </div>
  );
};
