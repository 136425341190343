// import 'firebase/storage';
// import 'firebase/auth';
// import 'firebase/firestore';
// import firebase from 'firebase/app';

import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";

const firebaseConfig = {
  apiKey: "AIzaSyBzl5hM7W-CWTGyp2xwktlPnGzV30dn2SU",
  authDomain: "championship-meb.firebaseapp.com",
  databaseURL: "https://championship-meb.firebaseio.com",
  projectId: "championship-meb",
  storageBucket: "championship-meb.appspot.com",
  messagingSenderId: "1059749437111",
  appId: "1:1059749437111:web:708779610d0a8907a2f732",
};

firebase.initializeApp(firebaseConfig);
// export const firebaseDatabase = firebase.database();
export const firebaseStorage = firebase.storage();
export const firebaseAuth = firebase.auth();
export const firestoreDb = firebase.firestore();
