import React, { useState } from "react";
import sha256 from "crypto-js/sha256";
import Hex from "crypto-js/enc-hex";
import { EXAM_SEASONS } from "../../common/examData";
import { Box } from "@material-ui/core";

const Exam = ({ user }) => {
  // console.log("user", user);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [classId, setClassId] = useState("");
  const [examLink, setExamLink] = useState("");
  const [season, setSeason] = useState(Object.keys(EXAM_SEASONS)[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const class_id = classId;
  
    if (!EXAM_SEASONS[season] || !EXAM_SEASONS[season].exam_ids[class_id]) {
      alert("Geçersiz sezon veya sınıf ID");
      return;
    }
  
    const id_hash = sha256(id.toString() + "tuz").toString(Hex);
    const user_identifier = id + id_hash;
  
    const regex_symbols = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#'"]/gi;
    let userNameForUrl = name
      .trim()
      .replace(regex_symbols, "")
      .replaceAll(" ", "+");
    let [examId, examQuestionsId] = EXAM_SEASONS[season].exam_ids[class_id];
  
    const link = `https://sinav.tzv.org.tr/linkedExam/${examId}&${examQuestionsId}&${user_identifier}&${userNameForUrl}`;
  
    setExamLink(link);
  
    // POST isteğini yapma
    try {
      const response = await fetch("http://localhost:3000/exams/send_exam_link", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: id,  
          exam_link: link,  
        }),
      });
  
      if (!response.ok) {
        throw new Error("Ağ hatası");
      }
  
      const data = await response.json();
      alert(data.message || "Sınav linki başarıyla gönderildi.");
    } catch (error) {
      console.error("Hata:", error);
      alert("Sınav linki gönderilirken bir hata oluştu.");
    }
  };
  
  

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
        padding: 60,
        backgroundColor: "#10273c",
        paddingTop: 20,
        paddingBottom: 30,
      }}
    >
      <h3 style={{ color: "orange", marginBottom: 20 }}>Sınav Linki Oluştur</h3>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ color: "white", marginRight: 4 }}>Kullanıcının ID:</label>
          <input
            type="text"
            value={id}
            onChange={(e) => setId(e.target.value)}
            required
            style={{
              height: "40px",
              borderRadius: "5px",
              padding: "10px",
              border: "1px solid #ccc",
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ color: "white", marginRight: 4, marginTop: 4 }}>Ad:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            style={{
              height: "40px",
              borderRadius: "5px",
              padding: "10px",
              border: "1px solid #ccc",
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ color: "white", marginRight: 4, marginTop: 4 }}>Class ID:</label>
          <input
            type="number"
            value={classId}
            onChange={(e) => setClassId(e.target.value)}
            required
            style={{
              height: "40px",
              borderRadius: "5px",
              padding: "10px",
              border: "1px solid #ccc",
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", paddingBottom: 10 }}>
          <label style={{ color: "white", marginRight: 4, marginTop: 4 }}>Sezon:</label>
          <select
            value={season}
            onChange={(e) => setSeason(e.target.value)}
            style={{
              height: "40px",
              borderRadius: "5px",
              padding: "10px",
              border: "1px solid #ccc",
            }}
          >
            {Object.keys(EXAM_SEASONS).map((seasonKey) => (
              <option key={seasonKey} value={seasonKey}>
                {EXAM_SEASONS[seasonKey].season_name}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          style={{
            height: "40px",
            borderRadius: "5px",
            backgroundColor: "orange",
            color: "white",
            border: "none",
            cursor: "pointer",
            marginTop: 20,
          }}
        >
          Linki Oluştur ve Kullanıcıya Gönder
        </button>
      </form>

      {examLink && (
        <div>

          <a href={examLink} target="_blank" rel="noopener noreferrer">
            {examLink}
          </a>
        </div>
      )}
    </Box>
  );
};

export default Exam;
