import React from "react";
import classNames from "./input.module.css";
import Label from "../label/label";
import InputMask from "react-input-mask";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

const InputElement = ({ select, label, ...props }) => {
  // const El = props.rows ? "textarea" :select?"select": InputMask;
  const El = props.rows ? "textarea" : select ? Autocomplete : InputMask;

  if (select) {
    return (
      <Autocomplete
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        noOptionsText={"Sonuç bulunamadı..."}
        onChange={props.onChange}
        options={props.options || []}
        getOptionLabel={(option) => option.title}
        style={{
          backgroundColor: "white",
          width: "100%",
          borderRadius: "12px",
          borderWidth: "1px",
          minHeight: "50px",
          fontSize: "24px",
        }}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    );
  }

  return (
    <El
      className={[classNames.input, select ? classNames.select : ""].join(" ")}
      {...props}
    />
  );
};

const Input = ({ label, labelColor, warning, ...props }) =>
  label ? (
    <Label label={label} warning={warning} color={labelColor}>
      <InputElement {...props} label={label} />
    </Label>
  ) : (
    <InputElement {...props} />
  );

export default Input;
