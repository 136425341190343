import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../ui/button";
import classNames from "./header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { signOut as signOutAction } from "../../store/actions/auth";
import NavLinks from "../navlinks/navlinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import LinearProgress from "@material-ui/core/LinearProgress";
import { useLocation } from "react-router-dom";

const Header = ({ ...props }) => {
  const dispatch = useDispatch();
  const signOut = useCallback(() => dispatch(signOutAction()), [dispatch]);
  const location = useLocation();

  const user = useSelector((state) => state.auth.user);
  const [menu, setMenu] = useState(false);
  const menuOpenCloseIcon = (
    <div
      className={classNames.toggle}
      onClick={(e) => {
        e.preventDefault();
        setMenu(!menu);
      }}
    >
      <FontAwesomeIcon
        icon={menu ? "window-close" : "bars"}
        size="2x"
        color="white"
      />
    </div>
  );

  const tzvLogoLink = (
    <Link
      to={{ pathname: "/", state: { scroll: true } }}
      className={classNames.logo_container}
    >
      <img
        className={classNames.logo}
        src="/assets/images/tzv-logo.png"
        alt="Logo"
      />
    </Link>
  );

  // ücret ödenmediyse, rol=0 ise, satınalma sayfasında değilsek progras göster

  const products = useSelector(
    (state) => (user && user.applied_products) || []
  );
  const showPurchaseIndicator = user && user.role === 0 && !products[0];
  const userOnThePurchaseScreen = location.pathname === "/satinal";
  const hidePurchaseButton = showPurchaseIndicator && userOnThePurchaseScreen;

  const authenticatedUserButtons = (
    <div
      className={menu ? classNames.headerButtonsBox : classNames.hidden}
      style={{ textAlign: "center" }}
    >
      {!hidePurchaseButton && (
        <Link to="/yonetim">
          <Button
            background="orange"
            color="white"
            onClick={() => {
              setMenu(false);
            }}
          >
            {"Şampiyona"}
            {/* {showPurchaseIndicator && <LinearProgress color="primary" />} */}
          </Button>
        </Link>
      )}
      <Link to="/">
        <Button
          color="white"
          background="transparent"
          style={{ minWidth: 60 }}
          onClick={() => {
            signOut();
            setMenu(false);
          }}
        >
          Çıkış
        </Button>
      </Link>
    </div>
  );

  const guestUserButtons = (
    // <div className={menu ? "" : classNames.hidden}>
    <div className={menu ? classNames.headerButtonsBox : classNames.hidden}>
      <Link to="/kayit">
        <Button
          color="white"
          background="orange"
          onClick={() => setMenu(false)}
        >
          Kaydol
        </Button>
      </Link>
      <Link to="/giris">
        <Button
          color="white"
          background="transparent"
          onClick={() => setMenu(false)}
        >
          Giriş Yap
        </Button>
      </Link>
    </div>
  );

  return (
    <div className={classNames.container}>
      {menuOpenCloseIcon}
      <header
        className={classNames.header} //onClick={() => setMenu(false)}
      >
        {tzvLogoLink}
        <NavLinks hidden={!menu} closeMenu={() => setMenu(false)} />
        {user ? authenticatedUserButtons : guestUserButtons}
        {/* <span style={{width:200}}></span> */}
      </header>
    </div>
  );
};

export default Header;
