import React from "react";
import classNames from "./loading.module.css";
const Loading = ({ color, className, style, ...props }) => (
  <div className={classNames.container}>
    <div
      className={[classNames.loading, className].join(" ")}
      {...props}
      style={{
        borderColor: color,
        ...style,
      }}
    />
  </div>
);

export default Loading;
