import axios from "axios";
const baseURL = "https://championship-api.herokuapp.com/";
// const baseURL = "http://localhost:3000";

const instance = axios.create({
  baseURL,
  timeout: 50000,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});

export const updateAuthHeaders = (authHeaders) => {
  for (let key in authHeaders)
    instance.defaults.headers.common[key] = authHeaders[key];
};

export const clearHeaders = () => {
  for (let key of ["uid", "access-token", "client"])
    delete instance.defaults.headers.common[key];
};

export default instance;
