import React, { useState, useEffect } from "react";
import PdfExporter from "../../../components/pdfcreate/pdfExporter";
import { BF_RESULT_TEXTS, PDF_DOC_PROPS } from "../../../common/constants";
import { TABLE_NUMBERS } from "../../../common/tableNumbers";
import classNames from "../manage.module.css";
import Typography from "../../../components/ui/typography";
import { detailedUpdatesLink } from "./generateLinks";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "../../../components/ui/button";
import PdfExporterSEBF_TF from "../../../components/pdfcreate/3_tr_finalleri/pdfExporterSEBF_TF";
import PDFTfIzinYazisi from "../../../components/pdfcreate/3_tr_finalleri/tf_izin_yazisi";
import PdfExporterTableNumberInformation from "../../../components/pdfcreate/3_tr_finalleri/pdfTableNumberDoc";
import { GoogleFormsLink } from "./objectionBox";
import {
  insertTemporaryResults,
  pullTfResultsFromFirebaseByTableNo,
} from "../../../firebaseMethods";
import { Height } from "@material-ui/icons";

export const TfSection = ({
  user,
  studentList,
  users_of_this_school,
  coachNameOfStudent,
  onDisclaimButtonPress,
}) => {
  const [params, setParams] = useState({
    anyCoachUserAttendedToTF: false,
    anySchoolUserAttendedToTF: false,
    anyStudentOfThisCoachTakesTFsuccessDoc: false,
    anyStudentOfThisSchoolTakesTFsuccessDoc: false,

    // studentList: [], //useState olarak güncellendi
  });

  const [tfAnswerForm, setTfAnswerForm] = useState(null);
  const [questionScores, setQuestionScores] = useState({});

  useEffect(() => {
    // Exam results geldiğinde öğrenci listesi üzerinden 1 tur geçip bu okuldan hangi sınavlara katılım olduğunu bulalım:
    const schoolResults = studentList; // 1-3
    const allStudentsOfThisSchool = users_of_this_school; // 5

    if (user?.role === 1 && allStudentsOfThisSchool) {
      let anyCoachUserAttendedToTF = false;
      let anySchoolUserAttendedToTF = false;
      let anyStudentOfThisCoachTakesTFsuccessDoc = false;
      let anyStudentOfThisSchoolTakesTFsuccessDoc = false;

      for (let i = 0; i < schoolResults.length; i++) {
        // sadece bu öğretmenin öğrencilerini kontrol eder:
        if (schoolResults[i].willUserTakeTFBasariBelgesi === true) {
          anyCoachUserAttendedToTF = true;
          anyStudentOfThisCoachTakesTFsuccessDoc = true;
        }

        if (schoolResults[i].willUserTakeTFKatilimBelgesi === true) {
          anyCoachUserAttendedToTF = true;
        }
      }

      for (let x = 0; x < allStudentsOfThisSchool.length; x++) {
        if (allStudentsOfThisSchool[x].willUserTakeTFBasariBelgesi === true) {
          anySchoolUserAttendedToTF = true;
          anyStudentOfThisSchoolTakesTFsuccessDoc = true;
        }
        if (allStudentsOfThisSchool[x].willUserTakeTFKatilimBelgesi === true) {
          anySchoolUserAttendedToTF = true;
        }
      }

      setParams({
        ...params,
        anyCoachUserAttendedToTF,
        anySchoolUserAttendedToTF,
        anyStudentOfThisCoachTakesTFsuccessDoc,
        anyStudentOfThisSchoolTakesTFsuccessDoc,
      });
    }
  }, [studentList, users_of_this_school]);

  useEffect(() => {
    if (user?.role === 0) {
      const school_class = user.school_id + "_" + user.class_id;
      let tableNumber = TABLE_NUMBERS[school_class];
      // pullAnswerFormFromDb(tableNumber);
      // pullQuestionScoresFromFirebase();
    }
  }, [user]);

  const pullAnswerFormFromDb = async (tableNo) => {
    const form = await pullTfResultsFromFirebaseByTableNo(tableNo);
    if (form?.answers) {
      setTfAnswerForm(form);
    }
  };

  const pullQuestionScoresFromFirebase = async () => {
    const scores = await pullTfResultsFromFirebaseByTableNo(
      "0_question_scores"
    );
    setQuestionScores(scores);
  };

  const willCoachTakeSuccessDoc = params.anyStudentOfThisCoachTakesTFsuccessDoc;
  const willCoachTakeAttendanceDoc =
    !willCoachTakeSuccessDoc && params.anyCoachUserAttendedToTF;

  const willSchoolTakeSuccessDoc =
    params.anyStudentOfThisSchoolTakesTFsuccessDoc;
  const willSchoolTakeAttendanceDoc =
    !willSchoolTakeSuccessDoc && params.anySchoolUserAttendedToTF;

  if (
    user.role === 1 &&
    !willCoachTakeSuccessDoc &&
    !willCoachTakeAttendanceDoc
  )
    return null;

  const coach_belge_render = () => {
    if (user.role === 1) {
      if (willCoachTakeSuccessDoc) {
        return (
          <PdfExporter
            type={PDF_DOC_PROPS.tf_basari_ogretmen.type}
            school={
              user.school_name + " (" + user.city + "/" + user.district + ")"
            }
            name={user.name}
          />
        );
      } else if (willCoachTakeAttendanceDoc) {
        return (
          <PdfExporter
            type={PDF_DOC_PROPS.tf_katilim_ogretmen.type}
            school={
              user.school_name + " (" + user.city + "/" + user.district + ")"
            }
            name={user.name}
          />
        );
      }
    } else {
      return null;
    }
  };

  const school_belge_render = () => {
    if (user.role === 1) {
      if (willSchoolTakeSuccessDoc) {
        return (
          <PdfExporter
            type={PDF_DOC_PROPS.tf_basari_okul.type}
            school={user.city + " / " + user.district}
            name={user.school_name}
          />
        );
      } else if (willSchoolTakeAttendanceDoc) {
        return (
          <PdfExporter
            type={PDF_DOC_PROPS.tf_katilim_okul.type}
            school={user.city + " / " + user.district}
            name={user.school_name}
          />
        );
      }
    }
  };

  let anyStudentOfThisCoachWillAttendTF = false;
  let trFinaliStudentList = "";

  studentList.forEach((student) => {
    const bfResult = student?.exam_results.find((r) => r.season === "2024_bf");

    const conclusion = bfResult?.conclusion;
    if ([1, 3].includes(conclusion)) {
      anyStudentOfThisCoachWillAttendTF = true;
      trFinaliStudentList = trFinaliStudentList + ", " + student.name;
    }
  });


  if (user.role === 1 && !anyStudentOfThisCoachWillAttendTF) return null;

  trFinaliStudentList = trFinaliStudentList.substring(1);

  const bf_2024_conclusion =
    user?.exam_results.find((r) => r.season === "2024_bf") ?? {};

  const bfConclusion = bf_2024_conclusion.conclusion;

  if (user.role === 0 && ![1, 3].includes(bfConclusion)) return null;

  const schoolName =
    user && user.school_name
      ? user.school_name + " (" + user.city + "/" + user.district + ")"
      : "";

  const school_class = user.school_id + "_" + user.class_id;
  let tableNumber = TABLE_NUMBERS[school_class];

  const hr = (
    <hr
      width="100%"
      color="white"
      style={{ marginTop: 10, marginBottom: 10 }}
    />
  );

  const studentTableTF = (
    <div style={{ marginTop: 20 }}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {[
                { name: "ID", align: "center" },
                { name: "Ad-Soyad", align: "left" },
                { name: "Sınıf", align: "center", hideForThisRole: 0 },
                { name: "Puanı", align: "center" },
                { name: "TR Sırası", align: "center" },
                { name: "Katılım/Başarı Belgesi", align: "center" },
                { name: "Sosyal Etkinlik Formu", align: "center" },
              ].map(
                (row) =>
                  row.hideForThisRole !== user.role && (
                    <TableCell
                      key={row.name + "_user_list_column_name"}
                      style={{ fontWeight: "bold" }}
                      align={row.align}
                    >
                      {row.name}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {studentList.map((row) => {
              const generateTfSEBF = () => {
                if (row.willUserTakeTF_SEBF)
                  return (
                    <Button
                      style={{
                        background: "green",
                        fontSize: 12,
                      }}
                    >
                      <PdfExporterSEBF_TF
                        size="small"
                        type={PDF_DOC_PROPS.tf_sebf.type}
                        school={row.school_name}
                        name={row.name}
                        fileNumber={"TZVSMP23 TR/" + row.user_id}
                      />
                    </Button>
                  );
              };
              const generateTfBasariSEBF = () => {
                if (row.willUserTakeTF_SEBF)
                  return (
                    <Button
                      style={{
                        background: "green",
                        fontSize: 12,
                      }}
                    >
                      <PdfExporterSEBF_TF
                        size="small"
                        type={PDF_DOC_PROPS.tf_sebf_basari.type}
                        school={row.school_name}
                        name={row.name}
                        fileNumber={"TZVSMP23 TR/" + row.user_id}
                      />
                    </Button>
                  );
              };
              const tfBasari = row.willUserTakeTFBasariBelgesi && (
                <PdfExporter
                  size="small"
                  type={PDF_DOC_PROPS.tf_basari_ogrenci.type}
                  school={
                    row.school_name + " (" + row.city + "/" + row.district + ")"
                  }
                  name={row.name}
                />
              );

              const tfKatilim = row.willUserTakeTFKatilimBelgesi && (
                <PdfExporter
                  size="small"
                  type={PDF_DOC_PROPS.tf_katilim_ogrenci.type}
                  school={
                    row.school_name + " (" + row.city + "/" + row.district + ")"
                  }
                  name={row.name}
                />
              );

              const bf_2024_result =
                row?.exam_results.find((r) => r.season === "2024_bf") ?? {};

              const bfConclusion = bf_2024_result.conclusion;
              if (![1, 3].includes(bfConclusion)) return null;

              const tfResult =
                row?.exam_results.find((r) => r.season === "2024_tf") ?? {};

              const tfOrder = tfResult.order_in_country ?? "-";
              const tfScore = tfResult.score ?? "-";

              return (
                <TableRow key={row.user_id + "user_id"}>
                  <TableCell align="center">{row.user_id}</TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  {/* <TableCell align="left">{row.email}</TableCell> */}
                  {user.role === 1 && (
                    <TableCell align="center" style={{ margin: 0, padding: 0 }}>
                      {" "}
                      {row.class_id}
                    </TableCell>
                  )}

                  <TableCell align="center"> {tfScore}</TableCell>
                  <TableCell align="center"> {tfOrder}</TableCell>

                  {
                    <TableCell align="center">
                      {" "}
                      {(user.role === 1 || user.id === row.user_id) &&
                        (tfBasari ?? tfKatilim)}
                    </TableCell>
                  }
                  {
                    <TableCell align="center">
                      {
                        (user.role === 1 && tfOrder <= 3) ? generateTfBasariSEBF() :
                          (user.role === 0 && user.id === row.user_id && tfOrder <= 3) ? generateTfBasariSEBF() :
                            (user.role === 1 && tfOrder > 4) ? generateTfSEBF() :
                              (user.role === 0 && user.id === row.user_id && tfOrder > 4) ? generateTfSEBF() :
                                null
                      }
                    </TableCell>
                  }
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  const plakaFormu = (
    <GoogleFormsLink
      buttonName="Plaka Bildirim Formu"
      formLink={
        "https://docs.google.com/forms/d/e/1FAIpQLSe6M2fSeUpvPM68Lp5pdVatc_5HFXu3ndfThvrtLCkSAw5M3w/viewform?usp=pp_url&entry.896380930=" +
        user.id +
        "&entry.1095764133= " +
        user.email
      }
      description="Sınav merkezine araçla gelecek yarışmacılar 1 Mayıs Çarşamba saat 23:59'a kadar bu form üzerinden araç plakalarını bildirmelidir."
    />
  );

  const disclamationButton = user.role === 0 && (
    <>
      <PDFTfIzinYazisi
        size="small"
        school={schoolName}
        name={trFinaliStudentList}
        coachName={user.role === 0 ? coachNameOfStudent : user.name}
      />
      <div style={{ fontStyle: "italic", color: "grey", marginTop: 10 }}>
        Öğrencilerin sorumlu öğretmenleri ile birlikte haftasonu şehir dışına
        çıkabilmesi için bazı okullar izin dilekçesi talep etmektedir. Talep
        edilmesi halinde yukarıdaki dilekçeyi okul yönetimine teslim
        edebilirsiniz.
      </div>
      {hr}
    </>
  );

  const izinYazisiPdfExporterButton = user.role === 0 && (
    <>
      <PDFTfIzinYazisi
        size="small"
        school={schoolName}
        name={trFinaliStudentList}
        coachName={user.role === 0 ? coachNameOfStudent : user.name}
      />
      <div style={{ fontStyle: "italic", color: "grey", marginTop: 10 }}>
        Öğrencilerin sorumlu öğretmenleri ile birlikte haftasonu şehir dışına
        çıkabilmesi için bazı okullar izin dilekçesi talep etmektedir. Talep
        edilmesi halinde yukarıdaki dilekçeyi okul yönetimine teslim
        edebilirsiniz.
      </div>
    </>
  );

  const objectionBox = (
    <GoogleFormsLink
      buttonName="Türkiye Finalleri Sınavı İtiraz Formu"
      formLink={
        "https://docs.google.com/forms/d/e/1FAIpQLSdL_LWt-grs5i1kKp6OcNvdeiWW9FEAFDuQk9bOYcUc7-JyvA/viewform?usp=pp_url&entry.1157331533=" +
        user.id +
        "&entry.427270648=" +
        user.email +
        "&entry.1902692000=" +
        user.class_id
      }
      description="Sorular ve cevap anahtarı için son itiraz tarihi 8 Mayıs 2024 Çarşamba saat 23:59'dur. Bu tarihten sonra sorulara ve cevap anahtarına yapılacak itirazlar değerlendirmeye alınmayacaktır. "
    />
  );

  const answersOfTheUser = user.role === 0 && tfAnswerForm && (
    <div style={{ color: "white", width: "100%" }}>
      <h6>CEVAPLARINIZIN DEĞERLENDİRMESİ</h6>
      {tfAnswerForm.answers.split("").map((answer, index) => {
        const questionScore = questionScores[user.class_id]
          ? questionScores[user.class_id][index]
          : "";
        const textForCorrectAnswer =
          "Doğru (Soru Puanı: " + questionScore + " )";

        const textForWrongAnswer = "Yanlış/Boş";

        return (
          <div style={{ border: "solid white 1px", width: 300 }} key={index}>
            {"Soru-" + (index + 1) + ": "}
            <span style={{ marginLeft: 7 }}>
              {answer === "1" ? textForCorrectAnswer : textForWrongAnswer}
            </span>
          </div>
        );
      })}
      <br />
      <div>{"PUANINIZ : " + tfAnswerForm.score}</div>
      <div>{"Ek Soru Puanınz : " + tfAnswerForm.additionalScore}</div>
      <div>{"Türkiye Dereceniz : " + tfAnswerForm.rank}</div>
      <br />

      <p>
        NOT: Kesin sonuçlar yayınlanana kadar puanlarda ve derecelerde
        değişiklik olabilir. Lütfen doğru cevaplarınızı ve puan toplamlarınızı
        kontrol ediniz. Hata tespit etmeniz halinde info@tzv.org.tr adresine
        e-posta ile bilgi veriniz.
      </p>
    </div>
  );

  return (
    <div className={"card " + classNames.left_step}>
      <Typography size="small" color="white" font="tadao" weight="normal">
        TÜRKİYE FİNALLERİ
      </Typography>
      {/* {detailedUpdatesLink("Sınav Yönergesi")} */}
      {/* {asilMi && generateTrFinaliLink(user)} */}
      {/* {detailedUpdatesLink("Okul Puanları")} */}

      {studentTableTF}
      {detailedUpdatesLink("Türkiye Finalleri Sınavı Sonuçları")}
      {/* {user.role === 0 && (
        <PdfExporterTableNumberInformation
          class_id={user.class_id}
          school={schoolName}
          name={trFinaliStudentList}
          table_number={tableNumber}
        />
      )} */}
      {user.role === 1 && coach_belge_render()}
      {user.role === 1 && school_belge_render()}
      {/* {hr} */}

      {/* {user.role === 0 && objectionBox} */}
      {/* {user.role === 0 && hr} */}
      {/* {user.role === 0 && answersOfTheUser} */}

      {/* {plakaFormu} */}
      {/* {izinYazisiPdfExporterButton} */}
    </div>
  );
};
