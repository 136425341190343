import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory as createHistory } from "history";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

//

export const history = createHistory();
const middleware = [thunk, routerMiddleware(history)];
const initialState = {};
const enhancers = [];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const storeAndPersistorReturner = () => {
  let store = createStore(persistedReducer, initialState, composedEnhancers);

  let persistor = persistStore(store);

  return { store, persistor };
};

export default storeAndPersistorReturner;

// const initialState = {};
// const enhancers = [];

// const middleware = [thunk, routerMiddleware(history)];

// if (process.env.NODE_ENV === 'development') {
//   const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

//   if (typeof devToolsExtension === 'function') {
//     enhancers.push(devToolsExtension())
//   }
// }

// const composedEnhancers = compose(
//   applyMiddleware(...middleware),
//   ...enhancers
// )

// export default createStore(
//   rootReducer(history),
//   initialState,
//   composedEnhancers
// )
