import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "./navlinks.module.css";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

const navLinks = [
  {
    to: "/hakkinda",
    text: "Hakkında",
    scroll: true,
  },
  {
    to: "/takvim",
    text: "Takvim",
    scroll: true,
  },
  // {
  //   to: "/kosullar",
  //   text: "Koşullar",
  //   scroll: true,
  // },
  {
    to: "/asamalar",
    text: "Aşamalar",
  },
  {
    to: "/kurul",
    text: "Kurul",
  },
  {
    to: "/gecmis",
    text: "Geçmiş",
  },
  {
    to: "/yardim",
    text: "Yardım",
  },
  {
    to: "/iletisim",
    text: "İletişim",
  },
];

const pastContests = [
  {
    to: "/2023",
    text: "2023-2024",
  },
  {
    to: "/2022",
    text: "2022-2023",
  },
  {
    to: "/2021",
    text: "2021-2022",
  },
  {
    to: "/2020",
    text: "2020-2021",
  },
  {
    to: "/2019",
    text: "2019-2020",
  },

  // {
  //   to: "/2018",
  //   text: "2018-2019",
  // },
];

const NavLinks = ({ color = "white", footer, hidden, closeMenu }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = (event) => {
    if (closeMenu) closeMenu();
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <>
      <ul
        className={
          classNames.nav_list +
          " " +
          color +
          (footer
            ? " " + classNames.navl_list_footer
            : hidden
            ? " " + classNames.hidden
            : "")
        }
      >
        {navLinks.map(({ to, text, scroll }, key) => {
          if (to === "/gecmis")
            return (
              <li key={"gecmis"} style={{ position: "relative", zIndex: 100 }}>
                <div>
                  <span
                    style={{ cursor: "pointer", zIndex: 99 }}
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={() => {
                      setOpen((prevOpen) => !prevOpen);
                    }}
                    // onMouseEnter={() => {
                    //   setOpen(true);
                    // }}
                  >
                    {text}
                  </span>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            onMouseLeave={() => {
                              setOpen(false);
                            }}
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {pastContests.map((item) => {
                              return (
                                <MenuItem
                                  key={item.text}
                                  onClick={handleClose}
                                  style={{
                                    backgroundColor: "silver",
                                    borderRadius: 8,
                                    marginBottom: 2,
                                    fontSize: "16px",
                                  }}
                                >
                                  <NavLink
                                    to={{ pathname: item.to }}
                                    activeClassName="orange"
                                    className={color}
                                  >
                                    {item.text}
                                  </NavLink>
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </ClickAwayListener>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </li>
            );
          else
            return (
              <li
                key={key}
                style={{ marginLeft: 5, marginRight: 5 }}
                onClick={closeMenu ? closeMenu : () => {}}
              >
                <NavLink
                  to={{ pathname: to, state: { scroll } }}
                  activeClassName="orange"
                  className={color}
                >
                  {text}
                </NavLink>
              </li>
            );
        })}
      </ul>
    </>
  );
};

export default NavLinks;
