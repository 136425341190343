import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React from "react";
import { PDF_DOC_PROPS } from "../../../common/constants";
import Button from "../../../components/ui/button";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const generatePDF = async (property) => {
  let docDefinition = {
    pageSize: "A4",
    pageMargins: [0, 0, 0, 0],
    background: {
      image: property.background_image,
      fit: [595.28, 842],
    },
    content: [
      {
        text: property.name,
        fontSize: 9,
        margin: [182, 85, 0, 0],
      },
      {
        text: property.school,
        fontSize: 7,
        margin: [182, 13, 0, 0],
      },

      {
        text: property.fileNumber,
        fontSize: 9,
        margin: [439, 170, 0, 0],
      },
      {
        text: property.fileNumber,
        fontSize: 9,
        margin: [361, 334, 0, 0],
      },
    ],
  };
  pdfMake.createPdf(docDefinition).download(property.file_name);
};

const PdfExporterSEBF_BF = (props) => {
  if (props.name == "") {
    return null;
  } else {
    const docProps = PDF_DOC_PROPS[props.type];
    return (
      <Button
        style={{
          background: "green",
          fontSize: props.size === "small" ? 12 : 20,
          // marginTop: "20px",
        }}
        onClick={() => {
          generatePDF({
            name: props.name,
            school: props.school,
            fileNumber: props.fileNumber,
            background_image: docProps.background_image,
            file_name: docProps.file_name,
          });
        }}
      >
        {docProps.button_text}
      </Button>
    );
  }
};
export default PdfExporterSEBF_BF;
