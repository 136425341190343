import React from "react";
import classNames from "./style.module.css";
import Typography from "../../../../components/ui/typography";

const Sponsors = () => (
  <div className={classNames.sponsors}>
    <Typography size="bigger" color="orange" font="merel">
      Destekleyenler
    </Typography>

    <div
      className={"card"}
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        marginTop:10
      }}
    >
      <Typography
        size="normal"
        color="#4c4949"
        font="merel"
        className={classNames.medya}
      >
        Ana Sponsor
      </Typography>
      <div>
        <a target="_blank" rel="noopener noreferrer" href="https://easyvit.com.tr/">
          <img
            src="\assets\images\isbank-logo.svg"
            alt="easyvit logo"
            style={{ maxWidth: 250, padding: 10 ,marginBottom:20}}
          />
        </a>
      </div>

      {/* <Typography
        size="small"
        color="#4c4949"
        font="merel"
        className={classNames.medya}
      >
        Medya Sponsoru
      </Typography>

      <div>
        <a target="_blank" href="https://www.radyoodtu.com.tr/">
          <img
            src="/assets/images/radyoodtu.png"
            alt="Radyo ODTÜ"
            style={{ maxWidth: 300, padding: 10 }}
          />
        </a>
      </div> */}
    </div>

    {/* <Typography size="small" color="white" font="tadao" weight="normal" className={classNames.description}>
      Türkiye Okullar Arası Zeka Oyunları Şampiyonası Millî Eğitim Bakanlığı'nın 
      <a target="_blank" className={classNames.desclink} href="http://ogm.meb.gov.tr/www/31-2020-2021-turkiye-okullar-arasi-zeka-oyunlari-sampiyonasi/icerik/1047"> izni </a> ve TÜBİTAK'ın destekleri ile düzenlenmektedir.
      
      <a target="_blank" className={classNames.desclink} href="/assets/files/MEB_onay_2020.pdf" download> izni </a> ve TÜBİTAK'ın destekleri ile düzenlenmektedir.
    </Typography> */}

    {/* <div className={"card "+classNames.sponsor_card }>
        <div>
        <img src="/assets/images/meb-logo.png" alt="meb logo"/>
        </div>
      <div>
        <img src="/assets/images/tubitak-logo.png" alt="tübitak logo"/>
        </div>
    </div> */}

    {/* <div className={"card " + classNames.medya_card}> */}

    {/* <div>
        <a target="_blank" href="https://www.webtekno.com/"><img src="/assets/images/webtekno.png" alt="webtekno"/></a>
        </div> */}
    {/* </div> */}
  </div>
);

export default Sponsors;
