import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "../../store/actions/generic";
import Checkbox from "@material-ui/core/Checkbox";
import { getDistrictSchools as getDistrictSchoolsAction } from "../../store/actions/generic";

import Input from "../../components/ui/input/input";
// import Button from "../../components/ui/button";
import Button from "@material-ui/core/Button";
import {
  GET_FILTERED_USERS,
  GET_USER_BY_ID,
  UPDATE_USER_BY_ID,
  DELETE_USER,
} from "../../store/actionTypes";
import { schoolCities, IF_RESULT_TEXTS } from "../../common/constants";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  getFilteredUsers,
  updateUserById,
  deleteUser,
} from "../../store/actions/generic";
import Modal from "@material-ui/core/Modal";
import MaterialBackDrop from "../../components/ui/loading/materialBackDrop";
import UpdateUser from "../manage/updateUser/updateUser";
import { actionCreate } from "../../store/actions/actionCreator";
import {
  pullUserDataFromFirebase,
  pullUserDataFromFirebaseBySchool,
  pullUserDataFromFirebaseByArray,
} from "../../firebaseMethods";
// import { competitorList, verdict, verdictNames } from "../../common/ilFinaliData_11";

const Users = () => {
  const user = useSelector((state) => state.auth && state.auth.user);
  // const selectedUser = useSelector((state) => state.generic.selectedUser);
  const loadingUserUpdate = useSelector(
    (state) => state.loading[UPDATE_USER_BY_ID]
  );

  const loadingSelectedUser = useSelector(
    (state) => state.loading[GET_USER_BY_ID]
  );
  const loadingFilteredUsers = useSelector(
    (state) => state.loading[GET_FILTERED_USERS]
  );

  const loadingDeleteUser = useSelector((state) => state.loading[DELETE_USER]);

  const dispatch = useDispatch();
  const districtSchools = useSelector((state) => state.generic.districtSchools);
  let users_of_this_filter = useSelector(
    (state) => state.generic.filtered_users
  );

  useEffect(() => {
    dispatch(actionCreate("CLEAR_FILTERED_USERS"), { payload: [] });
  }, []);

  useEffect(() => {
    getExamResultsFromFb();
  }, [users_of_this_filter]);

  const [params, setParams] = useState({
    textForFilter: "",
    city: "",
    district: "",
    schoolId: "",
    role: 0,
    showUserModal: false,
    selectedUserId: -1,
    selectedUserName: null,
    selectedUserEmail: null,
    selectedUserPhone: null,
    selectedUserDistrict: null,
    selectedUserCity: null,
    selectedUserSchoolId: null,
    selectedUserRole: null,
    selectedUserClass: null,
    selectedUser: { name: "yükleniyor..." },
    examResults: {},
  });

  const getExamResultsFromFb = async () => {
    // Daha önce fb'den sınav sonucunu çekmediğimiz kullanıcı id'lerini bir array'e atalım:
    let missingIds = [];
    users_of_this_filter.forEach((u) => {
      if (!params.examResults[u.id] && u.is_paid) missingIds.push(u.id);
    });

    if (missingIds.length > 0) {
      let examResultsSubSet = {}; // firebase'den sonuç çekilirken aynı işlem tekrarlanmasın diye bunları params'a bi yazalım da sonra çekeriz.
      missingIds.forEach((m_id) => {
        examResultsSubSet[m_id] = { waiting: true };
      });

      examResultsSubSet = { ...params.examResults, ...examResultsSubSet };
      setParam("examResults", examResultsSubSet);

      // şimdi missing ids için veri çekelim:

      const res = await pullUserDataFromFirebaseByArray(missingIds);
      examResultsSubSet = { ...examResultsSubSet, ...res };
      setParam("examResults", examResultsSubSet);
    }
  };

  const setParam = (key, value) => {
    setParams({
      ...params,
      [key]: value,
    });
  };

  const reloadDistrictSchools = (city, district) => {
    dispatch(getDistrictSchoolsAction(city, district));
  };

  const applyFilter = () => {
    if (
      !params.schoolId &&
      (!params.textForFilter || params.textForFilter.length < 4)
    )
      alert(
        "Lütfen okul seçin, ya da en az 4 karakter uzunluğunda text filtresi ekleyin!"
      );
    else {
      const filter = {
        school_id: params.schoolId,
        text_for_filter: params.textForFilter
          .trim()
          .replace("İ", "i")
          .replace("ı", "i"),
      };
      dispatch(getFilteredUsers(filter));
    }
  };

  // const pullResutlsBySchoolId = async (school_id, resultSetter) => {

  //   const res = await pullUserDataFromFirebaseBySchool(school_id);
  //   resultSetter("examResults", res);
  // }

  const textInputForFilter = (
    <div style={{ flex: 3, marginRight: "10px" }}>
      <Input
        autoComplete="off"
        label="Text Filtresi"
        name="textForFilter"
        placeholder="e-mail/ad/soyad"
        onChange={({ target: { name, value } }) => setParam(name, value)}
        value={params.textForFilter}
        style={{ height: "55px" }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            applyFilter();
          }
        }}
      />
    </div>
  );
  const schoolCityChooser = (
    <div style={{ flex: 2, marginRight: "10px" }}>
      <Input
        name="city"
        placeholder="Seçiniz"
        label="Okul İli"
        select
        value={params.city}
        onChange={(e, newItem) => {
          setParams({
            ...params,
            district: "",
            city: newItem ? newItem.value : "",
            schoolId: "",
          });
        }}
        options={Object.keys(schoolCities)
          .sort((a, b) => a.localeCompare(b))
          .map((cityName) => {
            return { value: cityName, title: cityName };
          })}
      />
    </div>
  );
  const schoolDistrictChooser = (
    <div style={{ flex: 2, marginRight: "10px" }}>
      <Input
        name="district"
        placeholder="Seçiniz"
        label="Okul İlçesi"
        select
        value={params.district}
        onChange={(e, newItem) => {
          setParams({
            ...params,
            district: newItem ? newItem.value : "",
            schoolId: "",
          });
          const district = newItem ? newItem.value : "";
          reloadDistrictSchools(params.city, district);
        }}
        options={
          schoolCities[params.city] &&
          schoolCities[params.city]
            .sort((a, b) => {
              return a.localeCompare(b);
            })
            .map((districtName) => {
              return { value: districtName, title: districtName };
            })
        }
      />
    </div>
  );
  const schoolChooser = (
    <div style={{ flex: 4, marginRight: "10px" }}>
      <Input
        name="schoolId"
        placeholder="Seçiniz"
        label="Okul"
        select
        value={params.schoolId}
        onChange={(e, newItem) => {
          setParams({
            ...params,
            schoolId: newItem ? newItem.value : "",
          });
        }}
        options={districtSchools.map((s) => ({ value: s.id, title: s.name }))}
      />
    </div>
  );

  const applyButton = (
    <div style={{ flex: 1, marginBottom: "15px" }}>
      <Button
        style={{ marginLeft: "20px", height: "50px" }}
        variant="contained"
        color="secondary"
        onClick={applyFilter}
      >
        Uygula
      </Button>
    </div>
  );
  const userFilter = (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      {textInputForFilter}
      {schoolCityChooser}
      {schoolDistrictChooser}
      {schoolChooser}
      {applyButton}
    </div>
  );

  const userTable = (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {[
              { name: "id", align: "left" },
              { name: "Ad-Soyad", align: "left" },
              { name: "E-Posta", align: "left" },
              { name: "Okul", align: "center" },
              { name: "Sınıf", align: "center" },
              { name: "Ücret", align: "center" },
              // { name: "iF Puan", align: "center" },
              // { name: "iF Ek Puan", align: "center" },
              // { name: "BF Katılım", align: "center" },
              // { name: "Bl.Sonuç", align: "center" },
              // { name: "Tr.Katılım", align: "center" },
            ].map((row) => (
              <TableCell
                style={{ fontWeight: "bold" }}
                align={row.align}
                key={"kolon_keyi_" + row.name}
              >
                {row.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {users_of_this_filter
            .sort((a, b) => {
              // const x = a.product_title ? a.product_title : "";
              // const y = b.product_title ? b.product_title : "";
              const aClass = a.class_id || 13;
              const bClass = b.class_id || 13;
              return bClass - aClass;
              // return (x + a.name).localeCompare(y + b.name);
            })
            .map((row) => {
              let classOfTheRow =
                row.role === 1
                  ? row.all_coaches_of_this_school
                      .map((u) => {
                        if (u.id === row.id) return " " + u.class_id;
                      })
                      .filter((u) => u)
                      .toString()
                  : row.class_id;

              let paymentStatus = "";

              if (row.is_paid) {
                paymentStatus = "Ödendi";
                let token = row.paid_receipts[0].token;
                if (token.length < 35) paymentStatus = token;
                // admin sistem üzerinden durumu ödendi olarak değiştirdiyse, receipt tablosundaki ilgili rowun token alanına ödemeyi yapan adminin ismini kaydediyorum.
                // user kendisi kartla ödediyse token 40 hanelik random karakterlerden oluşuyor.
              }

              // let exam_results = params.examResults[row.user_id] || {};

              // let if_result_text = "";
              // let if_score = "";
              // let if_additional_score = "";

              // if (exam_results) {
              //   if (exam_results.waiting) if_result_text = "?";
              //   else if (exam_results.if_result) {
              //     if_result_text =
              //       IF_RESULT_TEXTS[exam_results.if_result.conclusion]
              //         .adminText;
              //     if_score = exam_results.if_result.score.toFixed(3);
              //     if_additional_score =
              //       exam_results.if_result.additional_score.toFixed(3);
              //   }
              // }

              return (
                <TableRow key={row.user_id}>
                  <TableCell align="left">{row.user_id}</TableCell>
                  <TableCell
                    align="left"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setParams({
                        ...params,
                        showUserModal: true,
                        selectedUser: row,
                        // selectedUserId: row.user_id,
                        // selectedUserName: row.name,
                        // selectedUserEmail: row.email,
                        // selectedUserPhone: row.phone,
                        // selectedUserRole: row.role,
                        // selectedUserSchoolId: row.school_id,
                        // selectedUserClass: row.class_id,
                      });

                      // dispatch(getUserById(row.user_id));
                    }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">
                    {row.school_name.substring(0, 25)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: row.role === 1 ? "#e9cccc" : "white",
                    }}
                    title={row.role === 1 ? "ÖĞRETMEN" : ""}
                  >
                    {classOfTheRow}
                  </TableCell>
                  <TableCell align="center">{paymentStatus}</TableCell>
                  {/* <TableCell align="center"> {if_score}</TableCell> */}
                  {/* <TableCell align="center"> {if_additional_score}</TableCell> */}
                  {/* <TableCell align="center"> {if_result_text}</TableCell> */}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const userListBox = (
    <div
      style={{
        // backgroundColor: "white",
        marginLeft: "30px",
        height: "500px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {userFilter}
      {userTable}
    </div>
  );

  return (
    <div>
      {(loadingSelectedUser || loadingUserUpdate || loadingFilteredUsers) && (
        <MaterialBackDrop />
      )}
      {/* {params.showUserModal && renderUserModal()} */}
      {userListBox}
      {params.showUserModal && (
        <UpdateUser
          onClose={() => {
            setParam("showUserModal", false);
            applyFilter();
          }}
          selectedUser={params.selectedUser}
        />
      )}
    </div>
  );
};

export default Users;
