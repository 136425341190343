import React from "react";
import classNames from "../manage.module.css";
import { Button, LinearProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import Typography from "../../../components/ui/typography";

export const PaymentInstructionsBox = ({ user }) => {
  return (
    <div
      style={{ color: "white", fontSize: "1.3rem" }}
      className={"card " + classNames.left_step}
    >
      {/* <div>Kayıt/Ödeme süreci sona ermiştir. </div>  */}
      {!user.class_id && (
        <div>
         <Typography
            style={{
              fontStyle: "italic",
              color: "orange",
              fontSize: "18px",
            }}
          >
            {
              "Yarışmaya katılabilmek için lütfen ödeme yapınız. (Ödeme yapabilmek için önce sınıf ve okul bilgilerinizi güncelleyiniz.)"
            }
          </Typography>
        </div>
      )}
      {user.class_id && (
        <div>Yarışmaya katılabilmek için lütfen ödeme yapınız.</div>
      )}
      {user.class_id && (
        <Link to="/satinal">
          <Button
            style={{
              textDecoration: "none",
              backgroundColor: "green",
              fontSize: "20px",
              width: 140,
              height: 50,
              padding: 5,
              textAlign: "center",
              borderRadius: 6,
              marginTop: 6,
              color: "white",
              textTransform: "none",
            }}
          >
            Ödeme Yap
            <LinearProgress color="secondary" />
          </Button>
        </Link>
      )}
    </div>
  );
};
