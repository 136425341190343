import React from "react";
import Typography from "../../../../components/ui/typography";
import Carousel from "../../../../components/carousel";
import Slide from "./slide";
import classNames from "./style.module.css";
const slides = [
  /*  {
    date: "15.01.2024 15.00",
    title: "Ücretsiz Deneme Sınavı",
    text: "Deneme sınavı 20 Ocak Cumartesi saat 13.00'te şampiyona profil sayfasında çevrim içi olarak gerçekleştirilecektir. Kayıt olan herkes sınava katılabilir. Sınav süresi 45 dakikadır.",
  }, */
  {
    date: "01.10.2024 10.00",
    title: "2024-2025 Sezonu Şampiyona Kayıtları Başladı",
    text: "Şampiyona'ya ilk kez katılacak olanlar kaydol butonuna tıklayarak, daha önce katılmış olanlar profil sayfalarından bilgilerini güncelleyerek kayıt olabilirler.",
    // url: "../updates",
  },
//kanal linki güncelle
  {
    date: "01.10.2024 10.00",
    title: "Whatsapp Duyuru Kanalımız Yayında",
    text: " Şampiyona ile ilgili tüm duyuru ve hatırlatmalar için aşağıdaki bağlantıya tıklayarak Whatsapp kanalımıza üye olabilirsiniz. Üyelerin ad-soyad-telefon bilgileri gizlidir, kanal üzerinde paylaşılmaz. Dilediğiniz zaman kanaldan ayrılabilirsiniz.",
    url: "https://whatsapp.com/channel/0029VaIl6730Qeak6SX1yh1M",
    linkTitle: "Whatsapp Duyuru Kanalı",
  },
];

const Updates = () => (
  <div className={classNames.updates}>
    <Typography color="orange" size="bigger">
      Güncellemeler
    </Typography>
    <Typography color="white" className={classNames.description}>
      Yarışma hakkındaki güncelleme ve gelişmeleri buradan takip edebilirsiniz.
    </Typography>
    <Carousel
      slides={slides.map((slide, key) => (
        <Slide {...slide} key={key} />
      ))}
    />
  </div>
);

export default Updates;
