import {
  GET_DISTRICT_SCHOOLS,
  GET_FILTERED_USERS,
  GET_STATS,
  GET_USER_BY_ID,
  actionTypeSuccess,
  actionTypeFail,
  // UPDATE_USER_BY_ID,
  SET_SELECTED_STUDENTS_FOR_MULTIPLE_PAYMENT,
} from "../actionTypes";

const initialState = {
  districtSchools: [],
  filtered_users: [],
  school_name: "",
  stats: null,
  selectedUser: null,
  selectedStudentsForMultiplePayment: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypeSuccess(GET_DISTRICT_SCHOOLS):
      return {
        ...state,
        districtSchools: action.payload.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      };
    case actionTypeFail(GET_DISTRICT_SCHOOLS):
      return {
        ...state,
        districtSchools: [],
      };
    case actionTypeSuccess(GET_FILTERED_USERS):
      return {
        ...state,
        filtered_users: action.payload.users ? action.payload.users : [],
        school_name: action.payload.school_name
          ? action.payload.school_name
          : "",
      };

    case actionTypeFail(GET_FILTERED_USERS):
      return {
        ...state,
        filtered_users: [],
        school_name: "",
      };
    case "CLEAR_FILTERED_USERS":
      return {
        ...state,
        filtered_users: [],
        school_name: "",
      };

    case actionTypeSuccess(GET_USER_BY_ID):
      return {
        ...state,
        selectedUser: action.payload,
      };

    case actionTypeFail(GET_USER_BY_ID):
      return {
        ...state,
        selectedUser: null,
      };

    // case actionTypeSuccess(UPDATE_USER_BY_ID):
    // return {
    //   ...state,
    //   // selectedUser: action.payload

    // };

    // case actionTypeFail(UPDATE_USER_BY_ID):
    // return {
    //   ...state,
    //   // selectedUser:null
    // };

    case actionTypeSuccess(GET_STATS):
      return {
        ...state,
        stats: action.payload,
      };

    case actionTypeFail(GET_STATS):
      return {
        ...state,
        stats: null,
      };
    case SET_SELECTED_STUDENTS_FOR_MULTIPLE_PAYMENT:
      return {
        ...state,
        selectedStudentsForMultiplePayment: action.payload,
      };

    default:
      return state;
  }
};
