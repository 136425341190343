import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "./floatingMenu.module.css";

const links = {
  twitter: "http://twitter.com/ZekaVakfi",
  facebook: "http://facebook.com/TRZekavakfi",
  instagram: "https://instagram.com/zekavakfi",
};

const FloatingMenu = ({ onClick }) => (
  <div className={classNames.floatingMenu} onClick={onClick}>
    <div className={classNames.social}>
      <a href={links.instagram} rel="noopener noreferrer" target="_blank">
        <FontAwesomeIcon icon={["fab", "instagram"]} color="rgb(233, 89, 20)" />
      </a>
    </div>
    <div className={classNames.social}>
      <a href={links.facebook} rel="noopener noreferrer" target="_blank">
        <FontAwesomeIcon
          icon={["fab", "facebook-f"]}
          color="rgb(233, 89, 20)"
        />
      </a>
    </div>
    <div className={classNames.social}>
      <a href={links.twitter} rel="noopener noreferrer" target="_blank">
        <FontAwesomeIcon icon={["fab", "twitter"]} color="rgb(233, 89, 20)" />
      </a>
    </div>
    <div className={classNames.arrowContainer}>
      <FontAwesomeIcon icon="chevron-up" size="2x" color="rgb(233, 89, 20)" />
    </div>
  </div>
);

export default FloatingMenu;
