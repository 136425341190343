import React from "react";

const LabelAndText = ({ labelText, text, isHidden, title }) => {
  if (isHidden) return null;
  return (
    <div
      title={title}
      style={{
        display: "flex",
        // borderBottom: "#c3bebe70 solid 1px",
        borderBottom: "#ffa5008a solid 1px",
        width: "100%",
        fontSize: "calc(10pt + 0.3vw)",
        justifyContent: labelText ? "flex-start" :  "center"
      }}
    >
      {labelText && (
        <span
          style={{
            color: "orange",
            marginRight: 10,
            maxWidth: 130,
            minWidth: 130,
            padding: 5,
          }}
        >
          {labelText}
        </span>
      )}

      <span
        style={{
          color: "white",
          padding: 5,
          textAlign: "center"
        }}
      >
        {text ?? ""}
      </span>
    </div>
  );
};

export default LabelAndText;

{
  /* <div
            style={{ position: "absolute", left: -20, top: -1 }} // Öğrencinin sınıfını 2 saniye içinde kaybolan kırmızı yuvarlak içine alıyorum ki dikkat çeksin.
          >
            <Snackbar
              open={params.showControlClassAlert}
              autoHideDuration={3000}
              onClose={() => {
                setParam("showControlClassAlert", false);
              }}
              style={{
                position: "relative",
                border: "red solid",
                top: 0,
                width: "330px",
                height: "40px",
                borderRadius: 20,
              }}
            >
              <span></span>
            </Snackbar>
          </div> */
}

// <LinearProgress color="secondary" />
