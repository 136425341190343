import React from "react";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Typography from "../../components/ui/typography";

import classNames from "./style.module.css";

const Year2019_2020 = () => {
  const banner = (
    <div className={classNames.logo_wide}>
      <img src="/assets/images/2019/banner2019.png" alt="home-logo" />
    </div>
  );

  const resultVideo = (
    <div className={classNames.sponsors}>
      <Typography size="big" color="orange" font="merel">
        2019 Türkiye Okullar Arası Zeka Oyunları Yarışması Sonuçları
      </Typography>
      <Typography
        size="small"
        color="white"
        font="tadao"
        weight="normal"
        className={classNames.description}
        style={{ padding: 15 }}
      >
        Pandemi nedeniyle ertelenen ödül töreni, 26 Ekim 2020 tarihinde Millî
        Eğitim Bakanı Prof. Dr. Ziya Selçuk ve Türkiye Zeka Vakfı Başkanı
        Emrehan Halıcı'nın tebrik mesajları ile beraber aşağıdaki videodan
        yayınlanmıştır. Dereceye giren okulların kupa, madalya ve diğer
        belgeleri sorumlu öğretmenlerle iletişime geçilerek kargolanacaktır. Tüm
        okulları, takım üyelerini ve sorumlu öğretmenleri tebrik ederiz.
      </Typography>
      <div className={"card " + classNames.sponsor_card}>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/NWl8TOdAP30"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );

  const basinBulteni = (
    <div className={"card " + classNames.long_description}>
      <p>
        <b>
          2019 Türkiye Okullar Arası Zeka Oyunları Şampiyonası’nın Sonuçları
          İlan Edildi
        </b>
      </p>
      <p>
        Geçtiğimiz yıl ilki düzenlenen Türkiye Okullar Arası Zeka Oyunları
        Şampiyonası "Şampiyona 2019"un sonuçları 26 Ekim Pazartesi günü ilan
        edildi.
      </p>
      <p>
        <b>"Şampiyona 2019" İçin Sanal Ödül Töreni Düzenlendi</b>
      </p>
      <p>
        COVID-2019 Pandemisi nedeniyle Şampiyona sonuçlarının ilan edileceği
        Ödül Töreni, çevrim içi platformda düzenlendi. Tören, Milli Eğitim
        Bakanı Sayın Ziya Selçuk’un konuşması ile başladı.
      </p>
      <p>
        Bakan Selçuk konuşmasında yarışmayı düzenleyenleri ve yarışma
        katılımcılarını selamlarken, gelecek seneler için Şampiyona’nın yüz yüze
        yapılması dileklerinde bulundu.
      </p>
      <p>
        <b>
          Türkiye’nin Her Bölgesinden Yarışmaya Katılan Okullardan Dereceye
          Girenler Açıklandı
        </b>
      </p>
      <p>
        Sanal Ödül Töreni’nde 7 bölge, 81 ilden yarışmaya katılan okullardan ilk
        üçe girenler açıklandı.
      </p>
      <p>
        “İlkokul” kategorisinde{" "}
        <b>Burdur Alpaslan Ali Can Bilim ve Sanat Merkezi</b>; Öğretmen Ayşe
        Şimşek’in sorumlu olduğu Furkan Atça, Çağlayan Dinçtürk, Ali Alper
        Bilen, Tunahan Alp Özarslan ve Meriç Altunbaş’ın dâhil olduğu takım ile
        birinci,
      </p>
      <p>
        <b>Konya Bilim ve Sanat Merkezi;</b> Öğretmen Ahmet Özler’in sorumlu
        olduğu Berrin Kamalıoğlu, Arın Enhar Çalışkan, Alaaddin Eymen Evci,
        Ahmet Enes Sabancı ve Aras Mustafa Halil Doğu’nun dâhil olduğu takım ile
        ikinci,
      </p>
      <p>
        Denizli’de bulunan <b>Ülker Yörükoğlu Bilim ve Sanat Merkezi;</b>{" "}
        Öğretmen Kadir Alper Çetin’in sorumlu olduğu Yiğit Çağıl Öğmen, Mehmet
        Yiğit Çelik, Melih Kerem Avcı, Nisa Küçükşahal ve Azra Kavakoğlu’nun
        dâhil olduğu takım ile üçüncü oldu.
      </p>
      <p>
        “Ortaokul” kategorisinde
        <b> İstanbul Fuat Sezgin Bilim ve Sanat Merkezi; </b>
        Öğretmen Fatma Yudum Özer Akyüz’ün sorumlu olduğu Bersu Berker, Su Ece
        Boztepe, Kübra Efnan Dilmaç, Beyza Ebrar Dilmaç ve Sinan Gencer’in dâhil
        olduğu takım ile birinci,
      </p>
      <p>
        <b>Düzce Bilim ve Sanat Merkezi;</b> Öğretmen Bülent Şimşek’in sorumlu
        olduğu İrem Gülce Yazgan, Umut Kağan Yazgan ve Elif Naz Sarı’nın dâhil
        olduğu takım ile ikinci,
      </p>{" "}
      <p>
        Hatay’da bulunan <b>Şükrü Paşa Ortaokulu;</b> Öğretmen Gülten İçer’in
        sorumlu olduğu Elif Kahveci, Gizem Kahveci ve Mehmet Fatih Çakmak’ın
        dâhil olduğu takım ile üçüncü oldu.
      </p>
      <p>
        “Lise” kategorisinde <b>TED Ankara Koleji Vakfı Özel Lisesi;</b>{" "}
        Öğretmen Eser Yapıcı’nın sorumlu olduğu Yunus Alp Bıyıkoğlu, Selin
        Kasap, Gürkan İnal, Ata Soykal ve Doğukan Erdem’in dâhil olduğu takım
        ile birinci,
      </p>
      <p>
        <b>İstanbul Erkek Lisesi;</b> Öğretmen Ömer Arslan’ın sorumlu olduğu
        Mustafa Koyuncu, Bahar Açılan, Ahmet Ala, Emir Batu Göyer ve Ahmet Efe
        Serdar’ın dâhil olduğu takım ile ikinci,
      </p>
      <p>
        Kocaeli’de bulunan <b>Türk Eğitim Vakfı İnanç Türkeş Özel Lisesi</b>;
        Öğretmen Sevgül Çevik’in sorumlu olduğu Okan Tezcan, Simay Pala, Arman
        Özcan, Kerem Öner ve Kutay Sezgin’in dâhil olduğu takım ile üçüncü oldu.
      </p>
    </div>
  );

  const generateMedal = ({
    medalType,
    schoolLogoPath,
    schoolName,
    studentList,
    tutorList,
  }) => {
    const silverMedal = (
      <img src="/assets/images/medals/silver.png" alt="silver" />
    );
    const goldMedal = <img src="/assets/images/medals/gold.png" alt="gold" />;
    const bronzeMedal = (
      <img src="/assets/images/medals/bronze.png" alt="bronze" />
    );

    const medalImage =
      medalType === "gold"
        ? goldMedal
        : medalType === "silver"
        ? silverMedal
        : bronzeMedal;

    const schoolLogoImage = (
      <div>
        <img
          src={schoolLogoPath ? schoolLogoPath : "/assets/images/meb-logo.png"}
          alt="home-logo"
          width="120"
          height="120"
        />
      </div>
    );

    const schoolNameSpan = (
      <div
        style={{
          marginTop: 30,
          fontSize: "24px",
          fontWeight: "bolder",
          color: "#15273A",
        }}
      >
        {schoolName}
      </div>
    );

    const studentListBox = (
      <div style={{ marginTop: 15 }}>
        <div style={{ fontSize: "24px", color: "#E79121" }}>Takım</div>
        {studentList.map((s) => (
          <div style={{ fontSize: "20px" }}>{s}</div>
        ))}
      </div>
    );

    const tutorListBox = (
      <div style={{ marginTop: 15 }}>
        <div style={{ fontSize: "24px", color: "#E79121" }}>
          Sorumlu Öğretmen
        </div>
        {tutorList.map((t) => (
          <div style={{ fontSize: "20px" }}>{t}</div>
        ))}
      </div>
    );
    return (
      <div
        style={{
          borderRadius: "15px",
          position: "relative",
          width: 339,
          height: 633,
          textAlign: "center",
          lineHeight: 1.1,
          margin: 10,
        }}
      >
        {medalImage}
        <div
          style={{
            position: "absolute",
            top: 150,
            textAlign: "center",
            width: 338,
            paddingLeft: 27,
            paddingRight: 27,
          }}
        >
          {schoolLogoImage}
          {schoolNameSpan}
          {studentListBox}
          {tutorListBox}
        </div>
      </div>
    );
  };

  const generateMedalGroup = ({
    groupName,
    goldMedal,
    silverMedal,
    bronzeMedal,
  }) => {
    const header = (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "42px",
          paddingBottom: 20,
          borderBottom: "solid #DA571A 2px",
          marginBottom: 20,
          lineHeight: 1,
        }}
      >
        <span style={{ color: "#DA571A", textAlign: "left" }}>
          Türkiye Finalleri Sonuçları
        </span>
        <span style={{ color: "#15273A", fontWeight: "bold", marginLeft: 30 }}>
          {groupName}
        </span>
      </div>
    );

    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 15,
          padding: 20,
          maxWidth: 1200,
          margin: 20,
        }}
      >
        {header}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {goldMedal}
          {silverMedal}
          {bronzeMedal}
        </div>
      </div>
    );
  };

  const ilkokulMedals = generateMedalGroup({
    groupName: "İlkokul",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "Burdur Alpaslan Ali Can Bilim Ve Sanat Merkezi",
      studentList: [
        "Furkan Atça",
        "Çağlayan Dinçtürk",
        "Alper Bilen",
        "Tunahan Alp Özarslan",
        "Meriç Altunbaş",
      ],
      tutorList: ["Gökhan Karaaslan", "Ayşe Şimşek"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "Konya Bilim Ve Sanat Merkezi",
      studentList: [
        "Berrin Kamalıoğlu",
        "Arın Enhar Çalışkan",
        "Alaaddin Eymen Evci",
        "Ahmet Enes Sabancı",
        "Aras Mustafa Halil Doğu",
      ],
      tutorList: ["Ahmet Özler"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "",
      schoolName: "Ülker Yörükoğlu Bilim Ve Sanat Merkezi",
      studentList: [
        "Yiğit Çağıl Öğmen",
        "Mehmet Yiğit Çelik",
        "Melih Kerem Avcı",
        "Nisa Küçükşahal",
        "Azra Kavakoğlu",
      ],
      tutorList: ["Kadir Alper Çetin"],
    }),
  });

  const ortaokulMedals = generateMedalGroup({
    groupName: "Ortaokul",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "İstanbul Fuat Sezgin Bilim ve Sanat Merkezi",
      studentList: [
        "Bersu Berker",
        "Su Ece Boztepe",
        "Kübra Efnan Dilmaç",
        "Beyza Ebrar Dilmaç",
        "Sinan Gencer",
      ],
      tutorList: ["Fatma Yudum Özer Akyüz"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "Düzce Bilim ve Sanat Merkezi",
      studentList: ["İrem Gülce Yazgan", "Umut Kağan Yazgan", "Elif Naz Sarı"],
      tutorList: ["Bülent Şimşek"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "",
      schoolName: "Şükrü Paşa Ortaokulu ",
      studentList: ["Elif Kahveci", "Gizem Kahveci", "Mehmet Fatih Çakmak"],
      tutorList: ["Gülten İçer"],
    }),
  });

  const liseMedals = generateMedalGroup({
    groupName: "Lise",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "/assets/images/2019/ted.png",
      schoolName: "TED Ankara Koleji Vakfı Özel Lisesi",
      studentList: [
        "Yunus Alp Bıyıkoğlu",
        "Selin Kasap",
        "Gürkan İnal",
        "Ata Soykal",
        "Doğukan Erdem",
      ],
      tutorList: ["Yasemin Yılmaz", "Eser Yapıcı"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "İstanbul Erkek Lisesi",
      studentList: [
        "Mustafa Koyuncu",
        "Bahar Açılan",
        "Ahmet Ala",
        "Emir Batu Göyer",
        "Ahmet Efe Serdar",
      ],
      tutorList: ["Ömer Arslan"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "/assets/images/2019/tevitol.png",
      schoolName: "Türk Eğitim Vakfı İnanç Türkeş Özel Lisesi",
      studentList: [
        "Okan Tezcan",
        "Simay Pala",
        "Arman Özcan",
        "Kerem Öner",
        "Kutay Sezgin",
      ],
      tutorList: ["Sevgül Çevik", "Okan Tezcan"],
    }),
  });

  return (
    <div
      style={{
        marginTop: "120px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {banner}
      {resultVideo}
      {basinBulteni}
      {ilkokulMedals}
      {ortaokulMedals}
      {liseMedals}
      <ScrollToTop />
    </div>
  );
};

export default Year2019_2020;
