export const actionTypeRequest = (actionType) => actionType + "_REQUEST";
export const actionTypeFail = (actionType) => actionType + "_FAIL";
export const actionTypeSuccess = (actionType) => actionType + "_SUCCESS";

export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const CLEAR_AUTH = "CLEAR_AUTH";
export const PURCHASE = "PURCHASE";
export const PARTICIPANTS = "PARTICIPANTS";
export const UPDATE_USER = "UPDATE_USER";
export const MAIL_STATS = "MAIL_STATS";
export const ADD_SCHOOL = "ADD_SCHOOL";
export const GET_DISTRICT_SCHOOLS = "GET_DISTRICT_SCHOOLS";
export const GET_FILTERED_USERS = "GET_FILTERED_USERS";
export const GET_STATS = "GET_STATS";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const UPDATE_USER_BY_ID = "UPDATE_USER_BY_ID";
export const DELETE_USER = "DELETE_USER";
export const CHECK_PURCHASE_WITH_USER_ID = "CHECK_PURCHASE_WITH_USER_ID";
export const CREATE_A_PAID_RECEIPT = "CREATE_A_PAID_RECEIPT";
export const CANCEL_A_PAID_RECEIPT = "CANCEL_A_PAID_RECEIPT";
export const SET_SELECTED_STUDENTS_FOR_MULTIPLE_PAYMENT =
  "SET_SELECTED_STUDENTS_FOR_MULTIPLE_PAYMENT";
export const DISCLAIM = "DISCLAIM";
