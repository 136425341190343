import React from "react";

import classNames from "./documents.module.css";
import Typography from "../../components/ui/typography";

const Documents = () => {
  return (
    <div className={classNames.steps_container}>
      <Typography font="merel" size="normal" weight="bold" color="orange">
        Tüm Belgelerim
      </Typography>
      <div className={"card " + classNames.steps}>
        <div className={classNames.steps_list}>
          <Typography font="merel" size="normal" color="orange">
            Belge Adı
          </Typography>
          <Typography font="merel" size="normal" color="orange">
            İşlem
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Documents;
