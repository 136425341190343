import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
// import store, { history } from './store';
import { history } from "./store";

import "./config/fontAwesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "./common/styles.css";

import { PersistGate } from "redux-persist/integration/react";
import storeAndPersistorReturner from "./store";

const { store, persistor } = storeAndPersistorReturner();

const app = (
  <Provider store={store}>
    <PersistGate loading={<div>Yükleniyor..</div>} persistor={persistor}>
      <ConnectedRouter history={history}>
        <div>
          <App />
        </div>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
