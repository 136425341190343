import React from "react";
import classNames from "../manage.module.css";
import { personalExamLink, teamExamLink } from "./generateLinks";
import Button from "../../../components/ui/button";

export const paidUserInfoBox = ({ user }) => {
  return (
    <div
      style={{ color: "white", fontSize: "1.3rem" }}
      className={"card " + classNames.left_step}
    >
      {/* {user.brain_quire_coupon_code && (
        <p>
          1 yıllık GriCeviz bireysel üyelik için kupon kodunuz:{" "}
          <span
            style={{ color: "chocolate", fontSize: "1.5rem", fontWeight: 700 }}
          >
            {user.brain_quire_coupon_code.coupon_code}
          </span>
        </p>
      )} */}

      {/* {user.brain_quire_coupon_code && (
        <div>
          {" "}
          <a
            href="http://www.file.tzv.org.tr/sampiyona/griceviz_bireyseluyeligi.pdf"
            download
            target="_blank"
            style={{ color: "chocolate", fontWeight: 700 }}
          >
            (Kupon kullanım kılavuzunu indirmek için tıklayınız.)
          </a>
          <p
     style={{
       marginTop: 10,
       color: "#d5d3d3",
       fontFamily: "system-ui",
       fontSize: 14,
     }}
   >
     Kupon kodunuz 2 ay boyunca Şampiyona sayfanızda görünür olacaktır.
     İleri bir tarihte kullanmak istiyorsanız lütfen size ait kodu not
     alınız. Kuponların son kullanım tarihi 31 Mayıs 2023'tür. Bu
     tarihe kadar aktifleştirilmeyen kuponlar kullanım dışı kalacaktır.
   </p>
        </div>
      )} */}

      {/* {!user.brain_quire_coupon_code && (
        <p className="white">
          GriCeviz uygulamasına ait kupon kodu bulunamadı.{" "}
          <span style={{ color: "chocolate" }}>
            (24 saat içinde kupon kodunuz ekranda görünmezse lütfen{" "}
            <a href="mailto:sampiyona@tzv.org.tr">sampiyona@tzv.org.tr</a>{" "}
            adresine e-posta ile bilgi veriniz.)
          </span>
        </p>
      )} */}

      {user.class_id && (
        <div className={classNames.old_exams}>
          {user.class_id >= 3 && (
            <h3 style={{ marginBottom: 20, fontSize: 22 }}>
              Geçen Yıla Ait Sorular
            </h3>
          )}
          {/* {user.class_id < 3 && (
            <h3 style={{ marginBottom: 20, fontSize: 22 }}>
              Geçen Yıla Ait Sorular <hr width="100%" color="white" />
             <span style={{ color: "chocolate", fontSize: 16 }}>
                (Not: 1. ve 2. Sınıflar bu yıl ilk kez şampiyonaya katılacağı
                için geçmiş sorular bulunmamaktadır. Örnek olması amacıyla 3.
                Sınıf soruları verilmektedir.)
              </span> 
            </h3>
          )} */}
          {/* {generateIlFinaliLink(user, "2021 İl Finalleri Sınavı")} */}
          {personalExamLink({
            usr: user,
            buttonName: "2023-24 İl Finalleri Sınavı",
            season: "if_2324",
          })}
          {teamExamLink({
            usr: { ...user, school_id: user.school_id + 99999 }, // school_id'yi değiştirmezsek bu kullanıcı bu sınıf-okul'dan geçen sene sınava girmiş üst devrenin sınav ekranını ve responselarını görüyor.
            season: "bf_2324",
            buttonName: "2023-24 Bölge Finalleri Sınavı",
          })}
          {/* {user.class_id < 3 && (
            <a
              href={
                "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Ftr_finali_sorulari%2F1-2.s%C4%B1n%C4%B1f.pdf?alt=media&token=a886319b-f3d7-4ecf-81cc-16eee433751d"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ fontSize: 20, marginRight: 20, padding: 5 }}>
                {"2022-23 Türkiye Finalleri Sınavı"}
              </Button>
            </a>
          )} */}
          {user.class_id > 0 && user.class_id < 3 && (
            <a
              href={
                "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Ftr_finali_sorulari%2F1-2.s%C4%B1n%C4%B1f.pdf?alt=media&token=de66a6d2-24a5-46f5-bb99-01c4e6916688"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ fontSize: 20, marginRight: 20, padding: 5 }}>
                {"2024 Türkiye Finalleri Sınavı"}
              </Button>
            </a>
          )}
          {user.class_id > 2 && user.class_id < 5 && (
            <a
              href={
                "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Ftr_finali_sorulari%2F3-4.s%C4%B1n%C4%B1f.pdf?alt=media&token=340f94ba-727f-405f-a0ae-31bdfbde9544"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ fontSize: 20, marginRight: 20, padding: 5 }}>
                {"2024 Türkiye Finalleri Sınavı"}
              </Button>
            </a>
          )}
          {user.class_id > 4 && user.class_id < 9 && (
            <a
              href={
                "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Ftr_finali_sorulari%2F5-6-7-8.s%C4%B1n%C4%B1f.pdf?alt=media&token=7be162d7-c57f-4f2d-b392-e496ae694ff6"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ fontSize: 20, marginRight: 20, padding: 5 }}>
                {"2024 Türkiye Finalleri Sınavı"}
              </Button>
            </a>
          )}
          {user.class_id > 8 && (
            <a
              href={
                "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_23_24%2Ftr_finali_sorulari%2F9-10-11-12.s%C4%B1n%C4%B1f.pdf?alt=media&token=64dad60e-50d8-4fa4-b88a-5c40a47031e0"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ fontSize: 20, marginRight: 20, padding: 5 }}>
                {"2024 Türkiye Finalleri Sınavı"}
              </Button>
            </a>
          )}
        </div>
      )}
    </div>
  );
};
