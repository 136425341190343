import React from "react";
import classNames from "./typography.module.css";
const Typography = ({
  color = "black",
  children,
  size = "normal",
  className,
  font,
  weight,
  ...props
}) => {
  return (
    <div
      className={[
        classNames.title,
        color,
        size,
        font,
        weight && "fw_" + weight,
        className,
      ].join(" ")}
      {...props}
    >
      {" "}
      {children}
    </div>
  );
};

export default Typography;
