import React from "react";
import classNames from "./council.module.css";
import Typography from "../../components/ui/typography";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";

const chair = {
  name: "Emrehan Halıcı",
  title: "Kurul Başkanı / Soruların Hazırlayıcısı",
  degree: "Türkiye Zeka Vakfı Başkanı",
  image: "/assets/images/council/1.png",
};

const members = [
  {
    name: "Aziz Ateş",
    title: "Kurul Üyesi / İtiraz Sorumlusu",
    degree:
      "Matematikçi, Oyun 2008 ve Oyun 2012 Birincisi, Oyun 2004 İkincisi, Oyun 2009 Dördüncüsü",
    image: "/assets/images/council/1.png",
  },
  // {
  //   name: "Doç. Dr. Özgür Kişisel",
  //   title: "Kurul Üyesi",
  //   degree: "Akademisyen (Matematik Bölümü, ODTÜ)",
  //   image: "/assets/images/council/1.png"
  // },
  {
    name: "Doç. Dr. Fatih Sulak",
    title: "Kurul Üyesi",
    degree:
      "Akademisyen (Matematik Bölümü, Atılım Üniversitesi), Türkiye Go Şampiyonu (6 defa), Oyun 1999 İkincisi, Oyun 1997 Üçüncüsü",
    image: "/assets/images/council/1.png",
  },
];
const MemberCard = ({ name, title, degree, image }) => (
  <div className={classNames.member_card}>
    {/*<img src={image} alt={name} />*/}
    <Typography font="tadao" weight="normal" color="white" size="smallest">
      {title}
    </Typography>
    <Typography font="merel" weight="bold" color="orange" size="small">
      {name}
    </Typography>
    <Typography font="tadao" weight="normal" color="grey" size="smallest">
      {degree}
    </Typography>
  </div>
);

const Council = () => (
  <div className={"card " + classNames.council}>
    <MemberCard {...chair} />
    <div className={classNames.members}>
      {members.map((member, key) => (
        <div key={key} className={classNames.member_container}>
          <MemberCard {...member} />
        </div>
      ))}
    </div>
    <ScrollToTop />
  </div>
);

export default Council;
