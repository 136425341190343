import React from "react";
import classNames from "./style.module.css";
import Typography from "../../../../components/ui/typography";
import Button from "../../../../components/ui/button";
import { Link } from "react-router-dom";

const BottomSignUp = () => (
  <div className={classNames.bottomSignUp}>
    <img
      src="/assets/images/championship.png"
      alt="championship"
      className={classNames.logo}
    />
    <Typography
      color="white"
      size="big"
      font="merel"
      style={{ paddingBottom: 30, paddingTop: 30 }}
    >
      Şampiyonaya Kaydol
    </Typography>
    <Link to="/kayit">
      <Button size="large">Kaydol</Button>
    </Link>
  </div>
);

export default BottomSignUp;
