import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import MaterialBackDrop from "../../components/ui/loading/materialBackDrop";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CheckCircleIcon from "@material-ui/icons/Done";

import { Redirect } from "react-router-dom";

// import { answerKey, competitorList } from "../../common/ilFinaliData_11";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";

const Results = () => {
  const user = useSelector((state) => state.auth && state.auth.user);
  if (!user) return <Redirect to="/" />;

  const answerNotFoundWarning = (
    <div
      style={{
        color: "#ff9e9e",
        fontSize: "1.4rem",
        maxWidth: "500px",
        textAlign: "center",
      }}
    >
      {"Sayın " + user.name + ";"}
      <p style={{ textDecoration: "underline" }}>
        Sisteme kayıtlı cevabınız yoktur.
      </p>
      <p>
        28 Kasım 2020 tarihinde yapılan İl Finalleri Sınavına katıldıysanız
        lütfen itiraz süresi içinde info@tzv.org.tr adresine e-posta ile bilgi
        veriniz.
      </p>
    </div>
  );
  const updates = (
    <div
      style={{
        backgroundColor: "cornsilk",
        padding: 15,
        borderRadius: 15,
        marginBottom: 20,
        //fontSize: "1.2rem",
      }}
    >
      <span style={{ color: "orange", fontWeight: "bold" }}>Güncelleme:</span>
      <p>
        <span style={{ color: "orange", marginRight: 5, fontWeight: "bold" }}>
          1-
        </span>
        “10. Şifreler” sorusu, çizim sırasındaki yapılan bir hata sebebiyle
        iptal edilmiştir. Değerlendirme diğer sorular üzerinden yapılacaktır.
      </p>
      <span style={{ color: "orange", marginRight: 5, fontWeight: "bold" }}>
        2-
      </span>
      “Ek Soru - Küp Bloklar 9” sorusunun cevabı “B) 14” şıkkı olarak
      güncellenmiştir.
    </div>
  );

  const generateUserResult = () => {
    if (false) return answerNotFoundWarning;

    let userClass = user.applied_products[0].id - 7;

    // 3 kişi üst sınıfın sınavına girmiş. Sistemdeki sınıfları ile çözdükleri sınav farklı. Bunlara özel case yazarak doğru cevabı gösteriyorum.
    if (user.id === 21514 || user.id === 19239) userClass = 5;
    else if (user.id === 8219) userClass = 7;

    const keys = []; //answerKey[userClass];
    const answers = []; //competitorList[user.id].a;
    const manuallyCalculatedScore = 0; //competitorList[user.id].s;
    let extraScoreTotal = 0;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TableContainer
          component={Paper}
          style={{ maxWidth: 550, padding: 15 }}
        >
          <Table size="small" padding="none" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {[
                  { name: "SORU", align: "center" },
                  { name: "SORU PUANI", align: "center" },
                  { name: "DOĞRU CEVAP", align: "center" },
                  { name: "CEVABINIZ", align: "center" },
                  { name: "PUANINIZ", align: "center" },
                  { name: "", align: "center" },
                ].map((row) => (
                  <TableCell
                    key={row.name + "question_column_name"}
                    style={{
                      fontWeight: "bold",
                      border: row.name ? "solid black 1px" : "",
                      padding: "4px 6px 4px 6px",
                    }}
                    align={row.align}
                  >
                    {row.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {keys.map((key_row, index) => {
                const questionName = key_row.questionName;
                const correctAnswer = key_row.correctAnswer;
                const questionScore = key_row.score;
                const userAnswer =
                  answers[index] === "NULL" ? "" : answers[index];

                const isCorrect = userAnswer === correctAnswer;
                const userScore = isCorrect ? questionScore : 0;

                extraScoreTotal += index > 9 ? userScore : 0;

                return (
                  <TableRow
                    key={index + "_answer_key"}
                    style={{ borderTop: index === 10 ? "solid red" : "" }}
                  >
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      {questionName}
                    </TableCell>
                    <TableCell align="center">{questionScore}</TableCell>
                    <TableCell align="center">{correctAnswer}</TableCell>
                    <TableCell align="center">{userAnswer}</TableCell>
                    {isCorrect && (
                      <TableCell
                        align="center"
                        style={{ borderRadius: 5, backgroundColor: "#94ee94" }}
                      >
                        {userScore}
                      </TableCell>
                    )}
                    {!isCorrect && (
                      <TableCell align="center">{userScore}</TableCell>
                    )}

                    <TableCell align="center">
                      {isCorrect ? (
                        <CheckCircleIcon
                          style={{ color: "black", height: "1rem" }}
                        />
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}

              <TableRow
                key={"total_row"}
                style={{ backgroundColor: "#dddacf" }}
              >
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  TOPLAM PUAN
                </TableCell>

                <TableCell
                  align="center"
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {manuallyCalculatedScore}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow
                key={"total_row"}
                style={{ backgroundColor: "#dddacf" }}
              >
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  EK SORU PUANI
                </TableCell>

                <TableCell
                  align="center"
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {extraScoreTotal}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
          {explanationText}
          {updates}
        </TableContainer>
      </div>
    );
  };

  const generateSchoolResultList = () => {
    return null;
  };

  const explanationText = (
    <div style={{ fontStyle: "italic", color: "grey", marginTop: 10 }}>
      Ek soru puanı sadece eşitlik bozmada kullanılacaktır. Tam olarak aynı
      puana sahip iki yarışmacıdan ek soru puan toplamı yüksek olan yarışmacı
      sıralamada üstte olacaktır.
    </div>
  );

  return (
    <div
      style={{
        marginTop: "140px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {user && user.role === 0 && generateUserResult()}
      {user && user.role === 1 && generateSchoolResultList()}

      <ScrollToTop />
    </div>
  );
};

export default Results;
