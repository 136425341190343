import React from "react";
import classNames from "./style.module.css";
import Typography from "../../../../components/ui/typography";
import Button from "../../../../components/ui/button";
const About = () => (
  <div className={classNames.about}>
    <Typography color="orange" size="bigger">
      Hakkında
    </Typography>
    {/* <Typography color="white" className={classNames.description}>
    2022-2023 Türkiye Okullar Arası Zeka Oyunları Şampiyonası için örnek sorular kitapçığını aşağıdaki bağlantıdan indirebilirsiniz.
    </Typography>
    <div className={classNames.downloads}>
      <a target="_blank"  href={"https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_21_22%2Fkitapcik2021.pdf?alt=media&token=22165407-cd35-4bc5-8c52-1a1778dc1098"} download>
        <Button color="white" background="orange">
          Şampiyona Kitapçığını İndir
        </Button>
      </a>
    </div> */}
    {/* <div style={{maxWidth:810,margin:"auto"}}>
    <iframe
      width="100%"
      style={{borderRadius:25}}
      height="400"
      title="hakkimizda"
      src="https://www.youtube.com/embed/BBTiDMTlAT4"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
    </div> */}
    <div className={["card", classNames.card].join(" ")}>
      <Typography color="orange" font="merel" weight="bold" size="small">
        Amaç
      </Typography>
      <Typography
        color="white"
        font="tadao"
        weight="normal"
        size="smaller"
        className={classNames.text}
      >
        Öğrencilerin düşünme, karar verme ve problem çözme becerilerini
        geliştirmek. Yardımlaşma ve takım çalışması alışkanlıkları kazandırmak.
      </Typography>
      <Typography color="orange" font="merel" weight="bold" size="small">
        Kapsam
      </Typography>
      <Typography
        color="white"
        font="tadao"
        weight="normal"
        size="smaller"
        className={classNames.text}
      >
        Türkiye genelinde faaliyet gösteren devlet okulları ve özel okulların
        takım olarak katıldıkları zeka ve akıl oyunlarından oluşur. (Millî
        Eğitim Bakanlığına bağlı yurt dışında bulunan Türk okulları da takım
        olarak katılım sağlayabilir.)
      </Typography>
      <Typography color="orange" font="merel" weight="bold" size="small">
        Dayanak
      </Typography>
      <Typography
        color="white"
        font="tadao"
        weight="normal"
        size="smaller"
        className={classNames.text}
      >
        Millî Eğitim Bakanlığı İlköğretim ve Ortaöğretim Kurumları Sosyal
        Etkinlikler Yönetmeliği Yarışmalar Madde 11.
      </Typography>
    </div>
  </div>
);

export default About;
