import React from "react";
import classNames from "./help.module.css";
import Typography from "../../components/ui/typography";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Collapsable from "../../components/ui/collapsable/collapsable";

const sss = [
  {
    title: "Şampiyonaya kimler katılabilir?",
    body: "Türkiye genelinde faaliyet gösteren devlet okulları, özel okullar ve Bilim ve Sanat Merkezleri şampiyonaya katılım sağlayabilir. Ayrıca, Milli Eğitim Bakanlığı'na bağlı yurt dışındaki okullar da ayrı bir  bölge olarak değerlendirilecektir.",
  },
  // {
  //   title: "Şampiyona için ödeme yaptım ama ödeme sistemde görünmüyor?",
  //   body:
  //     <span>Ödemenizin tekrar kontrol edilmesi için  <a href="https://sampiyona.tzv.org.tr/payment_result" target="_self"> tıklayınız. </a></span>,
  // },

  {
    title: "Kayıt işlemleri nasıl yapılmaktadır?",
    body: "Öğrenci ve takım sorumlusu öğretmenler Şampiyona’ya bireysel olarak kayıt yaparlar. Kayıt esnasında okul ve sınıf bilgisini seçilir.",
  },
  {
    title:
      "Kayıt esnasında bilgilerimi hatalı girdiğimi farkettim. Nasıl düzeltebilirim?",
    body: "Okul, sınıf, ad, soyad vb. bilgilerinizi Şampiyona profil sayfanızdaki bilgi güncelleme ekranından düzeltebilirsiniz. Profil güncelleme ekranı Takım Belirleme Sınavı başlayana kadar kullanılabilir olacaktır. Hatalı okul/sınıf seçimi, yanlış kategoride soru görmenize ya da takımların hatalı sıralanmasına sebep olabileceği için Takım Belirleme Sınavı başladıktan sonra sınav sonucunuza etki edebilecek değişiklik talepleri diskalifiye edilmenizle sonuçlanabilir. Takım Belirleme Sınavından sonra hatalı bilgi tespit ederseniz sampiyona.tzv.org.tr adresinden bize ulaşınız.",
  },
  {
    title: "Katılım ücreti ne kadar?",
    body: "2024 yılı için katılım ücreti her öğrenci için 250 TL, 2025 yılı için ise 300 TL olarak belirlenmiştir.  Öğretmenler için katılım ücretsizdir. Kayıt işlemi tamamlandıktan sonra,ödeme ekranı açılacaktır. Ödemeler, kayıt işlemlerinden sonra  yapılabilir. Diğer aşamalarda yer alan sınavlar için ek bir ücret talep edilmeyecektir.",
  },
  {
    title: "Okul adına fatura kestirebilmek için ne yapmalıyım?",
    body: "Okul adına fatura kestirebilmek için kurumsal ödeme seçilmeli ve ödeme işlemi kurumun bilgisi dahilinde gerçekleştirilmelidir. Ödeme kurumun kart bilgileri ile yapılmalıdır. Aksi takdirde kurum faturası kesilmemektedir. ",
  },
  {
    title: "Öğrenci kendisi için ödeme yapıyorsa ne yapmalıdır?",
    body: "Öğrenci, bireysel ödeme yapmalı ve kendi bilgilerini girmelidir.",
  },
  {
    title: "Okullar, şampiyonaya en fazla kaç sınıf ile katılım sağlayabilir?",
    body: "Okullar uygun gördükleri tüm sınıflarda şampiyonaya katılabilir.",
  },

  {
    title: "Takım Belirleme Sınavı sonunda nasıl puanlama yapılır?",
    body: "Takım Belirleme Sınavı'na tüm okullardan dileyen her öğrenci katılabilir ve katılımda üst sınır yoktur. Sınav, öğrencilerin bireysel olarak yarıştığı ve dereceye göre takımlara ayrıldığı bir süreçtir. Öğrenciler 3'er kişilik takımlara yerleştirilir, ancak katılımcı sayısına bağlı olarak 2 ya da 1 kişilik takımlar da oluşturulabilir. Hiçbir öğrenci elenmez ve herkes bir takıma dahil olur. Aynı puanı alan yarışmacılar arasında sıralama, sınavı tamamlama sürelerine göre belirlenir.",
  },
  {
    title: "Takımlar kaç kişiden oluşmaktadır?",
    body: "Takım Belirleme Sınavı'nda, kendi okulunun ilgili sınıfında ilk üç sırayı alan öğrenciler birinci takımı, sonraki üç sırayı alan öğrenciler ikinci takımı oluşturur. Takımlar genellikle üç kişiden oluşsa da, katılımcı sayısına bağlı olarak iki veya bir kişilik takımlar da olabilir. Eşitlik durumunda, sınavı daha önce bitiren öğrenci daha yüksek sırada yer alır.",
  },
  {
    title: "İl Finalleri Sınavı sonunda nasıl puanlama yapılır?",
    body: "İl Finalleri Sınavı sonunda her okulun en iyi takımı belirlenir ve bu puan ilgili okulun o sınıftaki Okul İl Puanı olarak kabul edilir. İl genelinde derecelendirme yapılırken bu puan dikkate alınır. Takım puanlarında eşitlik olması durumunda takım üyelerinin bir önceki aşamada elde ettikleri dereceler dikkate alınarak takımlar arasında sıralama yapılır.",
  },


  {
    title: "Okullar katılım ücretini toplu olarak yatırabilir mi? ",
    body: "Evet, okullar katılım ücretini toplu olarak yatırabilir. Bu konuda detaylı bilgi almak için sampiyona@tzv.org.tr adresine e-posta gönderebilirsiniz.",
  },

  {
    title: "Takım sorumlusu öğretmenlerin Şampiyonadaki rolü nedir?",
    body: "Takım sorumlu öğretmenler, okullarından şampiyonaya kayıt olmuş öğrencilerin listesini görebilir ve sınav sonuçlarına erişim sağlayabilir. Şampiyona sürecinde öğretmenlere bilgilendirme e-postaları gönderilecektir. Öğrencilerin organizasyonu, hatalı bilgilerin düzeltilmesi ve takım üyeleri arasında koordinasyon sağlanması gibi görevleri Sorumlu Öğretmen üstlenir. Bir okulun, bir sınıf için en fazla bir takım sorumlu öğretmeni olabilir, ancak bir öğretmen birden fazla sınıfın takım sorumlusu olabilir. ",
  },
  

  {
    title: "Ek Soruların cevaplanması zorunlu mudur?",
    body: "Ek soruların cevaplanması zorunlu değildir. Ancak, eğer iki yarışmacı veya takım, asıl sorulardan aynı puanı alırsa, ek sorular bu durumda dikkate alınacaktır.",
  },
  {
    title: "Sınavda yanlış cevaplar doğru cevapları götürüyor mu?",
    body: "Hayır, sınavda yanlış cevaplar doğru cevapları götürmez. Yanlış bir cevap vermek ile o soruyu boş bırakmak arasında puan açısından fark yoktur.",
  },
  {
    title: "Sınavın herhangi bir aşamasında öğrenciler yardım alabilir mi?",
    body: " Takım Belirleme Sınavı bireysel olarak yapılırken, diğer aşamalarda (İl Finalleri, Bölge Finalleri ve Türkiye Finallerinde) öğrenciler takım halinde çalışacaktır. Her takım üyesi, diğerlerinin yanıtlarını görebilir ve değişiklik yapabilir. Yarışmacılar hesap makinesi gibi yardımcı cihazlar kullanamaz ve öğretmenler sınav sırasında öğrencilere yardım edemez.",
  },
  {
    title: "Sınavlara ait sonuçlar ne zaman / nerede yayınlanacak?",
    body: "Tüm sınav sonuçları, şampiyona internet sayfasında belirtilen tarihlerde ilan edilecektir. Katılımcıların puanları ve sıralamaları herkesin erişimine açık olacaktır. Ayrıca, itiraz süreci içinde yapılan itirazlar değerlendirmeye alınacaktır.",
  },
  {
    title:
      "Bölge Finalleri Sınavına katılmaya hak kazanan okullar nasıl belirlenecek?",
    body: "İl Finalleri Sınavı sonunda her ilde sınıf bazında ilk 2 sırayı alan takımlar Bölge Finalleri Sınavına katılmaya hak kazanırlar. Bu takımlara ek olarak sınıf bazında sıralamalar dikkate alınır ve sınava katılacak takım sayıları her bölgede her sınıf için 50'ye tamamlanır. Bölge Finalleri Sınavına her okul her sınıfta en fazla bir takımla (sıralamada en üstte bulunan) katılabilir.",
  },
  {
    title:
      "Türkiye Finalleri Sınavına katılmaya hak kazanan okullar nasıl belirlenecek?",
    body: "Bölge Finalleri Sınavı sonunda, ülkemizin 7 coğrafi bölgesi dikkate alınarak her bölgede sınıf bazında ilk 3 sırayı alan 21 takım Türkiye Finalleri Sınavına katılmaya hak kazanırlar. Bu takımlara ek olarak sınıf bazında sıralamalar dikkate alınır ve Türkiye Finalleri Sınavına katılacak takım sayıları her sınıf için 50'ye tamamlanır. 0 puan alan takımlar, ilk 3 içinde olsalar bile Türkiye Finalleri’ne katılamaz. Yurtdışında faaliyet gösteren okullar, ayrı bir bölge olarak değerlendirilecektir.",
  },
  {
    title: "Şampiyonanın Türkiye Finalleri Sınavı nerede olacak?",
    body: "Şampiyonanın Türkiye Finalleri Sınavı Ankara'da yazılı olarak yapılır. Sınava ait salon bilgisi ilerleyen günlerde Şampiyona internet sayfası üzerinden ilan edilecektir. (Pandemi koşulları gibi aksi bir durum söz konusu olması halinde TZV tarafından farklı bir karar alınabilir.)",
  },
  {
    title: "Şampiyona sonunda takımlar nasıl ödüllendirilecek?",
    body: (
      <ul className={classNames.list}>
        <li>
          Her sınıfta ilk üç dereceye giren takımlara kupa, takım üyesi
          öğrencilerine ve sorumlu öğretmenlerine madalya verilir. Ayrıca bu
          okullara çeşitli kitap ödülleri de verilecektir.
        </li>
        <li>
          Her sınıfta ilk üç dereceye giren takımların öğrencileri Şampiyona
          sonunda internet üzerinden yapılacak olan Türkiye Zeka Vakfı “Düşünce
          Şenliği”ne katılım hakkı kazanır.
        </li>
        {/* <li>
          Türkiye Finalleri Sınavı’nda yarışan tüm öğrenci ve öğretmenlere
          GriCeviz Bilişsel Beceri ve Zihinsel Gelişim Platformu için 1 yıllık
          aile üyeliği ödül olarak verilir.
        </li> */}

        <li>
          Ayrıca;
          <ul>
            <li>
              İl Finalleri Sınavı’nda Türkiye geneli sıralamada ilk %30’luk
              dilime giren tüm yarışmacılara elektronik İl Finalleri Başarı
              Belgesi, diğer katılımcılara elektronik İl Finalleri Katılım
              Belgesi verilir,
            </li>
            <li>
              Bölge Finalleri Sınavı’nda sonraki aşamaya katılım hakkı kazanan
              tüm takım üyelerine elektronik Bölge Finalleri Başarı Belgesi,
              diğer katılımcılara elektronik Bölge Finalleri Katılım Belgesi
              verilir,
            </li>
            <li>
              Türkiye Finalleri Sınavı’nda dereceye giren tüm takım üyelerine
              elektronik Türkiye Finalleri Başarı Belgesi, diğer katılımcılara
              elektronik Türkiye Finalleri Katılım Belgesi verilir.
            </li>
          </ul>
        </li>

        <li>
          Millî Eğitim Bakanlığına bağlı olarak yurt dışında faaliyet gösteren
          okullardan Şampiyona'ya katılım olması halinde ilgili ülke ayrı bir
          bölge olarak değerlendirilecektir. Türkiye Finalleri Sınavı sonucuna
          göre söz konusu okulların derecelendirilmesi ve ödüllendirilmesi
          konusunda TZV tarafından ayrıca karar alınacaktır.
        </li>
      </ul>
    ),
  },
 
];

const Help = () => (
  <div className={"card " + classNames.help}>
    <Typography font="merel" size="normal" weight="bold" color="orange">
      Yardım
    </Typography>
    <Typography
      font="tadao"
      weight="normal"
      color="white"
      size="smaller"
      style={{ paddingBottom: 30 }}
    >
      Lütfen iletişime geçmeden önce{" "}
      <span className="orange">sık sorulan soruları </span>inceleyiniz.
    </Typography>
    {sss.map(({ body, title }, key) => (
      <Collapsable key={key} title={title} body={body} />
    ))}
    <ScrollToTop />
  </div>
);

export default Help;
