import React, { useState } from "react";
// import { animateScroll } from "react-scroll";
import classNames from "./steps.module.css";
import Collapsable from "../../components/ui/collapsable/collapsable";
import Typography from "../../components/ui/typography";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";

const regions = [
  {
    name: "Akdeniz Bölgesi",
    cities: [
      "Antalya",
      "Burdur",
      "Isparta",
      "Mersin",
      "Adana",
      "Hatay",
      "Osmaniye",
      "Kahramanmaraş",
    ],
    testCenter: "-",
  },
  {
    name: "Ege Bölgesi",
    cities: [
      "İzmir",
      "Aydın",
      "Muğla",
      "Manisa",
      "Denizli",
      "Uşak",
      "Kütahya",
      "Afyon",
    ],
    testCenter: "-",
  },
  {
    name: "Marmara Bölgesi",
    cities: [
      "Çanakkale",
      "Balıkesir",
      "Edirne",
      "Tekirdağ",
      "Kırklareli",
      "İstanbul",
      "Bursa",
      "Yalova",
      "Kocaeli",
      "Bilecik",
      "Sakarya",
    ],
    testCenter: "-",
  },
  {
    name: "Karadeniz Bölgesi",
    cities: [
      "Bolu",
      "Düzce",
      "Zonguldak",
      "Karabük",
      "Bartın",
      "Kastamonu",
      "Çorum",
      "Sinop",
      "Samsun",
      "Amasya",
      "Tokat",
      "Ordu",
      "Giresun",
      "Gümüşhane",
      "Trabzon",
      "Bayburt",
      "Rize",
      "Artvin",
    ],
    testCenter: "-",
  },
  {
    name: "İç Anadolu Bölgesi",
    cities: [
      "Eskişehir",
      "Konya",
      "Ankara",
      "Çankırı",
      "Aksaray",
      "Kırıkkale",
      "Kırşehir",
      "Yozgat",
      "Niğde",
      "Nevşehir",
      "Kayseri",
      "Karaman",
      "Sivas",
    ],
    testCenter: "-",
  },
  {
    name: "Doğu Anadolu Bölgesi",
    cities: [
      "Malatya",
      "Erzincan",
      "Elâzığ",
      "Tunceli",
      "Bingöl",
      "Erzurum",
      "Muş",
      "Bitlis",
      "Kars",
      "Ağrı",
      "Ardahan",
      "Van",
      "Iğdır",
      "Hakkari",
    ],
    testCenter: "-",
  },
  {
    name: "Güneydoğu Anadolu Bölgesi",
    cities: [
      "Gaziantep",
      "Kilis",
      "Adıyaman",
      "Şanlıurfa",
      "Diyarbakır",
      "Mardin",
      "Batman",
      "Siirt",
      "Şırnak",
    ],
    testCenter: "-",
  },
];

const StepTitle = ({ left, right }) => (
  <Typography
    style={{ color: "#10273C" }}
    font="merel"
    weight="bold"
    size="small"
  >
    {left}
    {right && <span style={{ marginLeft: 3 }}>:</span>}
    <span className="orange"> {right}</span>
  </Typography>
);

const about = (
  <div className={classNames.list}>
    <b>YARIŞMANIN ADI</b>
    <p>2024-2025 Türkiye Okullar Arası Zeka Oyunları Şampiyonası.</p>
    <b>AMAÇ</b>
    <p>
      Öğrencilerin düşünme, karar verme ve problem çözme becerilerini
      geliştirmek. Yardımlaşma ve takım çalışması alışkanlıkları kazandırmak.
    </p>
    <b>KAPSAM</b>
    <p>
      Türkiye genelinde faaliyet gösteren devlet okulları ve özel okulların
      takım olarak katıldıkları zeka ve akıl oyunlarından oluşur. (Millî Eğitim
      Bakanlığına bağlı yurt dışında bulunan Türk okulları da takım olarak
      katılım sağlayabilir.)
    </p>
    <b>DAYANAK</b>
    <p>
      Millî Eğitim Bakanlığı İlköğretim ve Ortaöğretim Kurumları Sosyal
      Etkinlikler Yönetmeliği Yarışmalar Madde 11.
    </p>
  </div>
);

const conditions = (
  <ol className={classNames.list}>
    <li>
      Şampiyona “İlkokul <span className="grey">(1-2-3-4. sınıflar)</span>”,
      “Ortaokul <span className="grey">(5-6-7-8. sınıflar)</span>” ve “Lise{" "}
      <span className="grey">(9-10-11-12. sınıflar)</span>” olmak üzere on iki
      sınıf üzerinden yapılır.
    </li>

    <li>
      Şampiyona “Takım Belirleme Sınavı”, “İl Finalleri Sınavı”, “Bölge
      Finalleri Sınavı” ve “Türkiye Finalleri Sınavı” olmak üzere dört aşamadan
      oluşur. Takım Belirleme, İl Finalleri ve Bölge Finalleri Sınav’ları
      Şampiyona internet sayfası üzerinden çevrim içi olarak yapılır. Türkiye
      Finalleri Sınavı yazılı olarak Ankara’da yapılır. Sınavların yeri ve
      zamanına ait güncel bilgiler ve hatırlatmalar Şampiyona takvim sayfasından
      ve Whatsapp kanalından takip edilebilir.
    </li>

    <li>
      Dileyen her öğrenci bireysel olarak Şampiyona’ya kayıt olabilir ve
      bireysel olarak Takım Belirleme Sınavı’na katılabilir. Bu sınav sonucunda
      belirlenecek olan takım üyeleri ile birilkte İl Finalleri Sınavı’nda takım
      halinde yarışabilir.
    </li>
    <li>
      Öğrenci katılım ücreti 2024 yılı boyunca 250 TL, 2025 yılı için 300
      TL’dir.
    </li>
    {/* <li>
      Yarışmacıların Şampiyona’ya daha iyi hazırlanabilmesi için katılım
      ücretini ödeyen herkese geçen yıl Şampiyona’da sorulan tüm soru ve
      cevaplara erişim hakkı verilecektir. Ayrıca özellikle sayısal, sözel ve
      mantık kategorilerinde Şampiyona soruları ile benzer zihinsel gelişim
      egzersizleri içeren GriCeviz Bilişsel Beceri ve Zihinsel Gelişim
      platformunda kullanılmak üzere 1 yıllık premium üyelik kodu verilecektir.
    </li> */}
    <li>
      Her okul, Şampiyona’da yarışan her sınıf için bir sınıf sorumlusu öğretmen
      belirler. Öğretmenler kayıt esnasında sorumlu olmak istedikleri sınıfları
      seçebilir. Bir öğretmen birden fazla sınıfın sorumlusu olabilir; fakat bir
      sınıfın yalnızca bir sorumlu öğretmeni olabilir.
    </li>
    <li>
      Öğrenciler ve sınıf sorumlusu öğretmenler bireysel olarak kayıtlarını
      https://sampiyona.tzv.org.tr adresinden yaparlar.
    </li>
    <li>
      Yanlış bilgi -hangi aşamada olursa olsun- diskalifiye nedenidir. Kayıt
      esnasında hatalı girilen bilgi varsa ilk sınavdan önce mutlaka
      güncellenmelidir.
    </li>
    <li>
      Sınıf sorumlusu öğretmenler kendi sınıflarından Şampiyona’ya kayıt olan
      öğrencilerin listesini ve sınav sonuçlarını görebilir, öğrencilere ait pdf
      belgeleri indirebilir, öğrenci listelerinde uygunsuzluk tespit etmeleri
      durumunda değişiklik taleplerini bildirebilirler.
    </li>
    <li>
      Öğrenciler evlerinden ya da okulun sağladığı internet erişimi olan bir
      mekândan çevrim içi sınavlara katılabilirler. (Sınav süresi dolduğunda
      cevap formu kapatılacak ve daha sonra cevap göndermek mümkün
      olmayacaktır). Yarışmacılar internet erişimine uygun bir ortamın
      sağlanmasından sorumludur. Herhangi bir nedenle doğabilecek teknik problem
      nedeniyle sınav sorularına ulaşılamaması, bağlantının kesilmesi ya da
      cevapların belirlenen süre içinde yollanamaması gibi nedenlerden dolayı
      TZV farklı bir işlem yapamaz.
    </li>
    <li>
      Öğretmenler, veliler ve diğer öğrenciler yarışmacılara yardım edemezler.
    </li>
    <li>
      Şampiyona’ya katılmak isteyen engelli bireylerin yanlarında iletişime
      katkı sağlayacak bir yardımcı bulunabilir.
    </li>
    <li>
      Şampiyona sınavlarının hazırlanması ve değerlendirilmesi, Türkiye Zeka
      Vakfı Soru Kurulu tarafından, Türkiye Zeka Vakfı Yarışma Standartlarına
      göre yapılır. Sınavlar 10 soru ve eşitlik bozmada kullanılacak ek
      sorulardan oluşur. Her soru 10 puandır. Ayrıca soruların sınıf bazında
      çözülme yüzdelerine göre (0 ile 10 arasında) ek puan verilir. Bir sorunun
      doğru çözülme yüzdesi D ise o sorudan alınacak puan = 10 + 10x(1-D/100)
      olarak hesaplanır.
    </li>
    <li>
      Sıralama yapılırken öncelikli olarak asıl sorulardan alınan puanlar
      dikkate alınır. Asıl sorulardan eşit puan alan yarışmacılar/takımlar kendi
      aralarında sıralanırken ek sorulardan aldıkları puanlar dikkate alınır.
    </li>
    <li>
      Yanlış cevapların puana etkisi yoktur. (Bir soruya hatalı cevap vermek ile
      o soruyu boş bırakmak arasında fark olmayacaktır.)
    </li>
    <li>
      Sınavda herhangi bir nedenle iptal edilen soru olması durumunda diğer
      sorular dikkate alınarak değerlendirme yapılır.
    </li>
    <li>
      Cevap Formuna eksik, hatalı girilen, soru metninde ya da örnek sorularda
      belirtildiği biçimde girilmeyen cevaplar yanlış olarak kabul edilir. Bu
      nedenle cevapların Cevap Formuna hatasız ve soruda belirtilen formatta
      girilmesi çok önemlidir.
    </li>
    <li>
      Sınavlara ait ilk sonuçlar, itiraz süreci, itiraz sonrası güncellenen
      sonuçlar, takvimde yapılacak değişiklikler ve Şampiyona’yla ilgili tüm
      gelişmeler Şampiyona internet sayfasında ve Şampiyona Whatsapp kanalında
      duyurulur. Whatsapp kanalına katılmayan, yarışma sürecinde kanaldan
      ayrılan ya da kanal bildirimlerinin kapalı olması sebebiyle duyurulardan
      haberdar olmayan yarışmacıların bu konudaki itirazları dikkate
      alınmayacaktır.
    </li>
    <li>
      Şampiyona’ya katılan 18 yaşından küçük katılımcılardan kayıt esnasında
      velisinin izni alınır.
    </li>
    <li>
      Türkiye Zeka Vakfı, gerekli hallerde Şampiyona koşullarında ve takviminde
      değişiklik yapabilir. Yarışmacıların/takımların diskalifiye olması,
      yarışmacıların sınav sürecinde başka okullara nakil olması vb. istisnai
      durumlarda derecelendirme listelerinde değişiklik yapılabilir.
    </li>
    <li>
      6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında Şampiyona’ya
      kayıt sağlayanlardan kategorilerini belli etmek amacıyla açık rıza
      onayıyla alınan bilgiler, üçüncü şahıslarla hiçbir ad altında
      paylaşılmayacaktır.
    </li>
    <li>
      Şampiyonada yazılı olarak yapılan sınavlarda ulaşım ve konaklama giderleri
      katılımcılara aittir.
    </li>

    <li>
      Şampiyona’ya katılan okullar, öğretmenler ve öğrenciler bu koşulları kabul
      etmiş sayılırlar.
    </li>
  </ol>
);

const Step1Body = (
  <div>
    <ul className={classNames.list}>
      <li>
        Takım Belirleme Sınavı’na tüm okullardan dileyen her öğrenci
        katılabilir. Katılacak öğrenci sayısı için bir üst sınır yoktur. Bu
        sınavda herkes bireysel olarak yarışır.
      </li>
      <li>
        Takım Belirleme Sınavı'na katılmayan yarışmacılar, bir sonraki aşama
        olan İl Finalleri Sınavı'na katılamazlar.
      </li>
      <li>
        Bu sınavda amaç her okul/sınıf için yarışmaya kayıtlı öğrencilerin takım
        arkadaşlarını belirlemektir.
      </li>
      <li>
        Sınav sonunda katılımcılar derecesine göre sıralanır ve 3’er kişiden
        oluşan takımlara ayrılır. Kayıtlı yarışmacı sayısına göre takımlar 2 ya
        da 1 öğrenciden de oluşabilir. (Örneğin bir okulun bir sınıfından 4
        öğrenci yarışmaya kayıt olmuşsa, Takım Belirleme Sınavı sonrası derecesi
        en yüksek olan 3 öğrenci 1. takımı, son öğrenci ise tek başına 2. takımı
        oluşturacaktır.)
      </li>
      <li>
        Takım Belirleme Sınavı’nda hiçbir öğrenci elenmez, kayıtlı tüm
        öğrenciler mutlaka bir takıma dahil olarak sonraki aşamaya katılmaya hak
        kazanır.
      </li>
      Takım üyeleri sınav derecelerine göre belirlenecek ve zorunlu durumlar
      haricinde takımlar arasında öğrenci değişimi yapılmayacaktır. Sınavda tam
      olarak aynı puanı alan yarışmacılar kendi aralarında sıralanırken sınavı
      bitirme süresi dikkate alınacak ve sınavı erken tamamlayan yarışmacı üst
      sırada yer alacaktır. (Sınavı tamamlama kriteri olarak en son cevap kaydı
      yapılan zaman dikkate alınır.)
    </ul>
  </div>
);
const Step2Body = (
  <ul className={classNames.list}>
    <li>
      Takım Belirleme Sınavı’nda belirlenen tüm takımlar istisnasız olarak İl
      Finalleri Sınavı’na katılmaya hak kazanır. Bu sınavda takım üyeleri sanal
      ortamda yardımlaşarak soruları cevaplarlar.
    </li>
    <li>
      Sınav saati geldiğinde takım üyeleri kendi hesapları ile oturum açarak
      takım adına sorulara yanıt verebilir, diğer takım arkadaşlarının verdiği
      yanıtları sistem üzerinden görebilir ya da değiştirebilir. Hangi
      yarışmacının hangi soruya cevap verdiği anlık olarak kaydedilir.
    </li>
    <li>
      İl Finalleri Sınavı’nda amaç her ilin en iyi derece yapan okullarını
      belirlemektir. Takımlar bu sınavda hem kendi illerindeki diğer okullarla,
      hem de kendi okullarındaki diğer takımlarla rekabet edecektir.
    </li>
    <li>
      İl Finalleri Sınavı sonunda her okulun en iyi takımı belirlenir ve bu puan
      ilgili okulun o sınıftaki Okul İl Puanı olarak kabul edilir. İl genelinde
      derecelendirme yapılırken bu puan dikkate alınır. Takım puanlarında
      eşitlik olması durumunda takım üyelerinin bir önceki aşamada elde
      ettikleri dereceler dikkate alınarak takımlar arasında sıralama yapılır.
    </li>
    <li>
      İl Finalleri Sınavı sonunda her ilde sınıf bazında ilk 2 sırayı alan
      takımlar Bölge Finalleri Sınavına katılmaya hak kazanırlar. Bu takımlara
      ek olarak sınıf bazında sıralamalar dikkate alınır ve sınava katılacak
      takım sayıları her bölgede her sınıf için 50'ye tamamlanır. Bölge
      Finalleri Sınavına her okul her sınıfta en fazla bir takımla (sıralamada
      en üstte bulunan) katılabilir.
    </li>
    <li>
      Okul İl Puanı (puan + ek puan) sıfır olan takımlar Bölge Finalleri
      Sınavı’na katılamazlar.
    </li>
  </ul>
);

const Step3Body = (
  <div>
    <img src="/assets/images/map.png" alt="map" className={classNames.map} />
    <table className={classNames.cities_table} border="1">
      <thead>
        <tr className="orange">
          <th>Bölge Adı</th>
          <th>İl Sayısı</th>
          <th>İl Adı</th>
        </tr>
      </thead>
      <tbody>
        {regions.map((region, key) => (
          <tr key={key}>
            <td>{region.name}</td>
            <td>{region.cities.length}</td>
            <td>
              {region.cities.reduce(
                (acc, city, index) => [
                  ...acc,
                  <span
                    key={city}
                    className={city === region.testCenter ? "orange" : ""}
                  >
                    {city}
                    {index !== region.cities.length - 1 ? ", " : ""}
                  </span>,
                ],
                []
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <ul className={classNames.list} style={{ marginTop: "20px" }}>
      <li>
        İl Finalleri Sınavı’nda olduğu gibi, Bölge Finalleri Sınavı’nda da
        öğrenciler takım halinde yarışır.
      </li>
      <li>
        Bu sınavda amaç 7 coğrafi bölge için, her bölgenin en iyi derece yapan
        okullarını belirlemektir.
      </li>
      <li>
        Bölge Finalleri Sınavında her takımın aldığı puan, o okulun o sınıftaki
        Okul Bölge Puanı’dır.
      </li>
      <li>
        Sınav sonunda takım puanlarda eşitlik olması durumunda ilgili takımların
        bir önceki aşamada elde ettiği dereceler dikkate alınarak sıralama
        yapılır.
      </li>
      <li>
        Bölge Finalleri Sınavı sonunda, ülkemizin 7 coğrafi bölgesi dikkate
        alınarak her bölgede sınıf bazında ilk 3 sırayı alan 21 takım Türkiye
        Finalleri Sınavına katılmaya hak kazanırlar. Bu takımlara ek olarak
        sınıf bazında sıralamalar dikkate alınır ve Türkiye Finalleri Sınavına
        katılacak takım sayıları her sınıf için 50'ye tamamlanır.
      </li>
      <li>
        Okul Bölge Puanı (puan + ek puan) sıfır olan takımlar Türkiye Finalleri
        Sınavı’na katılamaz.
      </li>
    </ul>
  </div>
);

const Step4Body = (
  <div>
    <ul className={classNames.list}>
      <li>
        Bölge Finalleri Sınavı’na göre belirlenecek olan her sınıftan 50’şer
        takım Ankara’da yazılı olarak yapılacak olan Türkiye Finalleri Sınavı’na
        davet edilir.
      </li>
      <li>
        Türkiye Finalleri Sınavı’nda amaç, sınıf bazında Türkiye genelinde en
        yüksek dereceyi yapan takımları belirlemektir.
      </li>
      <li>
        Sınav sonunda derecelendirme yapılırken puanlarda eşitlik olması
        durumunda ilgili takımların bir önceki sınavda elde ettiği dereceler
        dikkate alınarak sıralama yapılır.
      </li>
      <li>
        Derecelendirme sonrası her sınıfta ilk 3 sırada yer alan takımlar ödül
        törenine davet edilir.
      </li>
    </ul>
  </div>
);

const lastNotes = (
  <div>
    <ul className={classNames.list}>
      <li>
        Türkiye Finalleri Sınavı sonunda her sınıfta ilk üç dereceye giren
        takımlara kupa, takım üyesi öğrencilerine ve sorumlu öğretmenlerine
        madalya verilir. Ayrıca bu okullara çeşitli kitap ödülleri de
        verilecektir.
      </li>
      <li>
        Her sınıfta ilk üç dereceye giren takımların öğrencileri Şampiyona
        sonunda internet üzerinden yapılacak olan Türkiye Zeka Vakfı “Düşünce
        Şenliği”ne katılım hakkı kazanır.
      </li>

      <li>
        Ayrıca;
        <ul>
          <li>
            Yarışmaya katılan tüm öğrencilere -derecelerine göre- E-Okul
            sistemine yüklenmek üzere Sosyal Etkinlik Bilgilendirme Formu
            verilir.
          </li>
          <li>
            Takım Belirleme Sınavı’nda Türkiye geneli sıralamada bireysel olarak
            ilk %30’luk dilime giren tüm yarışmacılara elektronik Başarı
            Belgesi, diğer katılımcılara elektronik Katılım Belgesi verilir,
          </li>
          <li>
            İl Finalleri Sınavı’nda Türkiye geneli sıralamada takım olarak ilk
            %30’luk dilime giren tüm takım üyelerine elektronik İl Finalleri
            Başarı Belgesi, diğer katılımcılara elektronik İl Finalleri Katılım
            Belgesi verilir,
          </li>
          <li>
            Bölge Finalleri Sınavı’nda sonraki aşamaya katılım hakkı kazanan tüm
            takım üyelerine elektronik Bölge Finalleri Başarı Belgesi, diğer
            katılımcılara elektronik Bölge Finalleri Katılım Belgesi verilir,
          </li>
          <li>
            Türkiye Finalleri Sınavı’nda ilk 3 dereceye giren tüm takım
            üyelerine elektronik Türkiye Finalleri Başarı Belgesi, diğer
            katılımcılara elektronik Türkiye Finalleri Katılım Belgesi verilir.
          </li>
          <li>
            Her aşama sonunda öğrencilerin/takımların elde ettiği başarı
            seviyesine göre sorumlu öğretmenlere ve okullara elektronik
            katılım/başarı belgesi verilir.
          </li>
        </ul>
      </li>

      <li>
        Millî Eğitim Bakanlığına bağlı olarak yurt dışında faaliyet gösteren
        okullardan Şampiyona'ya katılım olması halinde ilgili ülke ayrı bir
        bölge olarak değerlendirilecektir. Türkiye Finalleri Sınavı sonucuna
        göre söz konusu okulların derecelendirilmesi ve ödüllendirilmesi
        konusunda TZV tarafından ayrıca karar alınacaktır.
      </li>
    </ul>
  </div>
);

const Steps = ({ hideAboutSection }) => {
  const [activeIndexes, setActiveIndexes] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  });

  const toggleCollapse = (show, index) => {
    const isThisIndexActive = activeIndexes[index];
    setActiveIndexes({
      ...activeIndexes,
      [index]: !isThisIndexActive,
    });

    // animateScroll.scrollToTop();
  };

  return (
    <div className={classNames.steps_container}>
      <Typography color="orange" size="bigger">
        Koşullar ve Aşamalar
      </Typography>
      <div className={"card " + classNames.steps}>
        {!hideAboutSection && (
          <Collapsable
            title={<StepTitle left="Yarışma Hakkında" />}
            body={about}
            onToggle={(show) => toggleCollapse(show, 1)}
            showing={activeIndexes[1]}
          />
        )}
        <Collapsable
          title={<StepTitle left="Koşullar" />}
          body={conditions}
          onToggle={(show) => toggleCollapse(show, 2)}
          showing={activeIndexes[2]}
        />
        <Collapsable
          title={<StepTitle left="Aşama 1" right="Takım Belirleme Sınavı" />}
          body={Step1Body}
          onToggle={(show) => toggleCollapse(show, 6)}
          showing={activeIndexes[6]}
        />
        <Collapsable
          title={<StepTitle left="Aşama 2" right="İl Finalleri Sınavı" />}
          body={Step2Body}
          onToggle={(show) => toggleCollapse(show, 3)}
          showing={activeIndexes[3]}
        />
        <Collapsable
          title={<StepTitle left="Aşama 3" right="Bölge Finalleri Sınavı" />}
          body={Step3Body}
          onToggle={(show) => toggleCollapse(show, 4)}
          showing={activeIndexes[4]}
        />
        <Collapsable
          title={<StepTitle left="Aşama 4" right="Türkiye Finalleri Sınavı" />}
          body={Step4Body}
          onToggle={(show) => toggleCollapse(show, 5)}
          showing={activeIndexes[5]}
        />
        <Collapsable
          title={<StepTitle left="Derecelendirme ve Ödüller" />}
          body={lastNotes}
          onToggle={(show) => toggleCollapse(show, 6)}
          showing={activeIndexes[6]}
        />
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Steps;
