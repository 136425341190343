
import React, { useState } from "react";
import classNames from "./style.module.css";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const topButtonsValues = [
  { text: "BASIN BÜLTENİ", value: 0 },
  { text: "DERECE ALAN OKULLAR", value: 1 },
  { text: "FOTOĞRAF GALERİSİ", value: 2 },
  // { text: "BASINDA BİZ", value: 3 },
];

export default () => {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const topButtons = (
    <ButtonGroup
      size="large"
      color="secondary"
      aria-label="large outlined primary button group"
    >
      {topButtonsValues.map((b) => {
        return (
          <Button
            onClick={() => setSelectedMenu(b.value)}
            key={b.value + "topButton"}
            style={{ color: "white", fontWeight: 200 }}
          >
            {b.text}
          </Button>
        );
      })}
    </ButtonGroup>
  );

  return (
    <div className={classNames.pageContainer}>
      {topButtons}
      {selectedMenu === 0 && <Bulten />}
      {selectedMenu === 1 && <MedahallOfFame />}
      {selectedMenu === 2 && <PhotoGallery />}
    </div>
  );
};

const MedahallOfFame = () => {
  const generateMedal = ({
    medalType,
    schoolLogoPath,
    schoolName,
    studentList,
    tutorList,
  }) => {
    const silverMedal = (
      <img src="/assets/images/medals/silver.png" alt="silver" />
    );
    const goldMedal = <img src="/assets/images/medals/gold.png" alt="gold" />;
    const bronzeMedal = (
      <img src="/assets/images/medals/bronze.png" alt="bronze" />
    );

    const medalImage =
      medalType === "gold"
        ? goldMedal
        : medalType === "silver"
        ? silverMedal
        : bronzeMedal;

    const schoolLogoImage = (
      <div>
        <img
          src={schoolLogoPath ? schoolLogoPath : "/assets/images/meb-logo.png"}
          alt="home-logo"
          width="120"
          height="120"
        />
      </div>
    );

    const schoolNameSpan = (
      <div
        style={{
          marginTop: 30,
          fontSize: "24px",
          fontWeight: "bolder",
          color: "#15273A",
          textTransform: "capitalize",
        }}
      >
        {schoolName.toLocaleLowerCase()}
      </div>
    );

    const studentListBox = (
      <div style={{ marginTop: 15 }}>
        <div style={{ fontSize: "24px", color: "#E79121" }}>Takım</div>
        {studentList.map((s) => (
          <div style={{ fontSize: "20px", textTransform: "capitalize" }}>
            {s.toLocaleLowerCase()}
          </div>
        ))}
      </div>
    );

    const tutorListBox = (
      <div style={{ marginTop: 15 }}>
        <div style={{ fontSize: "24px", color: "#E79121" }}>
          Sorumlu Öğretmen
        </div>
        {tutorList.map((t) => (
          <div style={{ fontSize: "20px", textTransform: "capitalize" }}>
            {t.toLocaleLowerCase()}
          </div>
        ))}
      </div>
    );
    return (
      <div
        style={{
          borderRadius: "15px",
          position: "relative",
          width: 339,
          height: 633,
          textAlign: "center",
          lineHeight: 1.1,
          margin: 10,
        }}
      >
        {medalImage}
        <div
          style={{
            position: "absolute",
            top: 150,
            textAlign: "center",
            width: 338,
            paddingLeft: 27,
            paddingRight: 27,
          }}
        >
          {schoolLogoImage}
          {schoolNameSpan}
          {studentListBox}
          {tutorListBox}
        </div>
      </div>
    );
  };

  const generateMedalGroup = ({
    groupName,
    goldMedal,
    silverMedal,
    bronzeMedal,
  }) => {
    const header = (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "42px",
          paddingBottom: 20,
          borderBottom: "solid #DA571A 2px",
          marginBottom: 20,
          lineHeight: 1,
        }}
      >
        <span style={{ color: "#DA571A", textAlign: "left" }}>
          Türkiye Finalleri Sonuçları
        </span>
        <span style={{ color: "#15273A", fontWeight: "bold", marginLeft: 30 }}>
          {groupName}
        </span>
      </div>
    );

    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 15,
          padding: 20,
          maxWidth: 1200,
          margin: 20,
        }}
      >
        {header}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {goldMedal}
          {silverMedal}
          {bronzeMedal}
        </div>
      </div>
    );
  };

  //BİRİNCİ SINIF
  const birinciSinifMedals = generateMedalGroup({
    groupName: "Birinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F1_1_ilgim_koleji.png?alt=media&token=6b1b5a35-afb3-4c17-93f8-02b341b250aa",
      schoolName: "Özel Profesyonel İlgim İlkokulu",
      studentList: ["Emir Aktaş", "Çağın Alp Şengül", "Kubilay Polattan"],
      tutorList: ["Rabia Tayfur"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F1_2_bahcesehir_su%CC%88leyman_demirel.jpeg?alt=media&token=1e0120b8-a641-417d-8c22-c73a8842f68c",
      schoolName: "Bahçeşehir Süleyman Demirel İlkokulu",
      studentList: ["Ömer Metehan Karabıyık", "Elif Su Dansık"],
      tutorList: ["Serkan Dansık"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F1_3_lina_ilkokulu.jpeg?alt=media&token=cefa23ea-5e4d-4221-8574-bb7322395335",
      schoolName: "Özel Lina İlkokulu",
      studentList: ["Atabey Işık", "Selim Avlar", "Zülal Karakaş"],
      tutorList: ["Kübra Dizlek Nas"],
    }),
  });

  //İKİNCİ SINIF
  const ikinciSinifMedals = generateMedalGroup({
    groupName: "İkinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F2_1_kecioren_bilsem.jpeg?alt=media&token=5aebaea1-d750-4e0a-9187-cd2fd939336a",
      schoolName: "Keçiören Bilim ve Sanat Merkezi",
      studentList: ["Azra Beren Çetinkaya", "Çağan Başçam", "Yiğit Yücel"],
      tutorList: ["Ebru Özen"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F2_2_sorgun_bilsem.jpeg?alt=media&token=190b8d6e-8482-48d3-9a8e-d430fd69875b",
      schoolName: "Sorgun Bilim ve Sanat Merkezi",
      studentList: ["Erdem Asaf Tokgöz", "Deniz Aslan"],
      tutorList: ["Mukaddes Ünal"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F2_3_fuat_sezgin_bilsem.jpeg?alt=media&token=90466b97-26b8-4334-b5d2-68db247fc932",
      schoolName: "İstanbul Fuat Sezgin Bilim ve Sanat Merkezi",
      studentList: [
        "Leo Okyanus Düştegör",
        "Uzay Mazhar Tuğyanoğlu",
        "Uygar Arık",
      ],
      tutorList: ["Berna Bengül Bursalıoğlu"],
    }),
  });

  //ÜÇÜNCÜ SINIF
  const ucuncuSinifMedals = generateMedalGroup({
    groupName: "Üçüncü Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F3_1_fatih_satir_bilsem.jpeg?alt=media&token=a8e7df18-c771-40d7-800f-db9716f16cf6",
      schoolName: "Şehit Fatih Satır Bilim ve Sanat Merkezi",
      studentList: ["Mehmet Erdemir", "Hazar Coşkun", "Beren Yandım"],
      tutorList: ["Tuğba Coşkun"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F3_2_vezirkopru_mehmet_pasa.jpeg?alt=media&token=f32a184a-e9f2-40f1-b1ae-2044ddab4d27",
      schoolName: "Mehmet Paşa İlkokulu",
      studentList: [
        "Ahmet Orhan Şen",
        "Yaşar Ata Aydoğdu",
        "Yağmur Zehra Acar",
      ],
      tutorList: ["Nuray Karakuş"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F3_3_adapazari_osmanbey.jpeg?alt=media&token=2ceeeb99-6c46-49cb-8fc4-48539543f090",
      schoolName: "Osmanbey İlkokulu",
      studentList: [
        "İsmail Taha Solmaz",
        "Ömer Yusuf Özcan",
        "Tuğde Ulya Aldemir",
      ],
      tutorList: ["Asiya Tekşen"],
    }),
  });

  ///DÖRDÜNCÜ SINIF
  const dorduncuSinifMedals = generateMedalGroup({
    groupName: "Dördüncü Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F4_1_ted_ankara_koleji.jpeg?alt=media&token=fa9b19ad-79a1-43e2-ad43-f6c0f40073ba",
      schoolName: "TED Ankara Koleji Vakfı Özel İlkokulu",
      studentList: ["Kaan Ay", "Mehmet Toprak Karakaya", "Demir Sitrava"],
      tutorList: ["Caner Uluer"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "Bilge Kağan İlkokulu",
      studentList: [
        "Muhammed Kağan Demir",
        "Zeynep Ela Corcor",
        "Dide Nur Güner",
      ],
      tutorList: ["Havva Baş"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F4_3_kartal_bilsem.jpeg?alt=media&token=945d889b-fa9e-4c77-ba9d-a8663a01c3eb",
      schoolName: "Kartal Bilim ve Sanat Merkezi",
      studentList: ["İnci Hüma Alemdar", "Alperen Ertuğ", "Utku Onur"],
      tutorList: ["Umut Bektaş"],
    }),
  });

  //// BEŞİNCİ SINIF
  const besinciSinifMedals = generateMedalGroup({
    groupName: "Beşinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F5_1_bahcesehir_koleji_ortaokulu.jpeg?alt=media&token=b6fe56b7-04ab-46af-934a-bf0c86b7c1f6",
      schoolName: "Özel Karşıyaka Bahçeşehir Koleji Ortaokulu",
      studentList: ["Yağız Aydın", "Nehir Boz", "Suat Arhan Akçay"],
      tutorList: ["Derya Aykut"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F4_3_kartal_bilsem.jpeg?alt=media&token=945d889b-fa9e-4c77-ba9d-a8663a01c3eb",
      schoolName: "Kartal Bilim ve Sanat Merkezi",
      studentList: [
        "Yusuf Numan Keskin",
        "Eymen Karayavuz",
        "Ahmet Ferhan Başkurt",
      ],
      tutorList: ["Umut Bektaş"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F2_3_fuat_sezgin_bilsem.jpeg?alt=media&token=90466b97-26b8-4334-b5d2-68db247fc932",
      schoolName: "İstanbul Fuat Sezgin Bilim ve Sanat Merkezi",
      studentList: ["Erhan Solmazgül", "Ateş Özkan", "Muhammed Enes Kara"],
      tutorList: ["Dilek Koç"],
    }),
  });

  //ALTINCI SINIF
  const altinciSinifMedals = generateMedalGroup({
    groupName: "Altıncı Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F6_1_profesyonel_egitim_ortaokulu.jpeg?alt=media&token=b63e9ddc-0ac8-4a16-a770-75a3b4f16f70",
      schoolName: "Özel Profesyonel Eğitim Ortaokulu",
      studentList: [
        "Kağan Yarar",
        "Yusuf Serdar Karagül",
        "Eyüp Ensar Altuntaş",
      ],
      tutorList: ["Sefa Tekin"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F6_2_sadettin_turkun_ortaokulu.jpeg?alt=media&token=42f3d30a-b7c5-4422-aad6-ec1b976309c1",
      schoolName: "Sadettin Türkün Ortaokulu",
      studentList: ["Mehmet Tarık Sarul", "Yusuf Eren Çelik", "Buğlem Gökmen"],
      tutorList: ["Gülay Sevinç"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F6_3_ulker_yorukoglu_bilsem.jpeg?alt=media&token=1c0d4372-bb7d-4d1a-bcf4-419b16947f1a",
      schoolName: "Ülker Yörükoğlu Bilim ve Sanat Merkezi",
      studentList: ["Serra Nil Alver", "Emir Kaynak", "Sinnur İslamoğlu"],
      tutorList: ["Kadir Alper Çetin"],
    }),
  });

  //YEDİNCİ SINIF
  const yedinciSinifMedals = generateMedalGroup({
    groupName: "Yedinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F7_1_servergazi_ih_ortaokulu.jpeg?alt=media&token=3b8bebcf-f36e-4a3d-872f-34fc05c9d045",
      schoolName: "Servergazi İmam Hatip Ortaokulu",
      studentList: ["Banu Tuğba Allın", "Yusuf Ali Toker", "Ahmet Kerem Akşit"],
      tutorList: ["Faruk Gülhan"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F4_3_kartal_bilsem.jpeg?alt=media&token=945d889b-fa9e-4c77-ba9d-a8663a01c3eb",
      schoolName: "Kartal Bilim ve Sanat Merkezi",
      studentList: ["Ali Talha Turgut", "Edibe Silan", "Nehir Başkaya"],
      tutorList: ["Umut Bektaş"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F6_1_profesyonel_egitim_ortaokulu.jpeg?alt=media&token=b63e9ddc-0ac8-4a16-a770-75a3b4f16f70",
      schoolName: "Özel Profesyonel Eğitim Ortaokulu",
      studentList: [
        "Muhammed Renas Bayhan",
        "Arın Enhar Çalışkan",
        "Toprak Eymen Bağcı",
      ],
      tutorList: ["Sefa Tekin"],
    }),
  });

  //SEKİZİNCİ SINIF
  const sekizinciSinifMedals = generateMedalGroup({
    groupName: "Sekizinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "Araştırma Geliştirme Eğitim ve Uygulama Merkezi Ortaokulu",
      studentList: ["Yiğit Baran Durmuş", "Yağız Efe Uzun", "Egemen Ertuğ"],
      tutorList: ["Yudum Özer"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "Şahin Ortaokulu",
      studentList: ["Tuğra Yeşil", "Yağız Bayraktar", "Murathan Çağlar"],
      tutorList: ["Derya Hızarcıoğlu"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F4_1_ted_ankara_koleji.jpeg?alt=media&token=fa9b19ad-79a1-43e2-ad43-f6c0f40073ba",
      schoolName: "TED Ankara Koleji Vakfı Özel Ortaokulu",
      studentList: ["Yankı Karadağ", "Deren Bostan", "Deniz Erdem Dağdelen"],
      tutorList: ["Yasemin Yılmaz"],
    }),
  });

  //DOKUZUNCU SINIF
  const dokuzuncuSinifMedals = generateMedalGroup({
    groupName: "Dokuzuncu Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "Galatasaray Üniversitesi Galatasaray Lisesi",
      studentList: ["Muhammet Burak Ünlü", "Onur Yılmaz", "Kenan Sarı"],
      tutorList: [],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F6_1_profesyonel_egitim_ortaokulu.jpeg?alt=media&token=b63e9ddc-0ac8-4a16-a770-75a3b4f16f70",
      schoolName: "Özel Profesyonel Eğitim Fen Lisesi",
      studentList: [
        "Zeynep Ceren Ayrancı",
        "Zahide Zeren Yıldız",
        "Ümmühani Zümra Yıldız",
      ],
      tutorList: ["Mevlüt Uğur"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F9_3_izmir_ataturk_lisesi.jpeg?alt=media&token=c5bdcd41-bc9a-4009-aa8e-5edb9c985106",
      schoolName: "Atatürk Lisesi",
      studentList: ["Ayşe Naz Gürkan", "Özgün Onur Özdemir", "Duru Sönmez"],
      tutorList: ["Nur Kayanselçuk"],
    }),
  });

  //ONUNCU SINIF
  const onuncuSinifMedals = generateMedalGroup({
    groupName: "Onuncu Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F6_1_profesyonel_egitim_ortaokulu.jpeg?alt=media&token=b63e9ddc-0ac8-4a16-a770-75a3b4f16f70",
      schoolName: "Özel Profesyonel Eğitim Fen Lisesi",
      studentList: ["Yiğit Altınöz", "Tahsin Sağlam", "Ayşe Torun"],
      tutorList: ["Mevlüt Uğur"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F10_2_burak_bora_anadolu.jpeg?alt=media&token=3302f424-ab41-4435-9044-1a556d018e83",
      schoolName: "Burak Bora Anadolu Lisesi",
      studentList: ["Arda Genç", "Hakan Mutlu", "Efe Süzen"],
      tutorList: ["Fikriye Yürükcü"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F10_3_kadikoy_bilsem.jpeg?alt=media&token=9f793ef1-801f-4af6-9757-547d07655e0a",
      schoolName: "Kadıköy Bilim ve Sanat Merkezi",
      studentList: ["Bersu Berker", "Esma Kaya", "Mehmet Hasan Kaya"],
      tutorList: ["Yudum Özer"],
    }),
  });

  //ONBİRİNCİ SINIF
  const onbirinciSinifMedals = generateMedalGroup({
    groupName: "On Birinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F3_1_fatih_satir_bilsem.jpeg?alt=media&token=a8e7df18-c771-40d7-800f-db9716f16cf6",
      schoolName: "Şehit Fatih Satır Bilim ve Sanat Merkezi",
      studentList: ["Zeki Ege Derse", "Meriç Ege Ericek", "Emir Talha Çelik"],
      tutorList: ["Tuğba Coşkun"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F11_2_enka_lisesi.jpeg?alt=media&token=f8dd01de-71ca-4c7f-92a9-63985339fa32",
      schoolName: "Özel Enka Lisesi",
      studentList: ["Barış Koyuncu", "Mehmet Yıldız", "Kerem Yıldız"],
      tutorList: ["Gafur Taşkın"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "",
      schoolName: "Merfizon Fen Lisesi",
      studentList: ["Zeren Topal", "Ayberk Seyhan", "Zeynep Kazanç"],
      tutorList: ["Gülhan Çalışır"],
    }),
  });

  //ONİKİNCİ SINIF
  const onikinciSinifMedals = generateMedalGroup({
    groupName: "On İkinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F12_1_nilufer_imkb_fen.jpeg?alt=media&token=78162b22-8721-482b-b755-105800642325",
      schoolName: "Nilüfer İMKB Fen Lisesi",
      studentList: ["Yusuf Alp Çapar", "Arda Cenker Karagöz", "Mehmet Seymen"],
      tutorList: ["Süleyman Arslanhan"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F12_2_ted_atakent_koleji.png?alt=media&token=f600a191-c179-4f89-8599-5d9567a2e410",
      schoolName: "TED Özel Atakent Anadolu Lisesi",
      studentList: [
        "Yaman Yusufcan Çırak",
        "Taylan Güney Doğan",
        "Ege Basat Bağcılar",
      ],
      tutorList: ["İbrahim Halil Fidan"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath:
        "https://firebasestorage.googleapis.com/v0/b/championship-meb.appspot.com/o/tozos_22_23%2Fderece_alan_okul_logolari%2F12_3_turk_fen_lisesi.jpeg?alt=media&token=fc49d7b3-8b24-49b8-bedc-2163880899e4",
      schoolName: "Özel Türk Fen Lisesi",
      studentList: [
        "Aypars Ateş Hazantekin",
        "Fırat Seleker",
        "Ali Nail Somuncu",
      ],
      tutorList: [],
    }),
  });
  return (
    <div>
      {birinciSinifMedals}
      {ikinciSinifMedals}
      {ucuncuSinifMedals}
      {dorduncuSinifMedals}
      {besinciSinifMedals}
      {altinciSinifMedals}
      {yedinciSinifMedals}
      {sekizinciSinifMedals}
      {dokuzuncuSinifMedals}
      {onuncuSinifMedals}
      {onbirinciSinifMedals}
      {onikinciSinifMedals}
    </div>
  );
};

const PhotoGallery = () => {
  return (
    <div style={{ marginTop: "30px" }}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://halici.pixieset.com/trkyezekaoyunlariampyonasi2022-23/"
      >
        <Button size="large" color="secondary" variant="contained">
          GALERİYE ULAŞMAK İÇİN TIKLAYINIZ
        </Button>
      </a>
    </div>
  );
};

const Bulten = () => {
  return (
    <div
      className={"card " + classNames.long_description}
      // className={"card"}
      style={{ fontSize: "medium" }}
    >
      <h4 style={{ fontWeight: "bold", marginTop: 30 }}>
        Türkiye Okullar Arası Zeka Oyunları Şampiyonası’nın Ödül Töreni ODTÜ'de
        Yapıldı!
      </h4>
      <div
        className={"card " + classNames.sponsor_card}
        style={{ marginTop: 0 }}
      >
        <iframe
          width="560"
          maxWidth="100%"
          height="315"
          // maxHeight = "100%"
          src="https://www.youtube.com/embed/wX1SGCKw-qo"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <p>
        Türkiye Zeka Vakfının, Millî Eğitim Bakanlığı ve TÜBİTAK’ın destekleri
        ile 2019 yılında başlattığı “Türkiye Okullar Arası Zeka Oyunları
        Şampiyonası”nın ödül töreni 3 Haziran Cumartesi günü ODTÜ’de Fizik
        Bölümü’nde yapıldı.
      </p>
      <h5 style={{ fontWeight: "bold" }}>
        21 Bin 860 Başvuru, 1.521 Finalist...
      </h5>
      <p>
        Rekor katılımın olduğu Şampiyona’ya, öğrenci ve öğretmenlerden oluşan
        toplam 21 bin 860 başvuru oldu. Bu sene de 81 ilden de katılım oldu!
      </p>
      <p>
        İlkokul, ortaokul ve lise kategorisinde toplam on iki sınıf üzerinden
        gerçekleştirilen Şampiyona’ya, gelen yoğun talep doğrultusunda bu sene
        1. ve 2. sınıflar da dâhil edildi.
      </p>
      <p>
        En çok katılımın olduğu üç bölge sırasıyla; Marmara, İç Anadolu ve Ege
        Bölgesi oldu.
      </p>
      <h5 style={{ fontStyle: "italic", fontWeight: "bold" }}>
        “Zekâlarını kullanma konusunda kendilerini ispatlamış ve başarıya
        ulaşmış öğrenciler bugün için umut kaynağı gelecek için gurur
        kaynağıdır.”
      </h5>
      <p>
        <b>Türkiye Zeka Vakfı Başkanı Emrehan Halıcı</b> konuşmasında; salonda
        bulunan öğrencilerin Türkiye’nin geleceği olduğunu belirtti. Zekâlarını
        kullanma konusunda kendilerini ispatlamış ve başarıya ulaşmış
        öğrencilerin bugün için umut kaynağı gelecek için gurur kaynağı olduğunu
        dile getirdi.
      </p>
      <p>
        Halıcı, öğrencilere “Zekânızı bilgi ve emekle zenginleştirin. Düşünmeye,
        araştırmaya, öğrenmeye devam edin. Sizin gibi arkadaşlarınızın da
        kıymetini bilin, bağlarınızı güçlendirin. En büyük hazine, insan
        hazinesidir. Problem çözmek, sorun çözmek bir alışkanlık meselesidir.
        Yakın çevrenizdeki sorunları da bilimde, teknolojide, sanatta,
        ekonomide, ülke yönetiminde yine sizlerin çözeceğine inanıyorum” dedi.
      </p>
      <h5 style={{ fontWeight: "bold", fontStyle: "italic" }}>
        “Kimi zaman odaklanmak, kimi zaman takım ruhunun olması, kimi zaman
        analitik düşüncenizin kuvvetli olması gerekiyor. Bu bakımdan her bir
        zekâ sorusu hayatı simule ediyor.”
      </h5>
      <p>
        <b>ODTÜ Rektör Danışmanı Prof. Dr. Eren Kalay</b> konuşmasında;
        Şampiyona’nın hem final sınavının hem de ödül töreninin ODTÜ’nün ev
        sahipliğinde yapılmasından mutluluk duyduğunu dile getirdi. Kalay,
        öğrencilik zamanında Emrehan Halıcı’nın Bilim ve Teknik Dergisinde
        yayınlanan yazılarını okuyup, sorularını çözerek bilim dünyasına
        katıldığını belirtti. “Bilim insanın olmazsa olması merak duygusu ve
        analitik düşünceyi bende de geliştirmeye katkı sağladığı için Emrehan
        Bey’e bir kez daha teşekkürlerimi iletiyorum.” dedi.
      </p>
      <p>
        Kalay, Zekâ oyunlarının hayatı simüle ettiğini, kısa sürede çözülen
        sorularda aslında hayatın kendisini gördüğümüzü belirtti. Hayatta
        başarılı olmak üzere gerekli olan unsurların çoğunun bu sorularda
        olduğunu dile getirdi.
      </p>
      <h5>Ödüller…</h5>
      <p>
        Şampiyona’da derece elde eden takımların öğrenci ve öğretmenlerine
        <b> madalya, başarı belgesi ve kupa</b> verildi. Ayrıca okullara Türkiye
        Zeka Vakfı ve Türkiye İş Bankası Yayınlarından kitap setleri verildi.
      </p>
    </div>
  );
};
