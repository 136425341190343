const keys = ["uid", "access-token", "client"];

export const getAuthHeadersFromLocalStorage = () =>
  keys.reduce((acc, key) => {
    acc[key] = localStorage.getItem(key);
    return acc;
  }, {});

export const setAuthHeadersToLocalStorage = (data) => {
  for (let key in data) localStorage.setItem(key, data[key]);
};

export const clearAuthDataFromLocalStorage = () => {
  for (let key of keys) localStorage.removeItem(key);
  localStorage.removeItem("user");
};
export const getUserFromLocalStorage = () =>
  JSON.parse(localStorage.getItem("user"));
