import { firestoreDb } from "./config/firebaseConfig";
// import firebase from "firebase/app";

import firebase from "firebase/compat/app";
import axios from "axios";
import { allResults } from "./temp";
import { firebase_data_x } from "./firebase_temp_data";

import { increment } from "firebase/firestore";

// import { BF_RESULTS, IF_RESULT_FORMS, IL_FINALI_RESULTS } from "./common/constants";

const axiosInstance = axios.create({
  baseURL: "https://us-central1-championship-meb.cloudfunctions.net",
  //baseURL: "http://localhost:5001/championship-meb/us-central1", // firebase emulators:start   komutu ile cloud fonksiyonları deploy öncesi localde test edebilirsin.
  timeout: 50000,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});

const pullExamFromCloudFunctions = async (userId, examId) => {
  const cloudFunctionArgumans =
    "/pullExam?userId=" + userId + "&examId=" + examId;
  return axiosInstance
    .get(cloudFunctionArgumans)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("PULL EXAM  error", error);
      return {
        message:
          "HATA: Sınav bilgileri çekilemedi. Lütfen sayfayı yenileyiniz.",
        exam: null,
        formId: null,
        questions: [],
      };
    });
};

const pullAndListenTeamAnswers = (formId, callback) => {
  firestoreDb
    .collection("forms")
    .doc(formId) //.where("rol", "==", r.id)
    .onSnapshot((ss) => {
      callback(ss.data());
    });
};

const updateAnswerOfTeam = async (
  examId,
  responderId,
  responderName,
  formId,
  questionId,
  response
) => {
  const responderNameForUrl = responderName.trim().replaceAll(" ", "+");

  const cloudFunctionArgumans =
    "/updateAnswerOfTeam?responderId=" +
    responderId +
    "&examId=" +
    examId +
    "&responderName=" +
    responderNameForUrl +
    "&formId=" +
    formId +
    "&questionId=" +
    questionId +
    "&response=" +
    response;
  return axiosInstance
    .get(cloudFunctionArgumans)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("updateAnswerOfTeam error: ", error);

      return {
        message:
          "HATA: İnternet bağlantısı probleminiz olabilir. Lütfen sayfayı yenileyiniz.",
      };
    });
};

const insertQuestion = async (params) => {
  let optionsObject = {};
  if (params.type === 1) {
    optionsObject = {
      choices: {},
    };
    ["A", "B", "C", "D", "E", "F", "G", "H"].forEach((key) => {
      if (params[key])
        optionsObject.choices[key] = { choice_text: params[key] };
    });
  }
  let imagePathObject = {};
  if (
    params.question_image_path &&
    params.question_image_path.trim().length > 0
  )
    imagePathObject = {
      question_image_path: params.question_image_path.trim(),
    };
  const finalObject = {
    question_text: params.question_text,
    order: params.order,
    header: params.header,
    exams: params.exams,
    type: params.type,
    ...imagePathObject,
    ...optionsObject,
  };
  // console.log("uid: " + params.uid);
  // console.log(`finalObject: `, finalObject);
  //alert("insert işlemini commentledim. Lütfen kodu inceledikten sonra uncomment yapınız...");
  await firestoreDb
    .collection("questions")
    .doc(params.uid)
    .set(finalObject, { merge: true });
};

//Firebase storage'a yüklenmiş dosyaların path'lerini yine firebase'den çekiyoruz.
//Niye: sonuçların yayınlanması esnasında bu belgeler ha bire güncelleniyor, her değişiklikte deploya gerek kalmasın, dosyalar ve url'leri de dinamik olsun.
const pullFilePaths = async (bucketName) => {
  return firestoreDb
    .collection("file_paths")
    .doc(bucketName)
    .get()
    .then((doc) => {
      return doc.data();
    });
};

const pullUserDataFromFirebase = async (uid) => {
  return await firestoreDb
    .collection("user_results")
    .doc(uid.toString())
    .get()
    .then((doc) => {
      return { [doc.id]: doc.data() };
    });
};

export const pullTfResultsFromFirebaseByTableNo = async (tableNo) => {
  return await firestoreDb
    .collection("temporary_results")
    .doc(tableNo.toString())
    .get()
    .then((doc) => {
      return { ...doc.data() };
    });
};

export const insertTemporaryResults = async () => {
  const results = [
    // {tableNo:101, rank:15, score: '0', additionalScore: '0'},
  ];

  const qScores = {
    1: [
      20, 19.655, 20, 18.966, 19.655, 20, 18.966, 19.655, 18.276, 19.31, 20, 20,
      20, 20, 20, 20, 20, 20, 19.655, 19.655,
    ],
    2: [
      19.535, 13.721, 20, 16.279, 14.884, 16.047, 17.442, 18.14, 17.442, 15.581,
      18.837, 19.767, 18.605, 19.302, 20, 17.674, 17.674, 18.605, 18.14, 18.605,
    ],
    3: [
      18.537, 12.927, 19.756, 18.537, 11.707, 12.927, 18.293, 16.098, 14.634,
      13.659, 18.049, 20, 17.561, 17.317, 19.512, 18.049, 17.561, 18.293,
      17.561, 18.293,
    ],
    4: [
      16.279, 11.628, 19.767, 17.209, 10.233, 11.628, 17.209, 15.116, 14.884,
      12.326, 17.442, 18.605, 16.279, 17.674, 19.535, 15.814, 16.279, 18.372,
      16.744, 17.209,
    ],
    5: [
      18.511, 11.489, 20, 17.234, 15.745, 12.766, 17.234, 14.255, 16.17, 17.447,
      15.532, 15.745, 17.872, 19.574, 18.723, 15.957, 17.021, 18.085, 18.723,
      18.936,
    ],
    6: [
      17.333, 11.556, 20, 16.222, 13.333, 11.556, 16.889, 13.333, 16.222,
      17.778, 15.556, 15.333, 18.222, 18.889, 18.222, 17.333, 17.556, 17.111,
      18.222, 18.667,
    ],
    7: [
      15.625, 10.625, 19.167, 15.208, 10.417, 10.417, 13.333, 12.5, 13.75,
      15.833, 13.542, 13.958, 16.875, 18.125, 18.333, 14.583, 15.208, 15.417,
      15.833, 16.25,
    ],
    8: [
      13.659, 10.976, 18.537, 14.146, 10.488, 10.732, 14.878, 12.195, 13.171,
      14.878, 12.683, 12.927, 14.39, 16.341, 16.829, 14.634, 13.659, 15.122,
      16.341, 16.341,
    ],
    9: [
      14.222, 12.667, 19.333, 17.556, 10.667, 10.889, 15.333, 11.111, 11.111,
      16.667, 13.111, 12, 15.778, 18.222, 18.222, 14.222, 14, 15.333, 15.778,
      16,
    ],
    10: [
      13.902, 12.195, 17.317, 17.317, 10, 10.488, 14.634, 10.732, 10.488,
      16.829, 13.415, 12.195, 14.878, 18.049, 17.317, 15.122, 14.634, 15.61,
      15.854, 15.61,
    ],
    11: [
      12.889, 12.667, 17.556, 16.444, 10, 10, 14.222, 10.889, 10.889, 16,
      12.222, 12.222, 13.778, 17.778, 17.556, 14.667, 14.444, 15.111, 15.778,
      16,
    ],
    12: [
      13.125, 11.875, 19.063, 17.188, 10.938, 11.25, 16.25, 12.188, 11.875,
      17.188, 16.25, 15.625, 17.188, 19.063, 18.438, 15.625, 16.875, 17.188,
      18.125, 18.125,
    ],
  };

  await firestoreDb
      .collection("temporary_results")
      .doc("0_question_scores")
      .set(qScores, { merge: true });

  // for (const res of results) {
  //   const { tableNo, rank, score, additionalScore } = res;
  //   const obj = {
  //     rank,
  //     score,
  //     additionalScore,
  //   };
  //   await firestoreDb
  //     .collection("temporary_results")
  //     .doc(tableNo.toString())
  //     .set(obj, { merge: true });

  //   console.log("tableNo: ", tableNo);
  // }
};

export const pullUserDataFromFirebaseByArray = async (uids) => {
  if (uids.length > 250) {
    alert("Çok fazla sonuç olduğu için sınav sonuçları bilgisi çekilemedi");
    return {};
  }
  let res = {};
  let promises = uids.map((uid) =>
    firestoreDb.collection("user_results").doc(uid.toString()).get()
  );

  const docs = await Promise.all(promises);
  docs.forEach((doc) => {
    const data = doc.data() || {};
    res[doc.id] = data;
  });

  return res;
};

const pullUserDataFromFirebaseBySchool = async (school_id) => {
  let res = {};
  await firestoreDb
    .collection("user_results")
    .where("school_id", "==", school_id)
    .get()
    .then((ss) => {
      ss.docs.forEach((doc) => {
        res[doc.id] = doc.data();
      });
    });

  return res;
};

const firebase_do_something = async () => {
  // db'ye sorgu atma vs. manuel işlemler bu şekilde tetikleniyor. Biraz amelece..
  //question_isleri();
  // result_isleri();
  //forms_isleri();
  //user_isleri();
  //exam_isleri();
};

export const incrementIncomingSourceAmount = async (source_name) => {
  await firestoreDb
    .collection("source_click_numbers")
    .doc(source_name)
    .set(
      {
        click_amount: increment(1),
      },
      { merge: true }
    );
};

export {
  pullUserDataFromFirebase,
  pullUserDataFromFirebaseBySchool,
  pullAndListenTeamAnswers,
  updateAnswerOfTeam,
  pullExamFromCloudFunctions,
  insertQuestion,
  pullFilePaths,
  firebase_do_something,
};

const forms_isleri = () => {
  let forms = [];

  firebase
    .firestore()
    .collection("forms")
    .get()
    .then((ss) => {
      const dizi = ss.docs.map((doc) => {
        const f = doc.data();
        const form_id = doc.id;
        if (form_id.substring(0, 12) == "tozos2021_tf") {
          let form_object = {};
          form_object.id = form_id;
          form_object.cevaplar = f;
          forms.push(form_object);
        }
      });
      // console.log("forms: ", forms);
    });

  //const questionId = "q2";
  //firestoreDb.doc("forms/" +formId).set({[`responses.${questionId}`] : {responder: 2756, response: "D"}});
  // let sonuclar= "";
  // let forms = [];
  //  firebase.firestore().collection('forms').get().then((ss)=>{
  //     const dizi = ss.docs.map(doc => {
  //       const f = doc.data();
  //       const form_id= doc.id;
  //       if(form_id.substring(0,14)=="tozos_20-21_bf"){
  //         let form_object={};
  //         form_object.id= form_id;
  //         form_object.cevaplar=f;
  //         forms.push(form_object);
  //       }
  //      // return null;
  //       // sonuclar += form_id;
  //       // if(f.q7)
  //       // sonuclar += ","+f.q7.response;
  //       // else sonuclar += ",-";
  //       //  if(f.q8)
  //       // sonuclar += ","+f.q8.response;
  //       // else sonuclar += ",-";
  //       //  if(f.q9)
  //       // sonuclar += ","+f.q9.response;
  //       // else sonuclar += ",-";
  //       // sonuclar +="\n";
  //       // return {...doc.data(),
  //       // form_id: doc.id}
  //     });
  //     console.log("forms: " , forms);
  //  });
};

const exam_isleri = async () => {
  const oyunExams = [77, 80, 83, 86, 89];

  firestoreDb
    .collection("exams")
    .doc("oyun2021-test1-77")
    .get()
    .then((doc) => {
      oyunExams.forEach((id) => {
        const exam_id = id.toString();

        firestoreDb
          .collection("exams")
          .doc(exam_id)
          .set(
            {
              ...doc.data(),
              description: "Oyun 2021 Yarı Final Sınavı",
              header: "OYUN 2021 YARI FİNAL SINAVI (Kategori:" + id + ")",
            },
            { merge: true }
          );
      });
    });

  // let sira = 1;
  // trFinaliSorulari.forEach(s=>{
  //   const [sinif, soruUid, puan, cevap] = s;
  //   const examId = "tr_finali_" + sinif;

  //   firestoreDb
  //   .collection("questions")
  //   .doc(soruUid)
  //   .set({
  //     answer:cevap
  //   }, {merge:true});
  //   firestoreDb
  //   .collection("exams")
  //   .doc(examId)
  //   .set({
  //     questions:{
  //       [soruUid] : { score: puan}
  //     }
  //   },
  //     {merge:true})
  //     .then(console.log(sira++))

  // });

  // for (let i = 3; i < 13; i++) {
  //     const uid = "tr_finali_"+i;

  //   await   firestoreDb
  //   .collection("exams")
  //   .doc(uid)
  //   .get()
  //   .then((doc)=>{
  //     const sinav= doc.data();

  //     if(sinav){
  //       firestoreDb.collection('exams')
  //         .doc("deneme_final_" + i)
  //         .set({
  //           ...sinav
  //         }, {merge:true})

  //     }

  // });

  // }

  // firestoreDb
  //   .collection("exams")
  //   .doc('tr_finali_3')
  //   .get()
  //   .then((doc)=>{
  //     const sinav= doc.data();

  //     if(sinav){

  //       for (let i = 3; i < 13; i++) {
  //         const examUid= "tr_finali_"+i;

  //         firestoreDb.collection('exams')
  //         .doc(examUid)
  //         .set({
  //           start_date: sinav.start_date
  //         }, {merge:true})
  //       }

  //     }

  // });

  // firestoreDb
  //   .collection("exams")
  //   .doc("bolge_finali_5")
  //   .get()
  //   .then((doc) => {
  //     if (doc) {
  //       let tarih = doc.data().result_announcement_date;
  //       if (tarih) {
  //         for (let i = 3; i < 13; i++) {
  //           let examId = "bolge_finali_" + i;
  //           firestoreDb.collection("exams").doc(examId).set(
  //             {
  //               result_announcement_date: tarih,
  //             },
  //             { merge: true }
  //           ).then(console.log(i));
  //         }
  //       }
  //     }
  //   });
  // firestoreDb
  // .collection("exams")
  // .doc('temp_sinav')
  // .get()
  // .then((doc) => {
  //   // firestoreDb.collection('questions').doc('q7').set(doc.data());
  //   const q_list= doc.data().questions;
  //   const start_date = doc.data().start_date;
  //   for (let i = 3; i < 13; i++) {
  //     const examId= "e"+i;
  //     firestoreDb.collection('exams').doc(examId).set({
  //       start_date: start_date,
  //       duration: 90
  //     },
  //     { merge: true });
  //   }
  // });
  // };
};

const question_isleri = () => {
  firestoreDb
    .collection("questions")
    .get()
    .then((ss) => {
      ss.docs.map((d) => {
        if (d.id.substring(0, 14) === "tozos_20-21_tf") console.log(d.id);
      });
    });

  // firestoreDb
  // .collection("questions")
  // .doc('t9LSdmSWMjAZWGYOFTSZ')
  // .get()
  // .then((doc) => {
  //   firestoreDb.collection('questions').doc('q3').set(doc.data());
  // });
  //     const sorular =   [   ]
  // ;
  // sorular.forEach(q => {
  //   let soruNo = q.charAt(q.length-2);
  //   if(soruNo==="_")
  //    soruNo = q.charAt(q.length-1);
  //    else
  //    soruNo += q.charAt(q.length-1);
  //    const order= parseInt(soruNo);
  //    let header = "Soru-" +order;
  //    if(order>10)
  //     header = "Ek Soru-" + (order-10);
  //     firestoreDb
  //   .collection("questions")
  //   .doc(q)
  //   .set({
  //     order: order,
  //     header: header
  //   }, {merge:true});
  // });
  // firestoreDb
  //   .collection("exams")
  //   .doc('temp_sinav')
  //   .set({
  //     questions:sorular
  //   }, {merge:true});
};

const user_isleri = () => {
  const newUsers = [
    {
      id: "3759521018077361700",
      email: "minik@oyun.test",
      first_name: "minik tester",
      last_name: "",
      exam_teams: {
        ["77"]: "oyun2021_testFormMinik",
      },
    },
    {
      id: "1815968258363182300",
      email: "cocuk@oyun.test",
      first_name: "cocuk tester",
      last_name: "",
      exam_teams: {
        ["80"]: "oyun2021_testFormCocuk",
      },
    },
    {
      id: "377627967471821060",
      email: "genc@oyun.test",
      first_name: "genç tester",
      last_name: "",
      exam_teams: {
        ["83"]: "oyun2021_testFormGenc",
      },
    },
    {
      id: "3555836717464628700",
      email: "yetiskin@oyun.test",
      first_name: "yetişkin tester",
      last_name: "",
      exam_teams: {
        ["86"]: "oyun2021_testFormYetiskin",
      },
    },
    {
      id: "816253174817832200",
      email: "emekli@oyun.test",
      first_name: "emekli tester",
      last_name: "",
      exam_teams: {
        ["89"]: "oyun2021_testFormEmekli",
      },
    },
  ];

  newUsers.forEach((u) => {
    firestoreDb
      .collection("users")
      .doc(u.id)
      .set(
        {
          ...u,
        },
        { merge: true }
      );
  });
  // let finalistler = [];
  // let i = 1;
  // finalistler.forEach((f) => {
  //   const [id, school_id, sinif] = f;
  //   firestoreDb
  //     .collection("users")
  //     .doc(id.toString())
  //     .get()
  //     .then((doc) => {
  //       let u = doc.data();
  //       console.log("doc: ", u);
  //     });
  //   // .set(
  //   //   {
  //   //     exam_teams: {
  //   //       ["tr_finali_" + sinif]: ("tozos2021_tf_" + sinif + "_"+ school_id) ,
  //   //     },
  //   //   },
  //   //   { merge: true }
  //   // )
  //   // .then(console.log(i++ + "değişti: " + id ));
  // });

  // for (let i = 3; i < 13; i++) {
  //   const email1 = "sinif_" + i + "_kisi_1@halici.com.tr";
  //   const email2 = "sinif_" + i + "_kisi_2@halici.com.tr";
  //   [email1, email2].forEach((mail) => {
  //     firestoreDb
  //       .collection("users")
  //       .where("email", "==", mail)
  //       .get()
  //       .then((ss) => {
  //         const id = ss.docs[0].id;

  //         firestoreDb
  //           .collection("users")
  //           .doc(id)
  //           .set(
  //             {
  //               exam_teams: {
  //                 ["tr_finali_" + i]: "tr_finali_test_form_" + i,
  //               },
  //             },
  //             { merge: true }
  //           )
  //           .then(console.log("değişti: ", i));
  //       });
  //   });
  // }

  // id-isim-email-schoolId-sinif
  // const finaleKalanlar = [];

  //   finaleKalanlar.forEach((k) => {
  //     const sinav_adi = "e"+k[4];
  //     let temp = {};
  //     temp[sinav_adi] = "tozos20bf_" + k[4] + "_" + k[3];

  //     firestoreDb
  //       .collection("users")
  //       .doc(k[0].toString())
  //       .set({
  //         email: k[2],
  //         first_name: k[1],
  //         last_name: "",
  //         exam_teams: {
  //         //  ...temp
  //          place_holder_for_no_exam: "nil"
  //         },
  //       }
  //  );
  //   });
};

const result_isleri = async () => {
  // let allUsers = []; //allResults;
  // let ss = await firestoreDb.collection("user_results").get();

  // for (let i = 0; i < ss.docs.length; i++) {
  //   const u = ss.docs[i];
  //   const uData = u.data()["2023_if"];
  //   let arr = [u.id, uData.conclusion, uData.order_in_country, uData.percentile_in_country, uData.score]
  //   allUsers.push(arr);
  // }

  // console.log("allUSers: ", allUsers);

  // const newArray = allUsers.map((u) => {
  //   return [
  //     u.id,
  //     // u.school_id,
  //     // u.class_id,
  //     // u.if_result.order_in_school,
  //     // u.if_result.score,
  //     // u.if_result.additional_score,
  //     u.if_result.conclusion,
  //     // u.bf_result?.score || "",
  //     // u.bf_result?.additional_score || "",
  //     u.bf_result?.conclusion || "",
  //   ];
  // });

  // let csv = arrayToCsv(newArray);

  // downloadBlob(csv, "export.csv", "text/csv;charset=utf-8;");
  // alert("indi");

  // let bf_ids = [];
  // let bf_school_ids = [];
  // for (let i = 0; i < allUsers.length; i++) {
  //   const u = allUsers[i];

  //   if (u.bf_result) console.log(u.bf_result.conclusion);
  //   if (u.bf_result && u.bf_result.conclusion < 3) {
  //     bf_ids.push(u.id);
  //     bf_school_ids.push(u.school_id);

  //     // const sc_cls = u.school_id + "_" + u.class_id;

  //     // if ([1, 2].includes(tfKatilim[sc_cls])) {
  //     //   console.log(i + " / " + allUsers.length);
  //     //   await firestoreDb
  //     //     .collection("user_results")
  //     //     .doc(u.id)
  //     //     .set(
  //     //       {
  //     //         tf_result: {
  //     //           conclusion: u["bf_result"]["conclusion"],
  //     //         },
  //     //       },
  //     //       { merge: true }
  //     //     );
  //     // }
  //   }
  // }

  // console.log("bf_ids", bf_ids);
  // console.log("bf_school_ids", bf_school_ids);

  //       .collection("users")
  //       .where("email", "==", mail)
  //       .get()
  //       .then((ss) => {
  //         const id = ss.docs[0].id;
  // let i=1;
  //   const tf_katilacaklar = [];
  //   tf_katilacaklar.forEach(id=>{
  //     firestoreDb.collection("results")
  //     .doc(id)
  //     .set({
  //       result_text: "Türkiye Finalleri Sınavına Katılacak"
  //     }, {merge: true}).then(console.log((i++)+" : ", id));
  //   });
  // let sira=1;
  //  //[	48304,12,90.2,902001918,2,8,	"tozos_20-21_bf_12_1",	"tozos_20-21_bf_12_2",	"tozos_20-21_bf_12_3",	"tozos_20-21_bf_12_4",	"tozos_20-21_bf_12_5",	"tozos_20-21_bf_12_6",	"tozos_20-21_bf_9101112_7",	"tozos_20-21_bf_X_8",	"tozos_20-21_bf_X_9",	"tozos_20-21_bf_X_10",	"tozos_20-21_bf_X_11",	"tozos_20-21_bf_X_12",	"tozos_20-21_bf_X_13",	"tozos_20-21_bf_X_14",	"tozos_20-21_bf_X_15",	"tozos_20-21_bf_X_16",	"tozos_20-21_bf_X_17",	"tozos_20-21_bf_X_18",	"tozos_20-21_bf_X_19",	"tozos_20-21_bf_X_20",	"tozos_20-21_bf_X_21",	"tozos_20-21_bf_X_22",	"tozos_20-21_bf_X_23",	"tozos_20-21_bf_X_24",	"tozos_20-21_bf_X_25",	0,0,0,1,0,1,1,1,0,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,19.18	],
  //  //[	909,3,81.58,815802646,,	"tozos_20-21_tf_3_1",	"tozos_20-21_tf_3_2",	"tozos_20-21_tf_3_3",	"tozos_20-21_tf_34_4",	"tozos_20-21_tf_34_5",	"tozos_20-21_tf_34_6",	"tozos_20-21_tf_34_7",	"tozos_20-21_tf_3456789101112_8",	"tozos_20-21_tf_3456789101112_9",	"tozos_20-21_tf_3456789101112_10",	"tozos_20-21_tf_3456789101112_11",	"tozos_20-21_tf_3456789101112_12",	"tozos_20-21_tf_3456789101112_13",	"tozos_20-21_tf_3456789101112_14",	"tozos_20-21_tf_3456789101112_15",	"tozos_20-21_tf_3456789101112_16",	"tozos_20-21_tf_3456789101112_17",	"tozos_20-21_tf_3456789101112_18",	"tozos_20-21_tf_3456789101112_19",	"tozos_20-21_tf_3456789101112_20",	"tozos_20-21_tf_3456789101112_21",	"tozos_20-21_tf_3456789101112_22",	"tozos_20-21_tf_3456789101112_23",	"tozos_20-21_tf_3456789101112_24",	"tozos_20-21_tf_3456789101112_25",	0,0,1,1,1,1,1,1,0,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,26.46	],
  //  //[	21428,3,94.6,946000000,,	"tozos_20-21_tf_3_1",	"tozos_20-21_tf_3_2",	"tozos_20-21_tf_3_3",	"tozos_20-21_tf_34_4",	"tozos_20-21_tf_34_5",	"tozos_20-21_tf_34_6",	"tozos_20-21_tf_34_7",	"tozos_20-21_tf_3456789101112_8",	"tozos_20-21_tf_3456789101112_9",	"tozos_20-21_tf_3456789101112_10",	"tozos_20-21_tf_3456789101112_11",	"tozos_20-21_tf_3456789101112_12",	"tozos_20-21_tf_3456789101112_13",	"tozos_20-21_tf_3456789101112_14",	"tozos_20-21_tf_3456789101112_15",	"tozos_20-21_tf_3456789101112_16",	"tozos_20-21_tf_3456789101112_17",	"tozos_20-21_tf_3456789101112_18",	"tozos_20-21_tf_3456789101112_19",	"tozos_20-21_tf_3456789101112_20",	"tozos_20-21_tf_3456789101112_21",	"tozos_20-21_tf_3456789101112_22",	"tozos_20-21_tf_3456789101112_23",	"tozos_20-21_tf_3456789101112_24",	"tozos_20-21_tf_3456789101112_25",	1,1,0,1,1,1,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0	],
  //  trFinaliSonuclari.forEach((d) => {
  //   const school_id = d[0];
  //   const sinif = d[1];
  //   const form_id =
  //     "tozos2021_tf_" + sinif + "_" + school_id + "_tr_finali_" + sinif; // tozos_20-21_bf_10_10377_bolge_finali_10
  //   const total_score = d[2];
  //   const additional_score = d[55];
  //   //const order_in_country = d[5];
  //   //const order_in_region = d[4];
  //   const questions = {};
  //   for (let i = 0; i < 25; i++) {
  //     const q_uid = d[i + 5];
  //     const val = d[i + 30];
  //     questions[q_uid] = val;
  //   }
  //   const obje = {
  //     form_id,
  //     additional_score,
  //     total_score,
  //     //order_in_country,
  //     //order_in_region,
  //     questions,
  //   };
  //   firestoreDb
  //     .collection("results")
  //     .doc(form_id)
  //     .set(obje)
  //     .then((_) => console.log("sira: ", sira++));
  // });

  // for (let i = 0; i < TEMP.length; i++) {
  //   const [user_id, school_id, res] = TEMP[i];
  //   await firestoreDb
  //     .collection("user_results")
  //     .doc(user_id.toString())
  //     .set({ school_id, il_finali_result: res });
  //   console.log(i + "/" + TEMP.length);

  // }

  //  IF_RESULT_FORMS
  // BF_RESULTS

  // for (let i = 0; i < IF_RESULT_FORMS.length; i++) {
  //   const if_res = IF_RESULT_FORMS[i];

  // }

  // BF_RESULTS ----------------- OBJE
  // "87604_3": [
  //   111.709844559585,
  //   137.512953367876,
  //   1
  // ],

  // IF_RESULTS ------------------- ARRAY
  // {
  //   "class_id": 7,
  //   "il_finali_result": 1,
  //   "school_id": 51174,
  //   "if_result": {
  //       "order_in_school": 3,
  //       "additional_score": 109.226110363392,
  //       "conclusion": 1,
  //       "score": 70.7604306864065
  //   },
  //   "id": "31169"
  // },

  // let guncellenecek_formlar = [];

  // for (let i = 0; i < IF_RESULT_FORMS.length; i++) {
  //   const if_res = IF_RESULT_FORMS[i];
  //   const sch_cls = if_res.school_id + "_" + if_res.class_id;

  //   if (if_res.if_result?.conclusion) {
  //     const if_asil_mi = if_res.if_result.conclusion === 1;
  //     const if_yedek_mi = if_res.if_result.conclusion === 2;

  //     const bf_res = BF_RESULTS[sch_cls];

  //     if (bf_res && (if_asil_mi || if_yedek_mi)) {
  //       // Sadece bu kullanıcının bilgilerini güncelleyeceğiz.

  //       let update_object = { ...if_res };
  //       delete update_object.il_finali_result;

  //       const [bf_score, bf_add_score, bf_conclusion] = bf_res;
  //       update_object.bf_result = {
  //         score: bf_score,
  //         additional_score: bf_add_score
  //       };

  //       if (if_asil_mi && bf_conclusion === 1) update_object.bf_result.conclusion = 1;
  //       else if (if_yedek_mi && bf_conclusion === 1) update_object.bf_result.conclusion = 2;
  //       else if (bf_conclusion === 3) update_object.bf_result.conclusion = 3;

  //       guncellenecek_formlar.push(update_object);

  //     }

  //   }
  //   else {
  //     // console.log("conclusion olmayan res", if_res);
  //   }

  // }

  // let hatalilar = [];

  // // for (let i = 0; i < guncellenecek_formlar.length; i++) {
  // //   const f = guncellenecek_formlar[i];
  let str = "";

  // for (let i = 0; i < firebase_data_x.length; i++) {
  // // for (let i = 0; i < 2; i++) {
  //   const row = firebase_data_x[i];
  //   const [
  //     user_id,
  //     conclusion,
  //     order,
  //     percent,
  //     score
  // ]= row;

  // str += "(" + user_id + ", " + conclusion + ", " + order + ", " + percent + ", " + score + ", '2023_if', now(), now()),";

  // }

  let csv = arrayToCsv(firebase_data_x);

  downloadBlob(csv, "export.csv", "text/csv;charset=utf-8;");

  console.log("*************", str);
  // console.log("hatalilar:", hatalilar);
};

function arrayToCsv(data) {
  return data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => `"${v}"`) // quote it
          .join(",") // comma-separated
    )
    .join("\r\n"); // rows starting on new lines
}

function downloadBlob(content, filename, contentType) {
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
}
