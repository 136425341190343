import React from "react";
import classNames from "./contact.module.css";
import Typography from "../../components/ui/typography";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";

const Contact = () => (
  <div className={"card " + classNames.contact}>
    <Typography font="merel" size="normal" weight="bold" color="orange">
      İletişim
    </Typography>
    <img
      className={classNames.logo}
      src="/assets/images/tzv-logo-white.png"
      alt="Logo white"
    />
    <Typography font="merel" size="normal" weight="bold" color="orange">
      <a className="orange" href="mailto:sampiyona@tzv.org.tr">
        sampiyona@tzv.org.tr
      </a>
    </Typography>
    {/* <Typography font="merel" color="grey" weight="bold" className={classNames.phone}>0 312 210 00 20</Typography> */}
    <a href="https://www.tzv.org.tr" target="_blank" rel="noopener noreferrer">
      <Typography font="merel" color="orange" weight="bold" size="smaller">
        www.tzv.org.tr
      </Typography>
    </a>
    <a href="https://www.tzv.org.tr" target="_blank" rel="noopener noreferrer">
      <Typography font="merel" color="orange" weight="bold" size="smaller">
     0312 210 00 20
      </Typography>
    </a>
    <Typography
      size="smaller"
      weight="normal"
      color="white"
      className={classNames.address}
    >
      Üniversiteler Mah. İhsan Doğramacı Bulvarı No:33 Halıcı Yazılımevi
    </Typography>
    <Typography size="smaller" weight="normal" color="white">
      06800 ODTÜ/Teknokent ANKARA
    </Typography>
    <ScrollToTop />
  </div>
);

export default Contact;
