import React, { useState, useCallback, useEffect } from "react";
import { Redirect } from "react-router-dom";
import classNames from "./payment.module.css";
import Typography from "../../components/ui/typography";
// import Button from "../../components/ui/button";
import { useDispatch, useSelector } from "react-redux";

import { checkPurchaseWithUserId } from "../../store/actions/auth";
import { CHECK_PURCHASE_WITH_USER_ID } from "../../store/actionTypes";

import ScrollToTop from "../../components/scrollToTop/scrollToTop";
// import Loading from "../../components/ui/loading/loading";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import MaterialBackDrop from "../../components/ui/loading/materialBackDrop";

const PaymentResult = (props) => {
  const user = useSelector((state) => state.auth && state.auth.user);
  const dispatch = useDispatch();
  const loadingSomething = useSelector(
    (state) => state.loading[CHECK_PURCHASE_WITH_USER_ID]
  );

  const applied_products = useSelector(
    (state) => state.auth.user && state.auth.user.applied_products
  );

  useEffect(() => {
    if (user && user.id) {
      if (user.role === 0) dispatch(checkPurchaseWithUserId(user.id));
    } else alert("Lütfen oturum açınız.");
  }, []);

  const paymentSuccessfull = applied_products && applied_products.length > 0;

  const successText = (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography size="normal" color="orange" font="merel">
          ÖDEME BAŞARILI
        </Typography>
        <CheckCircleOutlineIcon
          style={{ color: "white", marginLeft: "20px", fontSize: "1.9rem" }}
        />
      </div>

      <Typography size="normal" color="orange" font="merel">
        Takvimde belirtilen tarihte İl Finalleri Sınavında görüşmek üzere..!
        <br />
        Profil sayfanıza ulaşmak için{" "}
        <a href="https://sampiyona.tzv.org.tr/yonetim" target="_self">
          tıklayınız.
        </a>
      </Typography>
    </div>
  );

  const failText = (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography size="normal" color="orange" font="merel">
          ÖDEME BAŞARISIZ
        </Typography>
        <HighlightOffIcon
          style={{ color: "white", marginLeft: "20px", fontSize: "1.9rem" }}
        />
      </div>

      <Typography size="normal" color="orange" font="merel">
        Ödeme sayfasına tekrar gitmek için{" "}
        <a href="https://sampiyona.tzv.org.tr/satinal" target="_self">
          tıklayınız.
        </a>
      </Typography>
    </div>
  );

  if (user && user.role === 1) return <Redirect to="/yonetim" />;

  return (
    <div style={{ marginTop: "120px" }}>
      {loadingSomething && <MaterialBackDrop />}
      {!loadingSomething && (
        <div className={"card " + classNames.payment}>
          {paymentSuccessfull ? successText : failText}
        </div>
      )}

      <ScrollToTop />
    </div>
  );
};

export default PaymentResult;
