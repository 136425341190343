import React from "react";
import Carousel from "react-slick";
import classNames from "./style.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Arrow = ({ className, left, ...props }) => (
  <div
    className={[
      className,
      classNames.arrow,
      left ? classNames.left : classNames.right,
    ].join(" ")}
    {...props}
  />
);

const config = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <Arrow />,
  prevArrow: <Arrow left />,
};

const CustomCarousel = ({ slides, ...props }) => (
  <div className={classNames.container}>
    <div className={[classNames.carousel_container, "card"].join(" ")}>
      <Carousel
        {...config}
        dotsClass={["slick-dots", classNames.dots].join(" ")}
        ar
      >
        {slides}
      </Carousel>
    </div>
    {/* <FontAwesomeIcon icon="chevron-right" size="2x" color="grey" /> */}
  </div>
);

export default CustomCarousel;
