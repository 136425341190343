import React from "react";
import Typography from "../typography";
import classNames from "./label.module.css";

const Label = ({ label, warning, children, color, className }) => (
  <label className={classNames.label}>
    <Typography
      color={color || "white"}
      font="tadao"
      size="smaller"
      weight="normal"
    >
      {label}
      <span className={"grey fw_light " + classNames.warning}>
        {warning}
      </span>{" "}
    </Typography>
    {children}
  </label>
);

export default Label;
