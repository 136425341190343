import React, { useState } from "react";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Typography from "../../components/ui/typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import classNames from "./style.module.css";

const topButtonsValues = [
  { text: "Basın Bülteni", value: 0 },
  { text: "Derece Alan Okullar", value: 1 },
  // { text: "Fotoğraf Galerisi", value: 2 },
  // { text: "BASINDA BİZ", value: 3 },
];

const Year2020_2021 = () => {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const topButtons = (
    <ButtonGroup
      size="large"
      color="secondary"
      aria-label="large outlined primary button group"
    >
      {topButtonsValues.map((b) => {
        return (
          <Button
            onClick={() => setSelectedMenu(b.value)}
            key={b.value + "topButton"}
            style={{ color: "white", fontWeight: 200 }}
          >
            {b.text}
          </Button>
        );
      })}
    </ButtonGroup>
  );

  const banner = (
    <div className={classNames.logo_wide}>
      <img src="/assets/images/2020/banner2020.png" alt="home-logo" />
    </div>
  );

  const resultVideo = (
    <div className={classNames.sponsors}>
      <Typography size="normal" color="orange" font="merel">
        2020 Türkiye Okullar Arası Zeka Oyunları Şampiyonası Sonuçları  
      </Typography>
      <Typography
        size="small"
        color="white"
        font="tadao"
        weight="normal"
        className={classNames.description}
      >
        Pandemi nedeniyle ödül töreni çevrim içi olarak gerçekleştirildi.
        Törende Millî Eğitim Bakanı Prof. Dr. Ziya Selçuk, Türkiye Zeka Vakfı
        Başkanı Emrehan Halıcı ve Türkiye Zeka Vakfı Yönetim Kurulu Üyesi Dr.
        Şeref Oğuz'un tebrik mesajları ile beraber aşağıdaki video
        yayınlanmıştır. Dereceye giren okulların ödüllerinden olan kupa, madalya
        ve diğer belgeleri sorumlu öğretmenlerle iletişime geçilerek
        kargolanmıştır. Tüm okulları, takım üyelerini ve sorumlu öğretmenleri
        tebrik ederiz.
      </Typography>
      <div className={"card " + classNames.sponsor_card}>
        <iframe
          width="560"
          height="315"
          title=""
          src="https://www.youtube.com/embed/moMy0SyqKWQ"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );

  const basinBulteni = (
    <div className={"card " + classNames.long_description}>
      <p>
        <b>
          “2020-2021 Türkiye Okullar Arası Zeka Oyunları Şampiyonası”nın
          Sonuçları İlan Edildi
        </b>
      </p>
      <p>
        Türkiye Zeka Vakfı tarafından düzenlenen; Milli Eğitim Bakanlığı ve
        TÜBİTAK’ın desteklediği “Türkiye
        Okullar Arası Zeka Oyunları Şampiyonası” pandemi koşullarına uygun
        olarak çevirim içi ortamda gerçekleştirildi. "Şampiyona 2020"nin
        sonuçları 7 Nisan 2021 tarihinde ilan edildi.
      </p>
      <p>
        <b>"Şampiyona 2020" İçin Sanal Ödül Töreni Düzenlendi</b>
      </p>
      <p>
        COVID-2019 Pandemisi nedeniyle Şampiyona sonuçlarının ilan edileceği
        Ödül Töreni, çevrim içi platformda düzenlendi. Tören, Milli Eğitim
        Bakanı Sayın Ziya Selçuk’un konuşması ile başladı.
      </p>
      <p>
        Bakan Selçuk konuşmasında zekâ oyunlarının öneminden bahsederek,
        Türkiye’nin dört bir yanından Şampiyona’ya katılımın olmasından, zekâ
        oyunlarının, beyin sporlarının kıymetinin her geçen gün daha arttığını
        görmekten büyük mutluluk duyduğunu belirtti. Zekâ oyunlarındaki
        gayretler gibi sosyal ve duygusal gelişimin, etik değerlere sahip
        olmanın da önemli olduğunu; yetenekler ışığında yükselen başarının
        bunların bütününden ortaya çıktığını belirtti.
      </p>
      <p>
        Türkiye Zeka Vakfı Başkanı Emrehan Halıcı konuşmasında; yarışmaya
        katılan ve derece elde eden tüm katılımcıları tebrik etti. Halıcı,
        pandeminin dayanışmanın önemini hatırlattığından bahsederek; sadece
        zorluklarda ve problemlerde değil dünyanın varlıklarını ve
        güzelliklerini paylaşırken de dayanışma içerisinde olunması gerektiğini,
        eşit ve adil davranılması gerektiğini belirtti.
      </p>
      <p>
        Türkiye Zeka Vakfı Yönetim Kurulu Üyesi Dr. Şeref Oğuz konuşmasında;
        ülkenin en değerli sermayesinin zeki ve yetenekli kişiler olduğundan
        bahsederek vakfın zeka ve yeteneği Türkiye gündemine getirmek için
        yarışmalar, kongreler gibi etkinliklerle bir gelenek başlattığından
        bahsetti. Oğuz, yarışmaya katılan kişilerin vakfın başlattığı bu
        geleneği sürdürmesini, zekâyı ve yeteneği gündemde tutmaları gerektiğini
        belirtti.
      </p>
      <p>
        <b>
          Sanal Ödül Töreninde Türkiye’nin Her Bölgesinden Yarışmaya Katılan
          Okullardan Dereceye Girenler Açıklandı.
        </b>
      </p>
      <p>
        Şampiyona sonunda her sınıfta ilk üç dereceye giren okullara kupa,
        öğrencilerine ve öğretmenlerine madalya verildi.
      </p>
      <p>
        <b>İşte yarışmada sınıf bazında ödül alan okullar!</b>
      </p>
    </div>
  );

  const generateMedal = ({
    medalType,
    schoolLogoPath,
    schoolName,
    studentList,
    tutorList,
  }) => {
    const silverMedal = (
      <img src="/assets/images/medals/silver.png" alt="silver" />
    );
    const goldMedal = <img src="/assets/images/medals/gold.png" alt="gold" />;
    const bronzeMedal = (
      <img src="/assets/images/medals/bronze.png" alt="bronze" />
    );

    const medalImage =
      medalType === "gold"
        ? goldMedal
        : medalType === "silver"
        ? silverMedal
        : bronzeMedal;

    const schoolLogoImage = (
      <div>
        <img
          src={schoolLogoPath ? schoolLogoPath : "/assets/images/meb-logo.png"}
          alt="home-logo"
          width="120"
          height="120"
        />
      </div>
    );

    const schoolNameSpan = (
      <div
        style={{
          marginTop: 30,
          fontSize: "24px",
          fontWeight: "bolder",
          color: "#15273A",
        }}
      >
        {schoolName}
      </div>
    );

    const studentListBox = (
      <div style={{ marginTop: 15 }}>
        <div style={{ fontSize: "24px", color: "#E79121" }}>Takım</div>
        {studentList.map((s) => (
          <div style={{ fontSize: "20px" }}>{s}</div>
        ))}
      </div>
    );

    const tutorListBox = (
      <div style={{ marginTop: 15 }}>
        <div style={{ fontSize: "24px", color: "#E79121" }}>
          Sorumlu Öğretmen
        </div>
        {tutorList.map((t) => (
          <div style={{ fontSize: "20px" }}>{t}</div>
        ))}
      </div>
    );
    return (
      <div
        style={{
          borderRadius: "15px",
          position: "relative",
          width: 339,
          height: 633,
          textAlign: "center",
          lineHeight: 1.1,
          margin: 10,
        }}
      >
        {medalImage}
        <div
          style={{
            position: "absolute",
            top: 150,
            textAlign: "center",
            width: 338,
            paddingLeft: 27,
            paddingRight: 27,
          }}
        >
          {schoolLogoImage}
          {schoolNameSpan}
          {studentListBox}
          {tutorListBox}
        </div>
      </div>
    );
  };

  const generateMedalGroup = ({
    groupName,
    goldMedal,
    silverMedal,
    bronzeMedal,
  }) => {
    const header = (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "42px",
          paddingBottom: 20,
          borderBottom: "solid #DA571A 2px",
          marginBottom: 20,
          lineHeight: 1,
        }}
      >
        <span style={{ color: "#DA571A", textAlign: "left" }}>
          Türkiye Finalleri Sonuçları
        </span>
        <span style={{ color: "#15273A", fontWeight: "bold", marginLeft: 30 }}>
          {groupName}
        </span>
      </div>
    );

    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 15,
          padding: 20,
          maxWidth: 1200,
          margin: 20,
        }}
      >
        {header}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {goldMedal}
          {silverMedal}
          {bronzeMedal}
        </div>
      </div>
    );
  };

  //ÜÇÜNCÜ SINIF
  const ucuncuSinifMedals = generateMedalGroup({
    groupName: "Üçüncü Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "/assets/images/2020/antalya_koleji.png",
      schoolName: "Özel Antalya Koleji Muratpaşa İlkokulu",
      studentList: ["Mehmet Gökmen Hergül", "İpek Su Çelik", "Altay Erbaş"],
      tutorList: ["Cansu Ayar"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "/assets/images/2020/ulker_yorukoglu.png",
      schoolName: "Ülker Yörükoğlu Bilim Ve Sanat Merkezi",
      studentList: [
        "Elif Naz Oğur",
        "Ravzanur Sinav",
        "Yağız Emir Sarıaydın",
        "Mustafa Yılmaz",
        "Emir Bulut",
        "Burak Bulut",
      ],
      tutorList: ["Kadir Alper Çetin"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "",
      schoolName: "Avcılar Bilim ve Sanat Merkezi",
      studentList: [
        "Barbaros Piroğlu",
        "Zeynep Duru Köroğlu",
        "Kuzey Güral",
        "Sümeyye Sağlık",
        "Aras Boran Ateş",
        "Emir Önal",
      ],
      tutorList: ["Didem Koloğlu, Mustafa Ünel", "Merve Özdemir"],
    }),
  });

  ///DÖRDÜNCÜ SINIF
  const dorduncuSinifMedals = generateMedalGroup({
    groupName: "Dördüncü Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "Toki Madenler Mevlana İlkokulu",
      studentList: [
        "Elif Nur Kayaoğlu",
        "Ahsen Ela Ağca",
        "Güneş Alya Karataş",
        "Ömer Çetinkaya",
        "Selin Erdem",
        "Raşid Harun Bağcı",
      ],
      tutorList: ["Hale Duman Ölmez"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "/assets/images/2020/TED_logo_seffaf.png",
      schoolName: "Ted Ankara Koleji Vakfı Özel İlkokulu",
      studentList: [
        "Arda Tunay Çelik",
        "İdil Geçer",
        "İlke Çağla Çabuk",
        "Lara Tercan",
        "Zeynep Akkocaoğlu",
        "İrem Güngör",
      ],
      tutorList: ["Caner Uluer"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "",
      schoolName: "Akçaalan İlkokulu",
      studentList: [
        "Görkem Kır",
        "Cemre Bozkurt",
        "Hüseyin Uras Kaya",
        "Arif Ege Okudan",
        "Mete Yağız Can",
      ],
      tutorList: ["Öner Kaya"],
    }),
  });

  //// BEŞİNCİ SINIF
  const besinciSinifMedals = generateMedalGroup({
    groupName: "Beşinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "/assets/images/2020/logo-bahcesehir-koleji.png",
      schoolName: "Özel Adana Bahçeşehir Ortaokulu",
      studentList: ["Yusuf Halid Göçer"],
      tutorList: ["Veli Burak Yıldıran"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "Çetin Şen Bilim ve Sanat Merkezi",
      studentList: [
        "Ege İnan",
        "Umut Ezgintürk",
        "Ömer Kaan Türeli",
        "Ece Taşkan",
        "Arda Ege Sonu",
        "Rüveyda Çinçik",
      ],
      tutorList: [
        "Şebnem Boyraz, Serhat Albayrak",
        "Hilal Boyraz, Eray Demirçelik",
      ],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "/assets/images/2020/odtu_gelistirme.png",
      schoolName: "Odtü Geliştirme Vakfı Özel Ortaokulu",
      studentList: [
        "Ege Doruk Ergez",
        "Abdulkadir Tural",
        "Umut Ege Var",
        "Ata Bayram",
        "Beray Gürses",
        "Efe Demir KAYA",
      ],
      tutorList: ["Burcu Arıcan"],
    }),
  });

  //ALTINCI SINIF
  const altinciSinifMedals = generateMedalGroup({
    groupName: "Altıncı Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "/assets/images/2020/pema.jpg",
      schoolName: "Özel Profesyonel Eğitim Ortaokulu",
      studentList: [
        "Yavuz Mete KARAGÜL",
        "Zeliha Belinay Mutlusoy",
        "Arda Korkmaz",
        "Ayşe Beyza Şarman",
        "Burak Duksal",
        "Tarık Tufan Aker",
      ],
      tutorList: ["Mustafa Ok"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "/assets/images/2020/bafra_cozum.jpg",
      schoolName: "Özel Bafra Çözüm Koleji Ortaokulu",
      studentList: ["Ozan Öztürk", "Kuzey Yazıcı", "Doğukan Çırak"],
      tutorList: ["Murat Semiz"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "/assets/images/2020/yontem_koleji.jpg",
      schoolName: "Özel Selçuklu Yöntem Ortaokulu",
      studentList: [
        "Göksu Aksoylu",
        "Alperen Çelik",
        "Kerem Tekin",
        "Ahmet Yiğit Çiftçi",
        "Kaan Tanrıkulu",
        "Elif Sezen Kader",
      ],
      tutorList: ["Umut Hudayberdiyev"],
    }),
  });

  //YEDİNCİ SINIF
  const yedinciSinifMedals = generateMedalGroup({
    groupName: "Yedinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "Yüksel Bahadır Alaylı Bilim ve Sanat Merkezi",
      studentList: [
        "Ümmühani Zümra Yıldız",
        "Zahide Zeren Yıldız",
        "Zeynep Ceren Ayrancı",
        "Özge Evcil",
        "Tevfik Fikret Kont",
        "Demir Güçbilmez",
      ],
      tutorList: ["Özden Ekinci, Nalan Tolğay", "Çağrı Kemal Erdal"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "Mehmetçik Ortaokulu",
      studentList: [
        "Elif Ecrin Turan",
        "Serhat Benk",
        "Mehmet Efe Okumuş",
        "Mehmed Taha Hamza",
      ],
      tutorList: ["Emine Atabay"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "",
      schoolName: "Kartal Bilim ve Sanat Merkezi",
      studentList: [
        "İhsan Özdem",
        "Ali Yüksel Şenel",
        "Fatma Zehra Yıldız",
        "Gökçe Deniz Arık",
      ],
      tutorList: ["Selçuk Acun"],
    }),
  });

  //SEKİZİNCİ SINIF
  const sekizinciSinifMedals = generateMedalGroup({
    groupName: "Sekizinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "Hayırseverler Ortaokulu",
      studentList: [
        "Zeynep Deren Gazioğlu",
        "Alper Bacanlı",
        "Bengisu Erten",
        "Ali Çağan Armağan",
        "Elif Mutluay",
        "İpek Öztin",
      ],
      tutorList: ["Melis Bekcan"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "Alpaslan Ali Can Bilim ve Sanat Merkezi",
      studentList: ["Eylül Nisa Çetiner", "Öykü Gümüştaş", "Beyza Oğuz"],
      tutorList: ["Tuğba Tetik", "Aybetül Türken", "Ayşe Şimşek"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "/assets/images/2020/pema.jpg",
      schoolName: "Özel Profesyonel Eğitim Ortaokulu",
      studentList: [
        "Yiğit Altınöz",
        "Begüm Öztürk",
        "Tahsin Sağlam",
        "Defne Askerzade",
        "Özge Alp",
        "Asude Berra Boztaş",
      ],
      tutorList: ["Mustafa Ok"],
    }),
  });

  //DOKUZUNCU SINIF
  const dokuzuncuSinifMedals = generateMedalGroup({
    groupName: "Dokuzuncu Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "/assets/images/2020/TED_logo_seffaf.png",
      schoolName: "Ted Ankara Koleji Vakfı Özel Lisesi",
      studentList: [
        "Nazlı Aykan",
        "Cenk Kuleoğlu",
        "İrem Alemdar",
        "Selin Su Altıntaş",
        "Dere Arat",
        "Zeynep Azra Şahin",
      ],
      tutorList: ["Eser Yapıcı"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "/assets/images/2020/enka.png",
      schoolName: "Özel Enka Lisesi",
      studentList: [
        "Barış Koyuncu",
        "Kerem Yıldız",
        "Mehmet Yıldız",
        "Kerem Üşümez",
        "Ayşe Akbal",
        "Arda Tuğasaygı",
      ],
      tutorList: ["Gafur Taşkın"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "/assets/images/2020/yontem_koleji.jpg",
      schoolName: "Özel Selçuklu Yöntem Fen Lisesi",
      studentList: [
        "Yusuf Ökçe",
        "Fatma Neslihan Çallı",
        "Ruken Seza Şahin",
        "Ayşe Nazlı Arslan",
      ],
      tutorList: ["Umut Hudayberdiyev"],
    }),
  });

  //ONUNCU SINIF
  const onuncuSinifMedals = generateMedalGroup({
    groupName: "Onuncu Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "Kartal Anadolu İmam Hatip Lisesi",
      studentList: [
        "Burak Emre Polat",
        "Muhammed Yusuf Kartal",
        "Ahmet Eren Gözübenli",
        "Fatmagül Ersöz",
        "Meliha Kara",
        "Gizem Kanca",
      ],
      tutorList: ["Emine Akkaş"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "Cağaloğlu Anadolu Lisesi",
      studentList: [
        "Alperen Selçok",
        "Kaya Karaman",
        "Arhan Alp",
        "Çınar Ekin Algül",
      ],
      tutorList: ["Adnan Şimşek", "Mohammad Sediq Sadıq"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "/assets/images/2020/TED_logo_seffaf.png",
      schoolName: "Ted Ankara Koleji Vakfı Özel Lisesi",
      studentList: ["Başar Ozan Zorba", "Nehir Kavak", "Berke Tigin Kaygın"],
      tutorList: ["Eser Yapıcı"],
    }),
  });

  //ONBİRİNCİ SINIF
  const onbirinciSinifMedals = generateMedalGroup({
    groupName: "On Birinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "Maltepe Fen Lisesi",
      studentList: ["Tarık Yıldız", "Onur Alp Güngör"],
      tutorList: ["Mehmet Yürükcü"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "/assets/images/2020/buyuk_kolej.jpg",
      schoolName: "Özel Büyük Fen Lisesi",
      studentList: ["Defne İşimtekin", "Çınar Kanbur", "Eylül Güler"],
      tutorList: ["Nazmiye Bozdağ"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "",
      schoolName: "Atatürk Anadolu Lisesi",
      studentList: ["Tunahan Turgut", "Tuna Akmehmet", "Hilal Gökçen Eren"],
      tutorList: ["Turan Özkan"],
    }),
  });

  //ONİKİNCİ SINIF
  const onikinciSinifMedals = generateMedalGroup({
    groupName: "On İkinci Sınıf",
    goldMedal: generateMedal({
      medalType: "gold",
      schoolLogoPath: "",
      schoolName: "Maltepe Fen Lisesi",
      studentList: ["Ezgi Ergüney", "Berre Şengül"],
      tutorList: ["Mehmet Yürükcü"],
    }),
    silverMedal: generateMedal({
      medalType: "silver",
      schoolLogoPath: "",
      schoolName: "Şehit Özcan Kan Fen Lisesi",
      studentList: ["Burhan Karaduman", "Gamze Burcu Özer", "Bilal Yıldız"],
      tutorList: ["Orkun Lüleci"],
    }),
    bronzeMedal: generateMedal({
      medalType: "bronze",
      schoolLogoPath: "/assets/images/2020/ozel-eyuboglu-koleji.png",
      schoolName: "Özel Eyüboğlu Koleji",
      studentList: ["Kerem Adem"],
      tutorList: ["İsmet Baykal"],
    }),
  });

  const bulten = (
    <div>
      {banner}
      {resultVideo}
      {basinBulteni}
    </div>
  );

  const hallOfFame = (
    <div>
      {ucuncuSinifMedals}
      {dorduncuSinifMedals}
      {besinciSinifMedals}
      {altinciSinifMedals}
      {yedinciSinifMedals}
      {sekizinciSinifMedals}
      {dokuzuncuSinifMedals}
      {onuncuSinifMedals}
      {onbirinciSinifMedals}
      {onikinciSinifMedals}
    </div>
  );

  const gallery = <div>GALERİ</div>;

  return (
    <div className={classNames.pageContainer}>
      {topButtons}
      {selectedMenu === 0 && bulten}
      {selectedMenu === 1 && hallOfFame}
      {selectedMenu === 2 && gallery}
      <ScrollToTop />
    </div>
  );
};

export default Year2020_2021;
