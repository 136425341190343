import React from "react";
import Typography from "../../../../components/ui/typography";
import classNames from "./style.module.css";

const Slide = ({ date, title, linkTitle, text, url, ...props }) => (
  <div className={classNames.slide_custom}>
    <Typography size="smaller" color="grey" style={{ textAlign: "right" }}>
      {date}
    </Typography>
    <Typography color="orange" size="small">
      {title}
    </Typography>
    <Typography size="smaller" color="white" className={classNames.slide_text}>
      {text}{" "}
    </Typography>
    {url ? (
      <a target="_blank" href={url}>
        <Typography size="smallest" color="orange">
          {linkTitle ?? "Detaylı bilgi için tıklayınız."}
        </Typography>
      </a>
    ) : null}
  </div>
);

export default Slide;
