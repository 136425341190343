import React, { useCallback, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/home/home";
import Login from "./containers/login/login";
import Layout from "./components/layout/layout";

import { useDispatch, useSelector } from "react-redux";
import { validateToken } from "./store/actions/auth";
import IndividualSignUp from "./containers/signup/individualSignup";
import individualSignupTemp from "./containers/signup/individualSignupTemp";
import Payment from "./containers/payment/payment";
import Council from "./containers/council/council";
import Contact from "./containers/contact/contact";
import Help from "./containers/help/help";
import Manage from "./containers/manage/manage";
import Steps from "./containers/steps/steps";
import Admin from "./containers/admin/admin";
import Exam from "./containers/exam/exam";
import Year2019_2020 from "./containers/pastYearsPages/year2019_2020";
import Year2020_2021 from "./containers/pastYearsPages/year2020_2021";
import Year2021_2022 from "./containers/pastYearsPages/year2021_2022";
import Year2022_2023 from "./containers/pastYearsPages/year2022_2023";
import Year2023_2024 from "./containers/pastYearsPages/year2023_2024";
import DetailedUpdates from "./containers/home/sections/updates/detailedUpdates";
import PaymentResult from "./containers/payment/paymentResult";
import Results from "./containers/results/results";
import ExternalExam from "./containers/exam/externalExam";
import CoachSignup from "./containers/signup/coachSignUp";
import Documents from "./containers/documents/documents";

const App = () => {
  const dispatch = useDispatch();

  const validate = useCallback(() => dispatch(validateToken()), [dispatch]);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
     if (user) validate();
    //console.log("validating");
  }, []);

  return (
    <Layout>
      <Switch>
        <Route exact path="/giris" component={Login} />
        {/* <Route exact path="/kayit" component={SignUp} /> */}
        <Route exact path="/kayit" component={IndividualSignUp} />
        <Route exact path="/coachsignup" component={CoachSignup} />
        <Route exact path="/kayit_asdfgh" component={individualSignupTemp} />
        <Route exact path="/results" component={Results} />
        <Route exact path="/payment_result" component={PaymentResult} />

        <Route exact path="/updates" component={DetailedUpdates} />

        <Route exact path="/exam/:examId" component={Exam} />
        <Route
          exact
          path="/oyun2021/:examId/:userToken"
          component={ExternalExam}
        />

        <Route exact path="/2023" component={Year2023_2024} />
        <Route exact path="/2022" component={Year2022_2023} />
        <Route exact path="/2021" component={Year2021_2022} />
        <Route exact path="/2020" component={Year2020_2021} />
        <Route exact path="/2019" component={Year2019_2020} />

        <Route exact path="/admin" component={Admin} />

        <Route exact path="/sifre-hatirlat" component={Login} />
        <Route exact path="/sifre-yenile" component={Login} />
        <Route exact path="/satinal" component={Payment} />
        <Route exact path="/kurul" component={Council} />
        <Route exact path="/asamalar" component={Steps} />
        <Route exact path="/iletisim" component={Contact} />
        <Route exact path="/yardim" component={Help} />
        <Route exact path="/yonetim" component={Manage} />
        <Route exact path="/belgelerim" component={Documents} />

        <Route path="/source/:source_name" component={Home} />
        <Route path="/" component={Home} />
        {/* <Route path="/" component={Year2023_2024} /> */}
      </Switch>
    </Layout>
  );
};

export default App;
