import { updateUserById } from "../store/actions/generic";

export default {
  signIn: "/auth/sign_in",
  signOut: "/auth/sign_out",
  validate: "/auth/validate_token",
  auth: "/auth",
  purchase: "products/purchase",
  pariticipants: "participants/create_multiple_participants",
  password: "/auth/password",
  mailStats: "/participants/mail_stats",
  getTest: "/test/get_test",
  getDistrictSchools: "/schools/get_district_schools",
  addSchool: "/schools/add_school",
  disclaim: "/users/disclaim",
  getFilteredUsers: "/users/get_filtered_users",
  getStats: "/users/get_stats",
  getUserById: "/users/get_user_by_id",
  updateUserById: "/users/update_user_by_id",
  deleteUser: "users/delete_user",
  checkPurchaseWithUserId: "users/check_purchase_with_user_id",
  createAPaidReceipt: "receipts/create_a_paid_receipt",
  cancelAPaidReceipt: "receipts/cancel_a_paid_receipt",
};
