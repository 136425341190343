import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "./login.module.css";
import { Link } from "react-router-dom";
import {
  signIn as signInAction,
  forgetPassword as forgetPasswordAction,
  rescuePassword,
} from "../../store/actions/auth";
import Typography from "../../components/ui/typography";
import Input from "../../components/ui/input/input";
import Button from "../../components/ui/button";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import { SIGN_IN } from "../../store/actionTypes";
import Loading from "../../components/ui/loading/loading";
import Second from "../manage/steps/second";

const types = {
  login: "/giris",
  forget: "/sifre-hatirlat",
  change: "/sifre-yenile",
};

const Login = (props) => {
  const [params, setParams] = useState({});
  const { history } = props;

  useEffect(() => {
    setType(history.location.pathname);
  }, [history.location.pathname]);

  useEffect(() => {
    const sParams = new URLSearchParams(
      props.history.location.search.substring(1)
    );
    const keys = ["uid", "access-token", "client"];
    const headers = keys.reduce((acc, key) => {
      acc[key] = sParams.get(key);
      return acc;
    }, {});
    if (keys.every((key) => headers[key])) {
      setType(types.change);
      setParams(headers);
    }
  }, []);

  const [type, setType] = useState(types.login);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const loading = useSelector((state) => state.loading[SIGN_IN]);
  const dispatch = useDispatch();

  const signIn = useCallback(
    () => dispatch(signInAction({ email, password })),
    [email, password, dispatch]
  );

  const forget = useCallback(
    () =>
      dispatch(forgetPasswordAction({ email })).then(() => {
        alert(
          "10 dakika içerisinde yazdığınız e-posta adresine yeni şifre belirlemeniz için bir link gönderilecektir. Lütfen e-posta kutunuzu kontrol ediniz."
        );
        window.open("https://sampiyona.tzv.org.tr/giris", "_self");
      }),
    [dispatch, email]
  );

  const changePassword = useCallback(
    () => dispatch(rescuePassword(params, password, passwordConfirm)),
    [dispatch, params, password, passwordConfirm]
  );

  const onSignIn = (e) => {
    e.preventDefault();

    if (type === types.forget) forget();
    else if (type === types.login)
      signIn().then((success) => {
        if (success) props.history.push("/yonetim");
      });
    else {
      // şifre yenileme ekranı:
      if (password.length < 6) alert("Şifre en az 6 karakter olmalı!");
      else if (password != passwordConfirm)
        alert("Yazdığınız şifreler birbiriyle eşleşmiyor!");
      else
        changePassword().then((success) => {
          if (success) props.history.push("/");
          else alert("Birşeyler ters gitti! Tekrar deneyiniz...");
        });
    }
  };
  const logoDiv = (
    <div className={classNames.logo_wide}>
      <img src="/assets/images/logo-w.png" alt="home-logo" />
    </div>
  );
  const header = type !== types.forget && (
    <Typography color="orange" size="small" font="merel">
      Giriş Yap
    </Typography>
  );

  const emailInput = (
    <Input
      name="email"
      placeholder="Email"
      label="Email"
      onChange={({ target: { value } }) => setEmail(value)}
      value={email}
      style={{fontSize:"17px"}}
    />
  );

  const passInput = (
    <Input
      name="password"
      type="password"
      placeholder="Şifre"
      label="Şifre"
      onChange={({ target: { value } }) => setPassword(value)}
      value={password}
    />
  );

  const confirmPassInput = (
    <Input
      name="confrimPassword"
      type="password"
      placeholder="Şifre Tekrar"
      label="Şifre Tekrar"
      onChange={({ target: { value } }) => setPasswordConfirm(value)}
      value={passwordConfirm}
    />
  );

  const signinOrChangeButton = (
    <Button onClick={onSignIn}>
      {type === types.forget
        ? "Gönder"
        : type === types.login
        ? "Giriş Yap"
        : "Değiştir"}
    </Button>
  );

  const bottomLinks = (
    <Typography font="tadao" weight="normal" size="smaller" color="grey">
      Hesabınız yok mu?{" "}
      <Link to="/kayit" className="orange">
        Kayıt Ol
      </Link>{" "}
      /{" "}
      <span
        className={"orange " + classNames.forget}
        onClick={() => {
          history.push(type === types.login ? types.forget : types.login);
          setType(type === types.login ? types.forget : types.login);
        }}
      >
        {type === types.forget ? "Giriş Yap" : "Şifremi Unuttum"}
      </span>
    </Typography>
  );

  return (
    <div className={classNames.login}>
      {logoDiv}
      <div className={classNames.login_container}>
        {/* <div style={{color:"#b9b6b6", fontSize:"x-large"}}>
          OYUN2022 YARI FİNAL SINAVINA ULAŞMAK İÇİN <a href="https://oyun.tzv.org.tr">TIKLAYINIZ.</a>
        </div> */}
        <div className={classNames.login_card_container}>
          {loading && <Loading />}
          <div className={["card", classNames.login_card].join(" ")}>
            {header}
            <form>
              {type !== types.change && emailInput}
              {type !== types.forget && passInput}
              {type === types.change && confirmPassInput}
              {signinOrChangeButton}
            </form>
          </div>
          {type !== types.change && bottomLinks}
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

export default Login;
