import React from "react";
import Header from "../header/header";
import classNames from "./layout.module.css";
import Footer from "../footer/footer";
import { useLocation } from "react-router-dom";

const Layout = ({ children, ...props }) => {
  const location = useLocation();

  const thisIsExternalExam = location.pathname.substring(1, 9) === "oyun2021";

  if (thisIsExternalExam)
    return (
      <div className={classNames.layout}>
        <main className={classNames.content}>{children}</main>
      </div>
    );
  else
    return (
      <div className={classNames.layout}>
        <Header />
        <main className={classNames.content}>{children}</main>
        <Footer />
        <img
          src="/assets/images/logo-overlay.png"
          alt="logo"
          className={classNames.logo_overlay}
        />
      </div>
    );
};

export default Layout;
