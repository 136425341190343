import React, { useState } from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Stats from "./stats";
import Users from "./users";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import AdminOperations from "./adminOperations";
import Exam from "./exam";

const Admin = () => {
  const user = useSelector((state) => state.auth && state.auth.user);

  const [params, setParams] = useState({
    selectedView: "users", // stats || users
  });

  if (!user || user.role !== 2) return <Redirect to="/" />;

  const setParam = (key, value) => {
    setParams({
      ...params,
      [key]: value,
    });
  };

  return (
    <div
      style={{
        margin: "100px auto 0",
        padding: "32px",
        height: "100%",
        width: "100%",
        maxWidth: "1600px",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <ButtonGroup
        orientation="vertical"
        color="secondary"
        aria-label="vertical outlined primary button group"
      >
        <Button onClick={() => setParam("selectedView", "stats")}>
          İSTATİSTİKLER
        </Button>
        <Button onClick={() => setParam("selectedView", "users")}>
          KULLANICI LİSTESİ
        </Button>
        <Button onClick={() => setParam("selectedView", "AdminOperations")}>
          ADMİN İŞLEMLERİ
        </Button>
        <Button onClick={() => setParam("selectedView", "exam")}>
          SINAV
        </Button>
      </ButtonGroup>
      <div
        style={{ paddingRight: "50px", paddingLeft: "100px", width: "100%" }}
      >
        {params.selectedView == "stats" && <Stats />}
        {params.selectedView == "users" && <Users />}
        {params.selectedView == "AdminOperations" && <AdminOperations />}
        {params.selectedView == "exam" && <Exam />}

      </div>
      <ScrollToTop />
    </div>
  );
};

export default Admin;
