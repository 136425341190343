import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "../../../../components/ui/typography";
import { scroller } from "react-scroll";
import classNames from "./style.module.css";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
// import year2023_2024 from "../../../pastYearsPages/year2023_2024";
// import AnnouncementModal from "../../../../components/ui/announcementModal/announcementModal";
import { useParams } from 'react-router-dom';
import { incrementIncomingSourceAmount } from "../../../../firebaseMethods";
const Landing = (props) => {
  const user = useSelector((state) => state.auth.user);

  const params = useParams();
  // console.log('params', params);

  const products = useSelector(
    (state) => (user && user.applied_products) || []
  );

  if (params.source_name) {
    incrementIncomingSourceAmount(params.source_name);
    return <Redirect to={`/`} />;
  }


  const isAnnouncementDisplayed =
    sessionStorage.getItem("isAnnouncementDisplayed") === "true";



  const competitorDidntPurchaseYet = user && user.role === 0 && !products[0];
  const competitorIsAPaidStudent = user && user.role === 0 && products[0];
  const competitorIsATutor = user && user.role === 1;

  const competitorVisitedPurcahsePageOnThisSession =
    window.sessionStorage.getItem("USER_VISITED_THE_PAYMENT_PAGE");
  const competitorVisitedManagementPageOnThisSession =
    window.sessionStorage.getItem("USER_VISITED_THE_MANAGEMENT_PAGE");

  if (competitorDidntPurchaseYet && !competitorVisitedPurcahsePageOnThisSession)
    return <Redirect to="/satinal" />;
  if (
    (competitorIsAPaidStudent || competitorIsATutor) &&
    !competitorVisitedManagementPageOnThisSession
  )
    return <Redirect to="/yonetim" />;

  return (
    <>
      {/* {!isAnnouncementDisplayed && <AnnouncementModal title= body =/>} */}
      <div className={classNames.landing}>
        <div className={classNames.logo_wide}>
          <img src="/assets/images/logo-w.png" alt="home-logo" />
        </div>
      </div>
      <div>
        <Typography
          font="tadao"
          weight="bold"
          size="bigger"
          color="orange"
          className={classNames.yeartext}
        >
          2024-2025
        </Typography>
        {/* <Typography font="tadao" weight="bold" size="normal" color="white" className={classNames.mebtext}>2022-23 sezonu sona ermiştir. Şampiyona'ya katılan tüm öğrenci ve öğretmenlerimizi kutlarız. 2023-24 sezonu takvimine takvim sayfasından ulaşabilirsiniz.</Typography> */}
        <Typography
          color="orange"
          size="big"
          className={classNames.record_time}
        >
         Kayıtlar
        </Typography>

        <div className={classNames.show_more}>
          <div>
            <img
              src="/assets/images/ill-1.png"
              alt="left"
              className={classNames.circle_img}
            />
          </div>
          <div className={classNames.show_more_center}>
            <Typography color="white" size="big" className={classNames.date}>
            2 Ekim 2024 - 7 Şubat 2025
            </Typography>
            <div className={classNames.show_more_desc}>
              <div>
                <Typography color="white" style={{ paddingTop: 35 }}>
                  Detaylı Bilgi
                </Typography>
              </div>

              <div
                className={classNames.show_more_arrow}
                onClick={() =>
                  scroller.scrollTo("/hakkinda", {
                    duration: 1500,
                    delay: 100,
                    smooth: "easeInOutQuint",
                  })
                }
              >
                <Typography color="orange" size="big">
                  <FontAwesomeIcon icon="chevron-down" />
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <img
              src="/assets/images/ill-2.png"
              alt="right"
              className={classNames.circle_img}
            />
          </div>
        </div>


        {/* {year2023_2024()} */}
      </div>
    </>
  );
};

export default Landing;
