import {
  getUserFromLocalStorage,
  getAuthHeadersFromLocalStorage,
} from "../../helpers/localStorageHelper";
import { updateAuthHeaders } from "../../config/axios-instance";
import {
  CLEAR_AUTH,
  SIGN_IN,
  actionTypeSuccess,
  CHECK_PURCHASE_WITH_USER_ID,
  SIGN_OUT,
  actionTypeRequest,
} from "../actionTypes";

const user = getUserFromLocalStorage();
if (user) updateAuthHeaders(getAuthHeadersFromLocalStorage());

const initialState = {
  user: user,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypeSuccess(SIGN_IN):
      return {
        ...state,
        user: action.user,
      };

    case actionTypeRequest(SIGN_OUT):
      return {
        // ...state,
        user: null,
      };

    case actionTypeSuccess(CHECK_PURCHASE_WITH_USER_ID):
      return {
        ...state,
        user: { ...state.user, applied_products: action.user.applied_products },
      };
    case CLEAR_AUTH:
      return {
        // ...state,
        user: null,
      };
    default:
      return state;
  }
};
