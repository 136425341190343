import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const MaterialBackDrop = () => {
  return (
    <Backdrop style={{ zIndex: 1, color: "#fff" }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default MaterialBackDrop;
