import React, { useState, useEffect } from "react";
import Typography from "../../../components/ui/typography";
import PdfExporter from "../../../components/pdfcreate/pdfExporter";
import { PDF_DOC_PROPS, BF_RESULT_TEXTS } from "../../../common/constants";
import { teamExamLink, detailedUpdatesLink } from "./generateLinks";
import classNames from "../manage.module.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import PdfExporterSEBF_BF from "../../../components/pdfcreate/2_bolge_finalleri/pdfExporterSEBF_BF";
import Button from "../../../components/ui/button";

export const BfSection = ({ user, studentList, users_of_this_school }) => {
  const [params, setParams] = useState({
    anyCoachUserAttendedToBF: false,
    anySchoolUserAttendedToBF: false,
    anyStudentOfThisCoachTakesBFsuccessDoc: false,
    anyStudentOfThisSchoolTakesBFsuccessDoc: false,

    // studentList: [], //useState olarak güncellendi
  });

  useEffect(() => {
    // Exam results geldiğinde öğrenci listesi üzerinden 1 tur geçip bu okuldan hangi sınavlara katılım olduğunu bulalım:
    const schoolResults = studentList; // 1-3
    const allStudentsOfThisSchool = users_of_this_school; // 5

    if (user?.role === 1 && allStudentsOfThisSchool) {
      let anyCoachUserAttendedToBF = false;
      let anySchoolUserAttendedToBF = false;
      let anyStudentOfThisCoachTakesBFsuccessDoc = false;
      let anyStudentOfThisSchoolTakesBFsuccessDoc = false;

      for (let i = 0; i < schoolResults.length; i++) {
        // sadece bu öğretmenin öğrencilerini kontrol eder:
        if (schoolResults[i].willUserTakeBFBasariBelgesi === true) {
          anyCoachUserAttendedToBF = true;
          anyStudentOfThisCoachTakesBFsuccessDoc = true;
        }
        if (schoolResults[i].willUserTakeBFKatilimBelgesi === true) {
          anyCoachUserAttendedToBF = true;
        }
      }

      for (let x = 0; x < allStudentsOfThisSchool.length; x++) {
        if (allStudentsOfThisSchool[x].willUserTakeBFBasariBelgesi === true) {
          anySchoolUserAttendedToBF = true;
          anyStudentOfThisSchoolTakesBFsuccessDoc = true;
        }
        if (allStudentsOfThisSchool[x].willUserTakeBFKatilimBelgesi === true) {
          anySchoolUserAttendedToBF = true;
        }
      }

      setParams({
        ...params,
        anyCoachUserAttendedToBF,
        anySchoolUserAttendedToBF,
        anyStudentOfThisCoachTakesBFsuccessDoc,
        anyStudentOfThisSchoolTakesBFsuccessDoc,
      });
    }
  }, [studentList, users_of_this_school]);

  const willCoachTakeSuccessDoc = params.anyStudentOfThisCoachTakesBFsuccessDoc;
  const willCoachTakeAttendanceDoc =
    !willCoachTakeSuccessDoc && params.anyCoachUserAttendedToBF;

  const willSchoolTakeSuccessDoc =
    params.anyStudentOfThisSchoolTakesBFsuccessDoc;
  const willSchoolTakeAttendanceDoc =
    !willSchoolTakeSuccessDoc && params.anySchoolUserAttendedToBF;

  // if (
  //   user.role === 1 &&
  //   !willCoachTakeSuccessDoc &&
  //   !willCoachTakeAttendanceDoc
  // )
  //   return null;

  const ifConclusion = user?.exam_results?.find(
    (er) => er.season === "2024_if"
  )?.conclusion;

  const bfConclusion = user?.exam_results.find(
    (r) => r.season === "2024_bf"
  )?.conclusion;

  const canSeeThisBox =
    [1, 3].includes(ifConclusion) || bfConclusion || user.role === 1; // if sonrası, bf öncesi, buradaki && işaretini || yapmak gerek

  if (!canSeeThisBox) return null;

  const StudentTableBF = ({ user, studentList }) => {
    return (
      <Box sx={{ overflow: "auto", marginTop: 20 }}>
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {[
                    { name: "ID", align: "center" },
                    { name: "Ad-Soyad", align: "left" },
                    // { name: "E-Posta", align: "left" },
                    { name: "Sınıf", align: "center", hideForThisRole: 0 },
                    { name: "Puanı", align: "center" },
                    { name: "TR Sırası", align: "center" },
                    {
                      name: "Türkiye Finallerine Katılım Durumu",
                      align: "center",
                    },
                    { name: "Katılım/Başarı Belgesi", align: "center" },
                    { name: "Sosyal Etkinlik Formu", align: "center" },
                  ].map(
                    (row) =>
                      row.hideForThisRole !== user.role && (
                        <TableCell
                          key={row.name + "_user_list_column_name"}
                          style={{ fontWeight: "bold" }}
                          align={row.align}
                        >
                          {row.name}
                        </TableCell>
                      )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {studentList.map((row) => {
                  const generateBfSEBF = () => {
                    if (row.willUserTakeBF_SEBF)
                      return (
                        <PdfExporterSEBF_BF
                          size="small"
                          type={PDF_DOC_PROPS.bf_sebf.type}
                          school={row.school_name}
                          name={row.name}
                          fileNumber={"TZVSMP23 BL/" + row.user_id}
                        />
                      );
                    else if (
                      row.willUserTakeBFBasariBelgesi ||
                      row.willUserTakeBFKatilimBelgesi
                    ) {
                      return (
                        <Button
                          style={{
                            background: "green",
                            fontSize: 12,
                          }}
                          onClick={() => {
                            alert(
                              "Bu yarışmacıya ait Sosyal Etkinlik Bilgilendirme Formu Türkiye Finalleri Sınavının sonucuna göre hazırlanacaktır."
                            );
                          }}
                        >
                          Açıklama
                        </Button>
                      );
                    } else return <div></div>;
                  };

                  const bfBasari = row.willUserTakeBFBasariBelgesi && (
                    <PdfExporter
                      size="small"
                      type={PDF_DOC_PROPS.bf_basari_ogrenci.type}
                      school={
                        row.school_name +
                        " (" +
                        row.city +
                        "/" +
                        row.district +
                        ")"
                      }
                      name={row.name}
                    />
                  );

                  const bfKatilim = row.willUserTakeBFKatilimBelgesi && (
                    <PdfExporter
                      size="small"
                      type={PDF_DOC_PROPS.bf_katilim_ogrenci.type}
                      school={
                        row.school_name +
                        " (" +
                        row.city +
                        "/" +
                        row.district +
                        ")"
                      }
                      name={row.name}
                    />
                  );

                  const bf_2024_result =
                    row?.exam_results.find((r) => r.season === "2024_bf") ?? {};

                  const bfResultText = bf_2024_result.conclusion
                    ? BF_RESULT_TEXTS[bf_2024_result.conclusion].short
                    : "";

                  const if_2024_result =
                    row?.exam_results.find((r) => r.season === "2024_if") ?? {};

                  const bfConclusion = bf_2024_result.conclusion;
                  if (![1, 2, 3, 4].includes(bfConclusion)) return null;

                  const ifConclusion = if_2024_result.conclusion;

                  // if (![1, 3].includes(ifConclusion) && bf_2024_result)
                  //   return null;

                  return (
                    <TableRow key={row.user_id + "user_id"}>
                      <TableCell align="center">{row.user_id}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      {/* <TableCell align="left">{row.email}</TableCell> */}
                      {user.role === 1 && (
                        <TableCell
                          align="center"
                          style={{ margin: 0, padding: 0 }}
                        >
                          {" "}
                          {row.class_id}
                        </TableCell>
                      )}
                      <TableCell align="center">
                        {bf_2024_result.score}
                      </TableCell>
                      <TableCell align="center">
                        {bf_2024_result.order_in_country}
                      </TableCell>
                      <TableCell align="center"> {bfResultText}</TableCell>

                      {
                        <TableCell align="center">
                          {(user.role === 1 || user.id === row.user_id) &&
                            (bfBasari ?? bfKatilim)}
                        </TableCell>
                      }
                      {
                        <TableCell align="center">
                          {(user.role === 1 || user.id === row.user_id) &&
                            generateBfSEBF()}
                        </TableCell>
                      }
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  };

  const renderCoachSuccesOrAttendanceDocs = () => {
    if (user.role === 1) {
      if (willCoachTakeSuccessDoc) {
        return (
          <PdfExporter
            type={PDF_DOC_PROPS.bf_basari_ogretmen.type}
            school={
              user.school_name + " (" + user.city + "/" + user.district + ")"
            }
            name={user.name}
          />
        );
      } else if (willCoachTakeAttendanceDoc) {
        return (
          <PdfExporter
            type={PDF_DOC_PROPS.bf_katilim_ogretmen.type}
            school={
              user.school_name + " (" + user.city + "/" + user.district + ")"
            }
            name={user.name}
          />
        );
      }
    } else {
      return null;
    }
  };

  const renderSchoolSuccessOrAtendanceDocs = () => {
    if (user.role === 1) {
      if (willSchoolTakeSuccessDoc) {
        return (
          <PdfExporter
            type={PDF_DOC_PROPS.bf_basari_okul.type}
            school={user.city + " / " + user.district}
            name={user.school_name}
          />
        );
      } else if (willSchoolTakeAttendanceDoc) {
        return (
          <PdfExporter
            type={PDF_DOC_PROPS.bf_katilim_okul.type}
            school={user.city + " / " + user.district}
            name={user.school_name}
          />
        );
      }
    }
  };

  const generateBFLinksForCoach = () => {
    if (user.role !== 1) return null;
    let bfButtons = {}; //{ class_id: <Button element>}
    studentList.forEach((student) => {
      const ifConclusion = student?.exam_results.find(
        (r) => r.season === "2024_if"
      )?.conclusion;
      if ([1, 3].includes(ifConclusion)) {
        bfButtons[student.class_id] = teamExamLink({
          usr: { ...student, name: user.school_name },
          season: "bf_2324",
          buttonName: student.class_id + ". Sınıflar Bölge Finalleri Sınavı",
        });
      }
    });

    return Object.values(bfButtons);
  };

  return (
    <div className={"card " + classNames.left_step}>
      <Typography size="small" color="white" font="tadao" weight="normal">
        BÖLGE FİNALLERİ
      </Typography>
      <StudentTableBF user={user} studentList={studentList} />;
      {[1, 3].includes(ifConclusion) &&
        teamExamLink({
          usr: user,
          season: "bf_2324",
          buttonName: "Bölge Finalleri Sınavı",
        })}
      {generateBFLinksForCoach()}
      {detailedUpdatesLink("Bölge Finalleri Sonuçları")}
      {user.role === 1 && renderCoachSuccesOrAttendanceDocs()}
      {user.role === 1 && renderSchoolSuccessOrAtendanceDocs()}
      {/* {user.role === 0 && objectionSection(user)} */}
    </div>
  );
};
