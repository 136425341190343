import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getStats } from "../../store/actions/generic";

import { GET_STATS } from "../../store/actionTypes";
import MaterialBackDrop from "../../components/ui/loading/materialBackDrop";

const Stats = () => {
  const user = useSelector((state) => state.auth && state.auth.user);
  const stats = useSelector((state) => state.generic.stats);
  const loadingStats = useSelector((state) => state.loading[GET_STATS]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user && user.role == 2) {
      dispatch(getStats());
    }
  }, []);

  const generateStatsTable = () => {
    const rows = [
      "header",
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      "coach",
      "TOPLAM",
    ];
    const columnNames = [
      "Sınıf",
      "Bugün Kayıt",
      "Toplam Kayıt",
      "Bugün Ödeme",
      "Toplam Ödeme",
    ];

    let totals = [0, 0, 0, 0];
    return (
      <table
        style={{
          backgroundColor: "white",
          marginBottom: "40px",
          marginRight: "20px",
        }}
      >
        {rows.map((row) => {
          let rowData = [];
          const rowName = row === "coach" ? "Öğretmen" : row;
          if (row === "header") rowData = columnNames;
          else if (row === "TOPLAM") rowData = ["TOPLAM", ...totals];
          else
            rowData = [
              rowName,
              stats.registers_of_today[row],
              stats.registers_of_all_days[row],
              stats.paids_of_today[row],
              stats.paids_of_all_days[row],
            ];

          return (
            <tr
              style={{
                backgroundColor: row === "TOPLAM" ? "#dd4b18" : "white",
              }}
            >
              {rowData.map((cell, i) => {
                if (
                  i == 1 &&
                  row !== "header" &&
                  row !== "TOPLAM" &&
                  !isNaN(cell)
                )
                  totals[0] += cell;
                if (
                  i == 2 &&
                  row !== "header" &&
                  row !== "TOPLAM" &&
                  !isNaN(cell)
                )
                  totals[1] += cell;
                if (
                  i == 3 &&
                  row !== "header" &&
                  row !== "TOPLAM" &&
                  !isNaN(cell)
                )
                  totals[2] += cell;
                if (
                  i == 4 &&
                  row !== "header" &&
                  row !== "TOPLAM" &&
                  !isNaN(cell)
                )
                  totals[3] += cell;
                return (
                  <td
                    key={i + "_column_id"}
                    style={{
                      border: "1px solid grey",
                      borderCollapse: "collapse",
                      textAlign: "center",
                      padding: 2,
                    }}
                  >
                    {cell}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
    );
  };

  const generateAdditionalInfo = () => {
    return (
      <div
        style={{
          backgroundColor: "white",
          marginBottom: "40px",
          marginRight: "20px",
          padding: 20,
        }}
      >
        {"Kayıtlı okul sayısı: " + stats.school_count}
        <br />
        {"Öğretmensiz okul sayısı: " + stats.school_count_without_coach}
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {loadingStats && <MaterialBackDrop />}
      {stats && generateStatsTable()}
      {stats && generateAdditionalInfo()}
    </div>
  );
};

export default Stats;
