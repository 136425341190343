import axios from "../../config/axios-instance";
import { actionCreate } from "./actionCreator";
import {
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFail,
  GET_DISTRICT_SCHOOLS,
  GET_FILTERED_USERS,
  GET_STATS,
  GET_USER_BY_ID,
  UPDATE_USER_BY_ID,
  DELETE_USER,
  CREATE_A_PAID_RECEIPT,
  CANCEL_A_PAID_RECEIPT,
  ADD_SCHOOL,
  DISCLAIM,
} from "../actionTypes";
import api from "../../config/api";

export const getDistrictSchools = (city, district) => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(GET_DISTRICT_SCHOOLS)));
  return axios
    .get(api.getDistrictSchools, { params: { city: city, district: district } })
    .then((response) => {
      dispatch(
        actionCreate(actionTypeSuccess(GET_DISTRICT_SCHOOLS), {
          payload: response.data,
        })
      );
      return response.data;
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(GET_DISTRICT_SCHOOLS)));
      console.log("GET_DISTRICT_SCHOOLS error", error);
    });
};

export const getFilteredUsers = (filter) => (dispatch) => {
  if (!filter.school_id && !filter.text_for_filter) return null;

  dispatch(actionCreate(actionTypeRequest(GET_FILTERED_USERS)));
  return axios
    .get(api.getFilteredUsers, { params: { ...filter } })
    .then((response) => {
      dispatch(
        actionCreate(actionTypeSuccess(GET_FILTERED_USERS), {
          payload: response.data,
        })
      );
      return response.data;
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(GET_FILTERED_USERS)));
      console.log("GET_FILTERED_USERS error", error);
    });
};

export const getUserById = (id) => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(GET_USER_BY_ID)));
  return axios
    .get(api.getUserById, { params: { id: id } })
    .then((response) => {
      dispatch(
        actionCreate(actionTypeSuccess(GET_USER_BY_ID), {
          payload: response.data,
        })
      );
      return response.data;
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(GET_USER_BY_ID)));
      console.log("GET_USER_BY_ID error", error);
    });
};

export const updateUserById = (user_details_object) => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(UPDATE_USER_BY_ID)));
  return axios
    .post(api.updateUserById, { ...user_details_object })
    .then((response) => {
      dispatch(
        actionCreate(actionTypeSuccess(UPDATE_USER_BY_ID), {
          payload: response.data,
        })
      );
      return response.data;
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(UPDATE_USER_BY_ID)));
      console.log("UPDATE_USER_BY_ID error", error);
    });
};

export const deleteUser = (user_id) => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(DELETE_USER)));
  return axios
    .post(api.deleteUser, { user_id })
    .then((response) => {
      dispatch(
        actionCreate(actionTypeSuccess(DELETE_USER), { payload: response.data })
      );
      return response.data;
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(DELETE_USER)));
      console.log("DELETE_USER error", error);
    });
};

export const createAPaidReceipt = (user_id, product_id) => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(CREATE_A_PAID_RECEIPT)));
  return axios
    .post(api.createAPaidReceipt, { user_id, product_id })
    .then((response) => {
      dispatch(
        actionCreate(actionTypeSuccess(CREATE_A_PAID_RECEIPT), {
          payload: response.data,
        })
      );
      return response.data;
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(CREATE_A_PAID_RECEIPT)));
      console.log("CREATE_A_PAID_RECEIPT error", error);
    });
};

export const cancelAPaidReceipt = (user_id, product_id) => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(CANCEL_A_PAID_RECEIPT)));
  return axios
    .post(api.cancelAPaidReceipt, { user_id, product_id })
    .then((response) => {
      dispatch(
        actionCreate(actionTypeSuccess(CANCEL_A_PAID_RECEIPT), {
          payload: response.data,
        })
      );
      return response.data;
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(CANCEL_A_PAID_RECEIPT)));
      console.log("CANCEL_A_PAID_RECEIPT error", error);
    });
};

export const getStats = () => (dispatch) => {
  dispatch(actionCreate(actionTypeRequest(GET_STATS)));
  return axios
    .get(api.getStats, { params: {} })
    .then((response) => {
      dispatch(
        actionCreate(actionTypeSuccess(GET_STATS), { payload: response.data })
      );
      return response.data;
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(GET_STATS)));
      console.log("GET_STATS error", error);
    });
};

export const addSchool = (params) => (dispatch) => {
  const { private_type, name, district, city } = params;
  dispatch(actionCreate(actionTypeRequest(ADD_SCHOOL)));
  return axios
    .post(api.addSchool, { private_type, name, district, city })
    .then((response) => {
      console.log("response:", response);
      alert(response.data?.message);
      dispatch(
        actionCreate(actionTypeSuccess(ADD_SCHOOL), { payload: response.data })
      );
      return response.data;
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(ADD_SCHOOL)));
      console.log("ADD_SCHOOL error", error);
    });
};

export const disclaim = (params) => (dispatch) => {
  const { season } = params;
  dispatch(actionCreate(actionTypeRequest(DISCLAIM)));
  return axios
    .post(api.disclaim, { season })
    .then((response) => {
      dispatch(
        actionCreate(actionTypeSuccess(DISCLAIM), { payload: response.data })
      );
      return response.data;
    })
    .catch((error) => {
      dispatch(actionCreate(actionTypeFail(DISCLAIM)));
      console.log("DISCLAIM error", error);
    });
};
